import { useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT


const useBuscaCloser = (closerid) => {
    useEffect(() => {
            async function buscaCloser() {
                try {
                    const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaFuncionario/${closerid}`);
                    let data;
                    const nomeCloser = document.getElementById('nomeCloser') as HTMLInputElement;

                    if (response.status === 404 || response.status === 400) {
                        data = response.data;
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "center-end",
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.onmouseenter = Swal.stopTimer;
                                toast.onmouseleave = Swal.resumeTimer;
                            }
                        });
                        Toast.fire({
                            icon: "info",
                            title: data
                        });
                        nomeCloser.value = '';
                    }

                    if (response.status === 200) {
                        data = response.data;

                        if (data.length > 0){
                            nomeCloser.value = data[0].funcionario;
                        } else {
                            const Toast = Swal.mixin({
                                toast: true,
                                position: "center-end",
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.onmouseenter = Swal.stopTimer;
                                    toast.onmouseleave = Swal.resumeTimer;
                                }
                            });
                            Toast.fire({
                                icon: "info",
                                title: "Não foram encontrados dados para o closer fornecido."
                            });
    
                            nomeCloser.value = '';
                        }
                        
                    }

                } catch (error) {
                    console.error('Erro ao buscar Closer pelo código! Detalhes:', error);
                }
            };
            
            closerid && closerid.length >= 2 && buscaCloser();
            
    }, [closerid]);
};

export default useBuscaCloser;

