import {useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const useBuscaParceiro = (parceiroid) => {
  useEffect(() => {
    async function buscaParceiro() {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros/buscaParceiroApi/${parceiroid}`
        );
        let data;
        const nomeParceiro = document.getElementById('nomeParceiro') as HTMLInputElement;

        if (response.status === 404 || response.status === 400) {
          data = await response.data;
          const Toast = Swal.mixin({
            toast: true,
            position: 'center-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: 'info',
            title: data,
          });
          nomeParceiro.value = '';
        }

        if (response.status === 200) {
          data = await response.data;

          if (data.length > 0) {
            nomeParceiro.value = data[0].parceiro;
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'center-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            Toast.fire({
              icon: 'info',
              title: 'Não foram encontrados dados para o parceiro fornecido.',
            });
            nomeParceiro.value = '';
          }
        }
      } catch (error) {
        console.error('Erro ao buscar parceiro pelo código! Detalhes:', error);
      }
    }

    parceiroid && parceiroid.length >= 2 && buscaParceiro();
  }, [parceiroid]);
};

export default useBuscaParceiro;
