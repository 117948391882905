import {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {useFormik} from 'formik';
import {ColumnInstance, useTable, Row, Column} from 'react-table';
import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {DescriptionComponent} from '../../../shared/components';
import {IFormulario} from '../../../shared/domain-types/models/formulario';
import {CustomRow} from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {CustomHeaderColumn} from '../../../modules/apps/user-management/users-list/table/columns/CustomHeaderColumn';
import {getFormularios} from '../listagem_requests/listagem_requests';

const FormulariosListagem: React.FC = () => {
  const formularioColumns: Column<IFormulario>[] = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Arquivo',
      accessor: 'arquivo',
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}: {value: number}) => <span>{value === 1 ? 'Ativo' : 'Inativo'}</span>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <button
          onClick={() => handleOnEdit(row.original.id!)}
          className='btn btn-success btn-sm bi bi-pencil'
          data-toggle='tooltip'
          data-placement='top'
          title='Editar'
        />
      ),
    },
  ];

  const navigate = useNavigate();

  const [isFormulariosData, setIsFormularioData] = useState<IFormulario[]>([]);
  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

  const data = useMemo(() => isFormulariosData, [isFormulariosData]);
  const columns = useMemo(() => formularioColumns, []);
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const initialValues = {
    arquivo: '',
    descricao: '',
    status: 1,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoadingSearch(true);

      try {
        const response = await getFormularios({
          descricao: values.descricao,
          nomeArquivo: values.arquivo,
          status: values.status,
        });

        const {content} = response.data;

        setIsLoadingSearch(false);
        setSubmitting(false);

        setIsFormularioData(content);
      } catch (errors: any) {
        const {error} = errors;

        if (error.response) {
          const status = error.response.status;

          if (status === 401 || 403) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: error,
              confirmButtonText: 'Ok',
            });
          }
        }

        setSubmitting(false);
        setIsLoadingSearch(false);
      }
    },
  });

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const handleOnEdit = (id: string | number) => {
    navigate(`/editar-formularios/${id}`);
  };

  const getFormularioData = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await getFormularios({
        descricao: '',
        nomeArquivo: '',
        status: 1,
        page,
      });

      const {content, totalPages} = response.data;

      setIsFormularioData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoading(false);
    } catch (errors: any) {
      const {error} = errors;

      const status = error.response.status;

      if (status === 401 || 403) {
        Swal.fire({
          icon: 'error',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        });
      }

      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonText: 'Ok',
      });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFormularioData(isPage);
  }, [isPage]);

  return (
    <>
      <DescriptionComponent
        description='Listagem de Formulários'
        withLink
        buttonTitle='+ Novo Formulario'
        redirectTo='/formularios-cadastro'
      />

      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
            <Col className='col-sm-6'>
              <label className='form-label'>Arquivo:</label>
              <input
                type='text'
                placeholder='Digite o arquivo do formulário'
                {...formik.getFieldProps('arquivo')}
                className='form-control'
              />
            </Col>
            <Col className='col-sm-6'>
              <div>
                <label className='form-label'>Status:</label>
                <select className='form-select' {...formik.getFieldProps('status')}>
                  <option value='1'>Ativo</option>
                  <option value='0'>Desativado</option>
                </select>
              </div>
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12'>
            <Col className='col-6-sm'>
              <label className='form-label'>Descrição:</label>
              <textarea
                placeholder='Digite a descrição do formulário'
                {...formik.getFieldProps('descricao')}
                className='form-control'
              />
            </Col>
          </RowBootstrap>
          <div className='row mt-6'>
            <div className='col-sm-2 mb-6'>
              <button type='submit' className='btn btn-success form-control'>
                {isLoadingSearch ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className='table-responsive'>
        <table
          id='kt_table_formularios'
          className='table table-hover table-striped table-rounded table-row-bordered border'
          {...getTableProps()}
        >
          <thead className='thead-dark'>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<IFormulario>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<IFormulario>, i) => {
                prepareRow(row);
                return (
                  <CustomRow
                    row={row}
                    key={`row-${i}-${row.id}`}
                    showEditButton
                    onEdit={(id) => handleOnEdit(id)}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isHasMore && (
          <div className='d-flex justify-content-center align-items-center'>
            <button className='btn btn-primary m-5' onClick={loadMore}>
              {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default FormulariosListagem;
