import React, {useEffect, useState} from "react";
import axios from "axios";
interface OptionType {
  value: number;
  label: string;
}

interface OptionTypeWithPrecoVenda extends OptionType {
    precoVenda: string; 
}


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

const useOptionsTabelaDePrecoProduto = (idProduto) => {
    const [optionsTabelaDePrecoProduto, setOptionsTabelaDePrecoProduto] = useState<OptionTypeWithPrecoVenda[]>([]);

    const limparOpcoesTabelaPrecoProduto = () => {
      setOptionsTabelaDePrecoProduto([]);
    };

    useEffect(() => {
      async function fetchOptions() {

        if (!idProduto) return;

        try {
          limparOpcoesTabelaPrecoProduto();
          const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/tabeladeprecos/optionsTabelaDePrecosProduto/${idProduto}`);
          const data = await response.data;

          const formattedOptions = data.map(item => ({
            value: item.idTabelaPreco,
            label: item.tabelaPreco,
            precoVenda: item.precoVenda
          }));

          setOptionsTabelaDePrecoProduto(formattedOptions);
        } catch (error) {
          console.error("Erro ao buscar opções:", error);
        }
      }
      fetchOptions();
    }, [idProduto]);
  
    return { optionsTabelaDePrecoProduto, limparOpcoesTabelaPrecoProduto};
    
  };
  
  export default useOptionsTabelaDePrecoProduto;