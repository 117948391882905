/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_sysled/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg6.jpg')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          {/* <img alt='Logo' src={toAbsoluteUrl('/media/logos/sysled-logo.svg')} className='h-125px' />
           */}
          <img
            alt='Logo'
            src='/media/logos/sysled-logo.svg'
            className='d-none d-md-block h-125px d-md-inline-block'
          />
          <img alt='Logo' src='/media/logos/sysled-logo.svg' className='d-block d-md-none' />
          {/* end::Logo */}
          {/* begin::Title */}
          <h1 className='text-white fw-normal m-0'>Nuvem de Soluções, Brilho de Eficiência.</h1>
          {/* end::Title */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}

      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AuthLayout}
