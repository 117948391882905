import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

const useBuscaCepPreVenda = (cep, formik) => {
  useEffect(() => {
    
    let isMounted = true;

    const buscaCep = async () => {
      const cepFormatado = cep.replace(/[^\d]/g, '');
      if (cepFormatado.length !== 8) {
        return;
      }
      
      try {
        const response = await fetch(`https://viacep.com.br/ws/${cepFormatado}/json/`);
        const data = await response.json();

        if (response.status === 200) {
          if (data.erro) {
            const Toast = Swal.mixin({
              toast: true,
              position: "center-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              }
            });
            Toast.fire({
              icon: "error",
              title: "Não foi possível localizar este CEP"
            });
          } else {
            formik.setValues({
              ...formik.values,
              enderecoEntrega: data.logradouro || '',
              bairroEntrega: data.bairro || '',
              cidadeEntrega: data.localidade || '',
              ufEntrega: data.uf || ''    
            });
          }
        }
      } catch (error) {
        console.error('Erro ao buscar endereço pelo CEP:', error);
      }
    };

    if (cep.replace(/[^\d]/g, '').length === 8) {
      buscaCep();
    }

    return () => {
      isMounted = false;
    };
  }, [cep]);
};

export default useBuscaCepPreVenda;