import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

const useBuscaCnpjParceiro = (cnpj, formik) => { 

    useEffect(() => {
        const buscaCnpj = async () => {
            const cnpjFormatado = cnpj.replace(/[^\d]/g, '');
            
            if (cnpjFormatado.length !== 14) {
                return;
            }

            try {
                const response = await fetch(`https://minhareceita.org/${cnpjFormatado}`);
                const data = await response.json();
    
                if (response.status === 200) {
                    formik.setValues({
                        ...formik.values,
                        nome_fantasia: data.nome_fantasia || '',
                        nome: data.razao_social || '',
                        nome_razaosocial: data.razao_social || '',
                        telefone: data.ddd_telefone_1 || '',
                        email_: data.email || '',
                        cep: data.cep || '',
                    });
                } else if (response.status === 400 || response.status === 404) {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                        }
                    });
                    Toast.fire({
                        icon: "error",
                        title: "Não foi possível localizar este CNPJ"
                    });

                    formik.setValues({
                        ...formik.values,
                        fantasia:  '',
                        nome:  '',
                        razaosocial:  '',
                        telefone:  '',
                        email_:  '',
                        cep: ''
                    });
                }
            } catch (error) {
                console.error('Erro ao buscar o CNPJ informado', error);
            }
        };
    
        if (cnpj.replace(/[^\d]/g, '').length === 14) {
            buscaCnpj();
        }
    }, [cnpj, formik.values.cnpj]);
};

export default useBuscaCnpjParceiro;