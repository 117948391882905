import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ResponseDadosparceiro } from '../../cadastros/parceiros/Interface';
import { Badge } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
import { formatStatus, formatStatusWorkflow, formatTipoParceiro } from '../proposta-pedido/components/formsPedido';
import { sincronizarParceiro } from '../listagem_requests/listagem_requests';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ListagemParceiros = () => {
  const [parceiros, setParceiros] = useState<ResponseDadosparceiro[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFiltro, setSearchFiltro] = useState('todos');
  const [loading, setLoading] = useState<boolean>(false);
  const [syncedParceiros, setSyncedParceiros] = useState<number[]>([]);

  interface parceiros {
    cnpj: string
    contato: string
    parceiro: string
    tipopessoa: number
    status: number
  }

  useEffect(() => {
    setLoading(true);
    fetchParceiros();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchParceiros();
  }, []);

  useEffect(() => {
    setSearchTerm('');
  }, [searchFiltro]);

  const fetchParceiros = async (search = '', filtro = '') => {
    try {
      setLoading(true);
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros`;

      const response = await axios.get(url, {
        params: {
          search: search,
          filtro: filtro,
        },
      });

      if (response.status === 200) {
        setParceiros(response.data.content);
        const syncedIds = response.data.content
          .filter((parceiro) => parceiro.status === 9)
          .map((parceiro) => parceiro.id);
        setSyncedParceiros(syncedIds);
      } else if (response.status === 204) {
        Swal.fire({
          icon: 'info',
          title: 'Não existe registros de Parceiros para essa pesquisa',
        });
        setParceiros([]);
      }
    } catch (error) {
      console.error('Error fetching parceiros:', error); // Log do erro para depuração
      Swal.fire({
        icon: 'error',
        title: 'Erro ao carregar parceiros',
        text: 'Ocorreu um erro ao carregar os parceiros. Por favor, tente novamente mais tarde.',
      });
    } finally {
      setLoading(false);
    }
  };



  const handleSearch = (event) => {
    event.preventDefault();
    fetchParceiros(searchTerm, searchFiltro);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (searchFiltro === 'id') {
      // Permite apenas números
      if (/^\d*$/.test(value)) {
        setSearchTerm(value);
      }
    } else {
      setSearchTerm(value);
    }
  };

  const renderInput = () => {
    if (searchFiltro === 'cpf') {
      return (
        <InputMask
          mask='999.999.999-99'
          type='text'
          placeholder='Pesquisar Profissional'
          className='form-control'
          value={searchTerm}
          onChange={handleInputChange}
        />
      );
    } else if (searchFiltro === 'cnpj') {
      return (
        <InputMask
          mask='99.999.999/9999-99'
          type='text'
          placeholder='Pesquisar Profissional'
          className='form-control'
          value={searchTerm}
          onChange={handleInputChange}
        />
      );
    } else if (searchFiltro === 'id') {
      return (
        <input
          type='text'
          placeholder='Pesquisar Profissional'
          className='form-control'
          value={searchTerm}
          onChange={handleInputChange}
        />
      );
    } else {
      return (
        <input
          type='text'
          placeholder='Pesquisar Profissional'
          className='form-control'
          value={searchTerm}
          onChange={handleInputChange}
        />
      );
    }
  };

  const formatStatus = (status) => {
    switch (status) {
      case 1:
        return <Badge bg='success'>ATIVO</Badge>
      case 0:
        return <Badge bg='danger'>INATIVO</Badge>
      case 2:
        return <Badge bg='warning'>SINCRONIZADO</Badge>
      default:
        return <Badge bg='light'>N/A</Badge>
    }
  }

  return (
    <>
      <div className=''>
        <div
          className='mb-3 form-label-container'
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <h1 className='text-dark fw-bolder'>Listagem de Profissionais</h1>
          <Link
            to='/cadastro-parceiros'
            className='btn btn-success btn-sm '
            style={{ fontSize: '14px' }}
          >
            + Novo Profissional
          </Link>
        </div>
        <div style={{ marginBottom: '8px' }}>
          <div style={{ borderBottom: '2px solid #000' }}></div>
        </div>
        <div className='mt-10'>
          <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
          <form onSubmit={handleSearch} className='form-control-solid row mb-5'>
            <div className='row mt-8 gap-4'>
              <div className='col-sm-3'>
                <select
                  className='form-select'
                  value={searchFiltro}
                  onChange={(e) => setSearchFiltro(e.target.value)}
                >
                  <option value='todos'>Selecione</option>
                  <option value='id'>Id Profissional</option>
                  <option value='nome'>Nome/fantasia</option>
                  <option value='cpf'>CPF</option>
                  <option value='cnpj'>CNPJ</option>
                </select>
              </div>
              <div className='col-sm-3'>{renderInput()}</div>
            </div>
            <div className='row mt-6'>
              <div className='col-sm-2  mb-6'>
                <button type='submit' className='btn btn-success form-control'>
                  Pesquisar
                </button>
              </div>
            </div>
          </form>
        </div>

        <div>
          <table className='table table-hover table-striped table-rounded table-row-bordered border '>
            <thead className='thead-dark'>
              <tr className='fw-bold fs-6 text-gray-800'>
                <th className='text-center'>Id</th>
                <th className='text-center'>Nome</th>
                <th className='text-center'>Tipo</th>
                <th className='text-center'>Status</th>
                <th className='text-center'>Editar</th>
                <th className='text-center'>Sincronizar</th>
              </tr>
            </thead>
            <tbody className='fw-bold fs-6 text-gray-700'>
              {parceiros.map((parceiros) => (
                <tr key={parceiros.id}>
                  <td className='text-center '>{parceiros.id}</td>
                  <td className='text-center'>
                    <div>{parceiros.parceiro || 'N/A'}</div>
                    <div>{formatStatus(parceiros.status)}</div>
                  </td>
                  <td className='text-center'> {formatTipoParceiro(parceiros.tipopessoa)}</td>
                  <td className='text-center'> {formatStatusWorkflow(parceiros.status)}</td>
                  <td className='text-center'>
                    <Link to={`/editar-parceiro/${parceiros.id}`} className='btn btn-success btn-sm'>
                      Editar
                    </Link>
                  </td>
                  <td className='text-center'>
                    {parceiros.status === 2 || parceiros.status === 0 ? (
                      <button className='btn btn-primary btn-sm disabled' style={{ display: 'none' }}>
                        Sincronizar
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (parceiros.tipopessoa === 0 && (parceiros.cnpj === '' || !parceiros.cnpj)) {
                            Swal.fire({
                              title:
                                'Parceiro(Pessoa Jurídica) sem CNPJ cadastrado. Por favor, atualize o cadastro do cliente e tente novamente.',
                              icon: 'info',
                              confirmButtonText: 'Ok',
                            })
                            return
                          }

                          if (parceiros.tipopessoa === 1 && (parceiros.cnpj === '' || !parceiros.cnpj)) {
                            Swal.fire({
                              title:
                                'Parceiro(Pessoa Física) sem CPF cadastrado. Por favor, atualize o cadastro do cliente e tente novamente.',
                              icon: 'info',
                              confirmButtonText: 'Ok',
                            })
                            return
                          }

                          if (
                            parceiros.cnpj === '' ||
                            parceiros.contato === '' ||
                            parceiros.parceiro === '' ||
                            parceiros.tipopessoa === 0 ||
                            parceiros.status === 0
                          ) {
                            Swal.fire({
                              title:
                                'Parceiro com endereço incompleto. Por favor, complete os campos de ENDEREÇO do Cliente.',
                              text: '',
                              icon: 'info',
                              confirmButtonText: 'Ok',
                            });
                            return;
                          }


                          Swal.fire({
                            title:
                              'Você tem certeza que deseja sincronizar o parceiro com id: ' +
                              parceiros.id +
                              '?',
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: 'Sim',
                            cancelButtonText: 'Não',
                            reverseButtons: true,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setLoading(true)
                              sincronizarParceiro(parceiros.id)
                                .then((response) => {
                                  let responseinfo = response.data
                                  setLoading(false)
                                  if (response.status === 200) {
                                    Swal.fire({
                                      icon: 'success',
                                      title: 'Parceiro sincronizada com sucesso!',
                                      confirmButtonText: 'Ok',
                                      reverseButtons: true,
                                    })
                                    fetchParceiros()
                                  } else if (response.status === 400) {
                                    setLoading(false)
                                    const errorMessage = responseinfo.message
                                    Swal.fire({
                                      icon: 'info',
                                      title: 'Verifique as informações',
                                      text:
                                        errorMessage ||
                                        'Ocorreu um erro ao tentar sincronizar o parceiro. Por favor, tente novamente mais tarde.',
                                      confirmButtonText: 'Ok',
                                    })
                                  }
                                })

                                .catch((error) => {
                                  setLoading(false)
                                  console.error(error)
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Erro ao sincronizar parceiro',
                                    text:
                                      error.response?.data?.message ||
                                      'Ocorreu um erro ao tentar sincronizar o parceiro. Por favor, tente novamente mais tarde.',
                                    confirmButtonText: 'Ok',
                                  })
                                })
                            }
                          })
                        }}
                        className='btn btn-success btn-sm'
                        style={{ backgroundColor: '#3fd975', borderColor: '#0099ff' }}
                      >
                        Sincronizar
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ListagemParceiros;
