import React, {useEffect, useState} from "react";
import axios from "axios";
interface OptionType {
  value: number;
  label: string;
  tipoEntregaPadrao: number;
}

interface OptionTypeWithEntregaPadrao {
  tipoEntregaPadrao: number
}



const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

const useOptionsTipoEntrega  = () => {
    const [optionsTipoEntrega, setOptionsTipoEntrega] = useState<OptionType[]>([]);
    const [defaultTipoEntrega, setDefaultTipoEntrega] = useState<OptionType[] | null>(null);
    useEffect(() => {
      async function fetchOptions() {
        try {
          const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/todosTipoEntrega`);
          const data = await response.data;
          const formattedOptions = data.map(item => ({
            value: item.idTipoEntrega,
            label: item.tipoEntrega,
            tipoEntregaPadrao: item.tipoEntregaPadrao
          }));
          setOptionsTipoEntrega(formattedOptions);

          // const defaultOption = formattedOptions.find(option => option.tipoEntregaPadrao === 1);
          // if (defaultOption) {
          //   setDefaultTipoEntrega(defaultOption);
          // }

        } catch (error) {
          console.error("Erro ao buscar opções:", error);
        }
      }
      fetchOptions();
    }, []);
  
    return { optionsTipoEntrega};
  };
  
  export default useOptionsTipoEntrega;