import React, { useEffect, useState } from 'react'
import axios from 'axios'
interface OptionType {
  value: number
  label: string
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

const useOptionsClassificacao = () => {
  const [optionsClassificacao, setOptionsClassificacao] = useState<OptionType[]>([])
  
  // const generateLabelName = (index: number) => {
  //   return `PA${index + 1}`
  // }
  
  useEffect(() => {
    async function fetchSegmentoOptions() {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros/classificacao`
        )
        const data = await response.data
        const formattedOptions = data.map((item) => ({
          value: item.id,
          label: item.id + ' - ' + item.titulo,
        

        }))
        setOptionsClassificacao(formattedOptions)
      } catch (error) {
        console.error('Erro ao buscar opções:', error)
      }
    }
    fetchSegmentoOptions()
  }, [])

  return { optionsClassificacao }
}

export default useOptionsClassificacao
