/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { toAbsoluteUrl } from '../../../helpers'

const REACT_APP_IMG_URL = process.env.REACT_APP_IMG_URL;

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={`${REACT_APP_IMG_URL}/avatars/-1.jpg`}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{currentUser?.nome}</div>
            <a
              href='#'
              className='fw-bold text-muted text-hover-primary fs-7'
              style={{ wordBreak: 'break-all' }}
            >
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>
      {currentUser?.tipouser === 'ADMIN' ? (
        <>
          <div className='menu-item px-5'>
            <Link to={`/editar-usuario/${currentUser?.id}`} className='menu-link px-5'>
              Meu Perfil
            </Link>
          </div>
          <div className='separator my-2'></div>
          <div className='menu-item px-5'>
            <a onClick={logout} className='menu-link px-5'>
              Sair
            </a>
          </div>
        </>
      ) : (
        <>
          <div className='separator my-2'></div>
          <div className='menu-item px-5'>
            <a onClick={logout} className='menu-link px-5'>
              Sair
            </a>
          </div>
        </>
      )}
      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Configurações da Conta
        </Link>
      </div> */}
    </div>
  )
}

export { HeaderUserMenu }
