import * as Yup from "yup";
export interface TipoPessoa {
  value: number;
  label: string;
}

export interface TipoParceiro {
  value: number;
  label: string;
}

interface Vendedores {
  value: number;
  label: string;
}

interface VendedoresOptions {
  value: number;
  label: string;
}
interface InfosPessoaContato {
  nome: string;
  setor: string;
  email: string;
  telefone: string;
  ramal: string;
}

interface Infobanco {
  observacao: string | number | readonly string[] | undefined;
  banco: TipoPessoa;
  agencia: number;
  conta: number;
}

interface InfosDadosparceiro {
 
  base64Image: string | undefined;
  descricao: string | undefined;
  codigo: string;
  nome_razaosocial: string;
  nome_fantasia: string;
  cnpj_cpf: string;
  inscricao_estudal_id: string;
  comissao: number | null;
  vendedor: any;
  tipo_parceiro: any;
  tipo_cadastro: any;
  cep: string;
  endereco: string;
  numero: string;
  bairro: string;
  complemento: string;
  cidade: string;
  cidadeid: string;
  estado: string;
  telefone: string;
  celular: string;
  email: string;
  data_nasc: string | undefined;
  website: string;
  classe_id: string;
  status: string;
  observacao: string;
  pessoasContato?: InfosPessoaContato;
  img_parceiro_base64: string;
  obsStatus: number;
  banco: null;
  agencia: '';
  conta: '';
  observacoes: string;
 
  tipoDeChave: number;
  chavePix: string;
  is_parceiro: number;
  createdat: Date;
  idParceiroClassificacao: number;
  parceiroClassificacaoTitulo: string;
  descricaoTipoProfissional: string;
}


interface ResponseDadosparceiro {
  cnpj: string;
  contato: string;
  id: number;
  parceiro: string;
  tipopessoa: number | null;
  status: number;
}

const formParceiro: InfosDadosparceiro = {
  codigo: "",
  nome_razaosocial: "",
  nome_fantasia: "",
  cnpj_cpf: "",
  inscricao_estudal_id: "",
  img_parceiro_base64: "",
  comissao: null,
  vendedor: 0,
  tipo_parceiro: "",
  tipo_cadastro: "1",
  cep: "",
  endereco: "",
  numero: "",
  bairro: "",
  complemento: "",
  cidade: "",
  cidadeid: "",
  estado: "",
  telefone: "",
  celular: "",
  email: "",
  data_nasc: "",
  website: "",
  classe_id: "",
  status: "",
  obsStatus: 0,
  observacao: "",
 
  base64Image: undefined,
  pessoasContato: {
    nome: "",
    setor: "",
    email: "",
    telefone: "",
    ramal: "",
  },
  
  descricao: undefined,
  banco: null,
  agencia: '',
  conta: '',
  observacoes: '',
  tipoDeChave: 0,
  chavePix: '',
  is_parceiro: 0,
  idParceiroClassificacao: 0,
  parceiroClassificacaoTitulo: '',
  descricaoTipoProfissional: '',
  createdat: new Date(),
};

const cadastroValidationSchema = Yup.object().shape({
  nome_razaosocial: Yup.string().required(
    "Por favor, informe um nome para o parceiro"
  ),
  cnpj_cpf: Yup.string().required("Por favor, informe CPF ou CNPJ"),
  tipo_parceiro: Yup.string().required("Por favor, o tipo de parceiro"),
  status: Yup.string().required("Por favor, selecione o status"),
  cep: Yup.string().required("Por favor, informe CEP"),
  endereco: Yup.string().required("Por favor, informe um endereço"),
  bairro: Yup.string().required("Por favor, informe o bairro"),
  cidade: Yup.string().required("Por favor, informe a cidade"),
  estado: Yup.string().required("Por favor, informe o estado"),
  email: Yup.string().required("Por favor, informe o email do parceiro"),
  telefone: Yup.string().required(
    "Por favor, informe telefone de contato do parceiro"
  ),
});

export { cadastroValidationSchema, formParceiro };
export type {
  InfosPessoaContato,
  InfosDadosparceiro,
  Vendedores,
  VendedoresOptions,
  ResponseDadosparceiro,
  Infobanco,
};
