import Swal from 'sweetalert2';
import { OptionType } from '../cadastros/produto/Interface';

export const submitForm = async (
  id, values, infosPessoaContato,
  updateFunction, sendFunction,
  selectedVendedor, setLoading,
  setSubmitting, setStatus,
  navigate, base64Image:
    string, selectedBanco:
    OptionType | undefined,
  isParceiro, IdParceiroClassificacao, createdat, parceiroClassificacaoTitulo, descricaoTipoProfissional) => {
  setLoading(true);
  try {
    if (id) {
      const vendedorpadraoid = selectedVendedor.map((vendedor) => vendedor.value);
      values.vendedor = vendedorpadraoid.toString();

      const dataNasc = (values.data_nasc = new Date(values.data_nasc));
      dataNasc.setDate(dataNasc.getDate() + 1);

      const response = await updateFunction(id, values, infosPessoaContato, base64Image,
        selectedBanco, isParceiro, IdParceiroClassificacao, createdat, parceiroClassificacaoTitulo, descricaoTipoProfissional);
      if (response.status === 200) {
        setSubmitting(false);
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Parceiro atualizado com sucesso!',
          reverseButtons: true,
          timer: 3000,
          timerProgressBar: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          didClose: () => {
            navigate('/listagem-parceiros');
          },
        });
      } else {
        Swal.fire({
          icon: 'error',
          title:
            'Erro ao atualizar o Parceiro, verifique as informações preenchidas e tente novamente',
          confirmButtonText: 'Ok',
        });
        setStatus('Ocorreu um erro ao salvar o Consultor. Por favor, tente novamente.');
      }
    } else {
      const vendedorpadraoid = selectedVendedor.map((tipo) => tipo.value);
      values.vendedor = vendedorpadraoid.toString();
      const dataNasc = (values.data_nasc = new Date(values.data_nasc));
      dataNasc.setDate(dataNasc.getDate() + 1);

      const response = await sendFunction(values, infosPessoaContato,
        base64Image, selectedBanco, isParceiro);
      if (response.status === 201) {
        setSubmitting(false);
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Parceiro cadastrado com sucesso!',
          reverseButtons: true,
          timer: 3000,
          timerProgressBar: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          didClose: () => {
            navigate('/listagem-parceiros');
          },
        });
      } else {
        Swal.fire({
          icon: 'error',
          title:
            'Erro ao salvar o Parceiro, verifique as informações preenchidas e tente novamente',
          confirmButtonText: 'Ok',
        });
        setStatus('Ocorreu um erro ao salvar o Parceiro. Por favor, tente novamente.');
      }
    }
  } catch (error: any) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.message === '401') {
      Swal.fire({
        icon: 'info',
        title: 'Por questões de segurança, por favor faça login novamente',
        text: 'Por favor, verifique as informações .',
        confirmButtonText: 'Ok',
      }).then(() => {
        setSubmitting(false);
        setLoading(false);
      });
    } else if (error.response && error.response.status === 409) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao salvar o Parceiro',
        text: 'CPF/CNPJ informado já em uso',
        confirmButtonText: 'Ok',
      }).then(() => {
        setSubmitting(false);
        setLoading(false);
      });
    } else {
      Swal.fire({
        // icon: 'error',
        icon: 'error',
        // title: 'Erro ao salvar o Parceiro',
        title: 'Erro ao cadastrar o Parceiro',
        // text: 'CPF/CNPJ informado já em uso',
        // text: 'Por favor, verifique as informações preenchidas.',
        confirmButtonText: 'Ok',
      });
      setSubmitting(false);
      setLoading(false);
    }
  }
};
