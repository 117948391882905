import axios from "axios";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

export const API_DASH_COMERCIAL = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/dashboards/comercial`;

export function getDashComercial(
    dataInicial: string,
    dataFinal: string,
    empresas: number[]
){
    const empresasParam = empresas.join(',');
    const url = `${API_DASH_COMERCIAL}?dataInicial=${dataInicial}&dataFinal=${dataFinal}&empresa=${empresasParam}`;
    return axios.get(url)
}
