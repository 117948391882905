import Swal from 'sweetalert2';

  function handleSuccess(message, navigate, caminhoRedirect, setSubmitting, setLoading) {

  const successMessage = `Solicitação ${message} com sucesso!`;
  Swal.fire({
    icon: 'success',
    title: successMessage,
    reverseButtons: true,
    timer: 3000,
    timerProgressBar: true,
    allowOutsideClick: false,
    allowEscapeKey: false,
    didClose: () => {
      navigate(`${caminhoRedirect}`);
    },
  });
  setSubmitting(false);
  setLoading(false);
}

 function handleError(setStatus,setSubmitting, setLoading) {
    Swal.fire({
      icon: 'error',
      title: 'Erro ao salvar a solicitação, verifique as informações preenchidas e tente novamente',
      confirmButtonText: 'Ok',
    });
    setStatus('Ocorreu um erro ao salvar a solicitação. Por favor, tente novamente.');
    setSubmitting(false);
    setLoading(false);
  }
  
  function handleException(error,setSubmitting, setLoading ) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.message === '401') {
      Swal.fire({
        icon: 'info',
        title: 'Por questões de segurança, por favor faça login novamente',
        confirmButtonText: 'Ok',
      }).then(() => {
        setSubmitting(false);
        setLoading(false);
        window.open('/auth', '_blank');
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao salvar a Permissão',
        text: 'Por favor, verifique sua conexão de internet e tente novamente.',
        confirmButtonText: 'Ok',
      });
      setSubmitting(false);
      setLoading(false);
    }
  }


export {handleSuccess, handleError , handleException} ;

