import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';


const useBuscaCep = (cep, formik) => {
  useEffect(() => {
     const buscaCep = async () => {
       const cepFormatado = cep.replace(/[^\d]/g, '');
       if (cepFormatado.length !== 8) {
         return;
       }
       
       try {
         const response = await fetch(`https://viacep.com.br/ws/${cepFormatado}/json/`);
         const data = await response.json();
 
         if (response.status === 200) {
           if (data.erro) {
            const Toast = Swal.mixin({
              toast: true,
              position: "center-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
              }
              });
              Toast.fire({
                  icon: "error",
                  title: "Não foi possível localizar este CEP"
              });
           } else {
             formik.setValues({
               ...formik.values,
               endereco: (data.logradouro || '').toUpperCase(),
               bairro: (data.bairro || '').toUpperCase(),
               cidade: (data.localidade || '').toUpperCase(),
               estado: (data.uf || '').toUpperCase(),
               pais: 'BRASIL',
             });
           }
         }
       } catch (error) {
         console.error('Erro ao buscar endereço pelo CEP:', error);
       }
     };
     if (cep.replace(/[^\d]/g, '').length === 8) {
       buscaCep();
     }
  }, [cep, formik.values.cep]);
 };
 
 export default useBuscaCep;