import axios from 'axios';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export async function fetchOptionsProdutosGlobal() {
  try {
    const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/optionsProdutos`);
    const data = response.data;
    const formattedOptions = data.map(item => ({
      value: item.idProduto,
      label: item.produto
    }));
    localStorage.setItem('optionsProdutos', JSON.stringify(formattedOptions));
  } catch (error) {
  }
}

export function getOptionsProdutosFromStorage() {
  const storedOptions = localStorage.getItem('optionsProdutos');
  return storedOptions ? JSON.parse(storedOptions) : [];
}


