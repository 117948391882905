const PageTitulo = ({id, tipoFormulario}) => {


  return (
    <div className='row'>
      <div className='col-sm-5'>
        <h1 className='text-dark fw-bolder mb-5'>
          {id ? `Edição de ${tipoFormulario}` : `Cadastro de ${tipoFormulario}`}
        </h1>
      </div>
      {/* <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #000'}}></div>
      </div> */}
    </div>
  );
};

export default PageTitulo;
