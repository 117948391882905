import axios from 'axios'
import {AuthModel, UserModel} from './_models'
// import api from '../../../service/api'

const API_URL = process.env.REACT_APP_API_URL
const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

export const GET_USER_BY_ACCESSTOKEN_URL = `https://${API_URL_SERVER}:${API_SERVER_PORT}/auth/verify_token`
export const LOGIN_URL = `https://${API_URL_SERVER}:${API_SERVER_PORT}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const LOGOFF_URL = `https://${API_URL_SERVER}:${API_SERVER_PORT}/auth/logoff`

// Server should return AuthModel
export function login(email: string, password: string) {
  
  const login = {
     'login': email,
     'password': password
  }
  
  return axios.post<AuthModel>(LOGIN_URL, login)
}


// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export async function logOffHandler(user?: string) {
  
  try {
     const responseLogOff = await axios.post(LOGOFF_URL, {
       user: user
     });
     return responseLogOff;
  } catch (error) {
     console.error("Erro ao comunicar logoff com o backend:", error);
  }

}