import React, { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../_sysled/layout/core'
import Swal from 'sweetalert2'
import axios from 'axios'
// import {sendCampanha} from '../campanha-promocional-requests/campanha-promocional-requests'
import { Link } from 'react-router-dom'
import { Badge } from 'react-bootstrap'
import { useAuth } from '../../../modules/auth'

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

interface Promocao {
  id: number
  titulo: string
  datainicial: string
  datafinal: string
  status: number
}

export function ListagemPromocao() {
  const [promocoes, setPromocoes] = useState<Promocao[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchFiltro, setSearchFiltro] = useState('todos')
  const { currentUser } = useAuth()

  useEffect(() => {
    setLoading(true)
    fetchPromocoes()
  }, [])

  const fetchPromocoes = async (search = '', filtro = '') => {
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/campanhaPromocional`,
        {
          params: {
            search: search,
            filtro: filtro,
          },
        }
      )

      if (response.status === 200) {
        setPromocoes(response.data.content)
      } else if (response.status === 204) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center-end',
          iconColor: 'blue',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer
            toast.onmouseleave = Swal.resumeTimer
          },
        })
        Toast.fire({
          icon: 'info',
          title: 'Não existe registros de Campanhas Promocionais para essa pesquisa',
        })
        setPromocoes([])
      }
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: 'Erro ao carregar campanhas promocionais',
        text: 'Ocorreu um erro ao carregar as campanhas promocionais. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = (event) => {
    event.preventDefault()
    fetchPromocoes(searchTerm, searchFiltro)
  }

  const formatStatus = (status) => {
    switch (status) {
      case 2:
        return <Badge bg='secondary'>SINCRONIZADO</Badge>
      case 1:
        return <Badge bg='success'>ATIVO</Badge>
      case 0:
        return <Badge bg='danger'>INATIVO</Badge>
      default:
        return <Badge bg='light'>N/A</Badge>
    }
  }

  const formatarPeriodo = (datainicial: string, datafinal: string) => {
    const formatarData = (data: string) => {
      const dataPartes = data.split('T')[0].split('-')
      return `${dataPartes[2]}-${dataPartes[1]}-${dataPartes[0]}`
    }
    return `${formatarData(datainicial)} / ${formatarData(datafinal)}`
  }

  const sendPromocao = async (promocaoid, usuario) => {
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/campanhaPromocional/sendG2`,
        {
          params: {
            promocaoid: promocaoid,
            usuario: usuario,
          },
        }
      )
      if (response.status === 200) {
        await Swal.fire({
          icon: 'success',
          title: 'Encarte sincronizado com sucesso!',
          confirmButtonText: 'Ok',
          timer: 3000,
          timerProgressBar: true,
          reverseButtons: true,
        })
        fetchPromocoes()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao salvar campaha, verifique as informações preenchidas e tente novamente',
          text: 'Something went wrong!',
          confirmButtonText: 'Ok',
        })
      }
    } catch (error: any) {
    }
  }
  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <h1 className='text-dark fw-bolder'>Listagem de Promoções/Encartes</h1>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <div style={{ borderBottom: '2px solid #000' }}></div>
      </div>

      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form onSubmit={handleSearch} className='form-control-solid row mb-6'>
          <div className='row mt-8 gap-4'>
            <div className='col-sm-3'>
              <select
                className='form-select'
                value={searchFiltro}
                onChange={(e) => setSearchFiltro(e.target.value)}
              >
                <option value='todos'>Selecione</option>
                <option value='titulo'>Título</option>
              </select>
            </div>
            <div className='col-sm-3'>
              <input
                type='text'
                placeholder='Pesquisar promocao'
                className='form-control'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className='row mt-6'>
            <div className='col-sm-2  mb-6'>
              <button type='submit' className='btn btn-success form-control'>
                Pesquisar
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className='table-responsive'>
        {loading ? (
          <p>Carregando...</p>
        ) : (
          <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7'>
            <thead className='thead-dark'>
              <tr className='fw-bold fs-6 text-gray-800'>
                <th className='text-center'>Id</th>
                <th className='text-center'>Titulo</th>
                <th className='text-center'>Período Promocional</th>
                <th className='text-center'>Status</th>
                <th className='text-center'>Editar</th>
                <th className='text-center'>Sincronizar(ERP)</th>
              </tr>
            </thead>
            <tbody>
              {promocoes.map((promocao) => (
                <tr key={promocao.id}>
                  <td className='text-center'>{promocao.id}</td>
                  <td className='text-center'>{promocao.titulo}</td>
                  <td className='text-center'>
                    {formatarPeriodo(promocao.datainicial, promocao.datafinal)}
                  </td>
                  <td className='text-center'>{formatStatus(promocao.status) || 'N/A'}</td>
                  <td className='text-center'>
                    {promocao.status === 2 ? (
                      <Link
                        to={`/promocao-edicao/${promocao.id}`}
                        style={{ display: 'none' }}
                        className='btn btn-success btn-sm'
                      >
                        Editar
                      </Link>
                    ) : (
                      <Link
                        to={`/promocao-edicao/${promocao.id}`}
                        className='btn btn-success btn-sm'
                      >
                        Editar
                      </Link>
                    )}
                  </td>
                  <td className='text-center'>
                    {promocao.status === 0 || promocao.status === 2 ? (
                      <button className='btn btn-primary btn-sm disabled' style={{ display: 'none' }}>
                        Sincronizar
                      </button>
                    ) : (
                      <button
                        className='btn btn-success btn-sm'
                        onClick={() => sendPromocao(promocao.id, currentUser?.login)}
                        style={{ backgroundColor: '#3fd975', borderColor: '#0099ff' }}
                      >
                        Sincronizar
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
