import React from 'react'
import {Field, ErrorMessage} from 'formik';

function Input(props) {

  const {name, label, type} = props

  return (
    <>
    <div className='row'>
      <label htmlFor={name}>{label}</label>
    </div>
    <div>
      <Field name={name} id={name} type={type}/>
    </div>
    <ErrorMessage name={name}/>
    </>
  )
}

export default Input;

