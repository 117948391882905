import {right} from '@popperjs/core';
import React, {useEffect, useState} from 'react';
import Swal from 'sweetalert2';

type Props = {
  className: string;
  infosPessoaContato: PessoaContatoInfo[];
  onRemovePessoa: (index: number) => void;
  onEditPessoa: (index: number, pessoa: PessoaContatoInfo) => void;
  showInputRow: boolean;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddPessoa: () => void;
  onCancel: () => void;
  newPessoa: PessoaContatoInfo;
};

interface PessoaContatoInfo {
  nome: string;
  setor: string;
  email: string;
  telefone: string;
  ramal: string;
}

const TablePessoasContato: React.FC<Props> = ({
  className,
  infosPessoaContato,
  onRemovePessoa,
  onEditPessoa,
  showInputRow,
  onInputChange,
  onAddPessoa,
  onCancel,
  newPessoa,
}) => {
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editPessoa, setEditPessoa] = useState<PessoaContatoInfo | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleEditClick = (index: number) => {
    setEditIndex(index);
    setEditPessoa(infosPessoaContato[index]);
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if (name === 'telefone' && !/^\d*$/.test(value)) return; // Only allow digits
    setEditPessoa({...editPessoa!, [name]: value});
  };

  const handleSaveEdit = () => {
    if (!validateFields(editPessoa!)) return;
    onEditPessoa(editIndex!, editPessoa!);
    setEditIndex(null);
    setEditPessoa(null);
  };

  const handleCancelEdit = () => {
    setEditIndex(null);
    setEditPessoa(null);
  };

  const handleRemoveClick = (index: number) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá desfazer essa ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, remover!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        onRemovePessoa(index);
        Swal.fire('Removido!', 'O contato foi removido.', 'success');
      }
    });
  };

  const validateFields = (pessoa: PessoaContatoInfo): boolean => {
    if (!pessoa.nome || !pessoa.setor || !pessoa.email || !pessoa.telefone) {
      Swal.fire(
        'Atenção',
        'Todos os campos obrigatórios (*) de "Pessoas de Contato" devem ser preenchidos.',
        'info'
      );
      return false;
    }
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if (name === 'telefone' && !/^\d*$/.test(value)) return; // Only allow digits
    onInputChange(e);
  };

  const handleAddPessoa = () => {
    if (!validateFields(newPessoa)) return;
    onAddPessoa();
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`card ${className}`}>
      <div className='card-header' style={{display: 'none'}}>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fs-3'>Listagem de produtos selecionados</span>
        </h3>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='custom-table table'>
            {windowWidth <= 770 && !showInputRow && (
              <h2
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '30px',
                  color: '#8e8e93',
                }}
              >
                Adicionar Contato
              </h2>
            )}
            <thead>
              <tr className='fs-7 fw-bold text-gray-600 border-bottom-0'>
                <th className='p-0 pb-3 text-center'>Nome *</th>
                <th className='p-0 pb-3 text-center'>Setor *</th>
                <th className='p-0 pb-3 text-center'>Email *</th>
                <th className='p-0 pb-3 text-center'>Telefone *</th>
                <th className='p-0 pb-3 text-center'>Ramal</th>
                <th className='p-0 pb-3 text-center'>Ações</th>
              </tr>
            </thead>

            <tbody>
              {infosPessoaContato.map((infoPessoa, index) => (
                <tr key={index} style={{paddingTop: '20px'}}>
                  <td className='text-center' style={{display: 'none'}}></td>
                  {editIndex === index ? (
                    <>
                      <td data-label='Nome *' className='text-center'>
                        <input
                          className='form-control'
                          name='nome'
                          value={editPessoa!.nome}
                          onChange={handleEditInputChange}
                        />
                      </td>
                      <td data-label='Setor *' className='text-center'>
                        <input
                          className='form-control'
                          name='setor'
                          value={editPessoa!.setor}
                          onChange={handleEditInputChange}
                        />
                      </td>
                      <td data-label='Email *' className='text-center'>
                        <input
                          className='form-control'
                          name='email'
                          value={editPessoa!.email}
                          onChange={handleEditInputChange}
                        />
                      </td>
                      <td data-label='Telefone *' className='text-center'>
                        <input
                          className='form-control'
                          name='telefone'
                          value={editPessoa!.telefone}
                          onChange={handleEditInputChange}
                        />
                      </td>
                      <td data-label='Ramal' className='text-center'>
                        <input
                          className='form-control'
                          name='ramal'
                          value={editPessoa!.ramal}
                          onChange={handleEditInputChange}
                        />
                      </td>
                      <td className='text-center'>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '12px',
                          }}
                        >
                          <button
                            type='button'
                            className='btn btn-sm btn-success'
                            onClick={handleSaveEdit}
                          >
                            Salvar
                          </button>
                          <button
                            type='button'
                            className='btn btn-sm btn-danger'
                            onClick={handleCancelEdit}
                          >
                            Cancelar
                          </button>
                        </div>
                      </td>
                    </>
                  ) : (
                    <>
                      <td data-label='Nome * ' className='text-center'>
                        <input className='form-control' value={infoPessoa.nome} readOnly />
                      </td>
                      <td data-label='Setor * ' className='text-center'>
                        <input className='form-control' value={infoPessoa.setor} readOnly />
                      </td>
                      <td data-label='Email * ' className='text-center'>
                        <input className='form-control' value={infoPessoa.email} readOnly />
                      </td>
                      <td data-label='Telefone *' className='text-center'>
                        <input className='form-control' value={infoPessoa.telefone} readOnly />
                      </td>
                      <td data-label='Ramal' className='text-center'>
                        <input className='form-control' value={infoPessoa.ramal} readOnly />
                      </td>
                      <td className='text-center'>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '12px',
                          }}
                        >
                          <button
                            type='button'
                            className='btn btn-sm btn-primary'
                            onClick={() => handleEditClick(index)}
                          >
                            Editar
                          </button>
                          <button
                            type='button'
                            className='btn btn-sm btn-danger'
                            onClick={() => handleRemoveClick(index)}
                          >
                            Remover
                          </button>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))}
              {showInputRow && (
                <tr>
                  <td className='text-center' style={{display: 'none'}}></td>
                  <td className='text-center' data-label='Nome *'>
                    <input
                      className='form-control'
                      name='nome'
                      value={newPessoa.nome}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className='text-center' data-label='Setor *'>
                    <input
                      className='form-control'
                      name='setor'
                      value={newPessoa.setor}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className='text-center' data-label='Email *'>
                    <input
                      className='form-control'
                      name='email'
                      value={newPessoa.email}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className='text-center' data-label='Telefone *'>
                    <input
                      className='form-control'
                      name='telefone'
                      value={newPessoa.telefone}
                      onChange={handleInputChange}
                      pattern='\d*'
                    />
                  </td>
                  <td className='text-center' data-label='Ramal *'>
                    <input
                      className='form-control'
                      name='ramal'
                      value={newPessoa.ramal}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className='text-center'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '12px',
                      }}
                    >
                      <button
                        type='button'
                        className='btn btn-sm btn-success'
                        onClick={handleAddPessoa}
                      >
                        Salvar
                      </button>
                      <button type='button' className='btn btn-sm btn-danger' onClick={onCancel}>
                        Cancelar
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export {TablePessoasContato};
