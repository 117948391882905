import React, { useEffect, useState } from 'react'
import axios from 'axios'
interface OptionType {
  value: number
  label: string
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

const useOptionsTipoParceiro = () => {
  const [optionsPerfilParceiro, setOptionsPerfilParceiro] = useState<OptionType[]>([])

  useEffect(() => {
    async function fetchSegmentoOptions() {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros`
        )
        const data = await response.data
        const formattedOptions = data.map((item) => ({
          value: item.id,
          label: item.descricao,
          //provavelmente precisar na label, item.codigodobanco + item.nomedobanco
          // fazer concatenação label / value

        }))
        setOptionsPerfilParceiro(formattedOptions)
      } catch (error) {
        console.error('Erro ao buscar opções:', error)
      }
    }
    fetchSegmentoOptions()
  }, [])

  return { optionsPerfilParceiro }
}

export default useOptionsTipoParceiro
