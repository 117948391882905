import * as Yup from 'yup';

export const cadastroFormularioValidationSchema = Yup.object().shape({
  arquivo: Yup.string()
    .min(4, 'O nome do arquivo deve conter pelo menos 4 caracteres')
    .max(255, 'O nome do arquivo deve conter no máximo 255 caracteres')
    .required('Por favor, informe um arquivo'),
  descricao: Yup.string()
    .min(4, 'A descrição deve conter no mínimo 4 caracteres')
    .max(255, 'A descrição deve conter no máximo 255 caracteres')
    .required('Por favor, informe uma descrição'),
  status: Yup.string().required('Por favor, adicione um status'),
});
