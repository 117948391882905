import { tr } from 'date-fns/locale';
import React, { useState, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import Swal from 'sweetalert2';

type Props = {
    className: string
    infosPagamento: FormaPagamentoInfo[],
    onRemovePagamento: (index: number) => void;
    //onEditPagamento: (infoPagamento: FormaPagamentoInfo) => void;
}

interface FormaPagamentoInfo {

    idloja: number | null,
    idFormaPagamento: number,
    idCondicaoPagamento: number,
    descFormaPagamento: string,
    descCondicaoPagamento: string,
    valorRecebido: number,
    desconto: number,
    valorTotal: number,
    id?: number,
    icone: string,
    pagamentoPrincipal: number

}

const REACT_APP_IMG_URL = process.env.REACT_APP_IMG_URL;

const TableFormasDePagamento: React.FC<Props> = ({ className, infosPagamento, onRemovePagamento }) => {

    const handleRemovePagamento = (index) => {
        Swal.fire({
            title: 'Você tem certeza?',
            text: "Essa ação não pode ser desfeita!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText: 'Não, cancelar!'
        }).then((result) => {
            if (result.isConfirmed) {
                onRemovePagamento(index);
                Swal.fire(
                    'Removido!',
                    'A forma de pagamento foi removida com sucesso.',
                    'success'
                );
            }
        });
    };


    return (
        <>
            <div className={`card ${className}`}>
                <div className='card-header'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fs-3'>Listagem Formas Pagamento</span>
                    </h3>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed align-middle gs-0 gy-3 my-0' style={{ tableLayout: 'fixed', width: '100%' }}>
                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th style={{ width: '10%' }} className="p-2 text-center"></th>
                                    <th style={{ width: '10%' }} className="p-2 text-center">Forma Pag. Id</th>
                                    <th style={{ width: '10%' }} className="p-2 text-center">Descrição</th>
                                    <th style={{ width: '10%' }} className="p-2 text-center">Condição</th>
                                    <th style={{ width: '15%' }} className="p-2 text-center">Valor Recebido</th>
                                    <th style={{ width: '10%' }} className="p-2 text-center">Pagamento Principal</th>
                                    <th style={{ width: '15%' }} className="p-2 text-center">Remover</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infosPagamento.map((infoPagamento, index) => {
                                    return (
                                        <tr key={infoPagamento.idFormaPagamento || index}>
                                            <td className="text-center p-2">
                                                {infoPagamento.icone ? (
                                                    <img src={`${REACT_APP_IMG_URL}/pagamentos/${infoPagamento.icone}`} alt={infoPagamento.descFormaPagamento} style={{ width: '40px', height: 'auto' }} />
                                                ) : (
                                                    "N/A"
                                                )}
                                            </td>
                                            <td className='text-center p-2'>{infoPagamento.idFormaPagamento}</td>
                                            <td className='text-center p-2'>{infoPagamento.descFormaPagamento}</td>
                                            <td className='text-center p-2'>{infoPagamento.descCondicaoPagamento}</td>
                                            <td className='text-center p-2'>{`R$ ` + infoPagamento.valorRecebido.toLocaleString('pt-BR')}</td>
                                            <td className='text-center p-2'>
                                                {infoPagamento.pagamentoPrincipal === 1 ? <i className="bi bi-check2-all" style={{ fontSize: '24px', color: 'springgreen' }} ></i> : '-'}
                                            </td>
                                            <td className='text-center p-2'>
                                                <button type='button'
                                                    className='btn btn-sm btn-danger'
                                                    onClick={() => handleRemovePagamento(index)}
                                                >
                                                    X
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
};

export { TableFormasDePagamento }

