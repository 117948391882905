import React, {useState, useEffect} from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import {useFormik} from 'formik';
import {useAuth} from '../../../modules/auth';
import {useNavigate} from 'react-router-dom';
import {sendCliente} from '../cadastro_requests/cadastro_requests';
import useOptionsVendedores from '../../selectOptions/useOptionsVendedores';
import useOptionsSegmentos from '../../selectOptions/useOptionsSegmentos';
import FormContainer from '../../components/FormContainer';
import PageTitulo from '../../components/Pagetitulo';

export function CadastroVendedores() {
  return (
    <div className='text-light-dark'>
      <PageTitulo id={null} tipoFormulario='Vendedor' />
      <div>
        <FormContainer />
      </div>
    </div>
  );
}
