import { Badge } from 'react-bootstrap';

export const formatStatusPendencia = (status) => {
  switch (status) {
    case 0:
      return (
        <Badge bg='success' text='light' style={{ fontSize: '12px', letterSpacing: '0.5px' }}>
          APROVADO
        </Badge>
      );
    case 1:
      return (
        <Badge bg='danger' text='light' style={{ fontSize: '12px', letterSpacing: '0.5px' }}>
          RECUSADO
        </Badge>
      );
    case 2:
      return (
        <Badge bg='warning' text='light' style={{ fontSize: '12px', letterSpacing: '0.5px' }}>
          Pendente de Aprovação
        </Badge>
      );
    default:
      return <Badge bg='light'>N/A</Badge>;
  }
};

export const formatStatus = (status) => {
  switch (status) {
    case 1:
      return (
        <Badge bg='success' text='light' style={{ fontSize: '12px', letterSpacing: '0.5px' }}>
          ATIVO
        </Badge>
      );
    case 0:
      return (
        <Badge bg='danger' text='light' style={{ fontSize: '12px', letterSpacing: '0.5px' }}>
          INATIVO
        </Badge>
      );

    default:
      return <Badge bg='light'>N/A</Badge>;
  }
};
