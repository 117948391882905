import axios from 'axios';
import Swal from 'sweetalert2';
import {IItemsSeparacao, IRequestFormulariosParams} from '../../../shared/domain-types/models';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export const GET_USUARIOS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/usuarios`;
export const GET_EMPRESAS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empresas`;
export const API_PREVENDA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas`;
export const API_CLIENTES = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes`;
export const API_PRODUTOS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos`;
export const API_PARCEIROS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros`;
export const GET_FORMULARIOS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formularios`;
export const GET_FLUXO_LOGISTICO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-fluxo`;
export const GET_LOGISTICA_SEPARACAO_BY_ID = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-separacao`;
export const GET_LOGISTICA_SEPARACAO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-separacao/listar`;
export const GET_LOGISTICA_SEPARACAO_ITEMS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-separacao-itens`;
export const SEPARACAO_SYNC = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-separacao/sincronizar`;
export const GET_RESUMOSKU = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/resumoSku`;
export const GET_CATEGORIZACAO_PRODUTO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/categorizacao-produto`;
export const GET_TIPO_ENTREGA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/todosTipoEntrega`;

export function clonarPrevenda(idPrevenda) {
  return axios.post(`${API_PREVENDA}/clonarPrevenda?idPrevenda=${idPrevenda}`);
}

export function receberPagamento(idPrevenda) {
  return axios.post(`${API_PREVENDA}/receberPagamento?idPrevenda=${idPrevenda}`);
}

export function sincronizarPrevenda(idPrevenda) {
  return axios.post(`${API_PREVENDA}/sincronizarPrevenda?idPrevenda=${idPrevenda}`);
}

export function buscaHistoricoCompras(codigoCliente, idEmpresa) {
  return axios.get(
    `${API_CLIENTES}/buscaHistoricoCompra?codigoCliente=${codigoCliente}&idEmpresa=${idEmpresa}`
  );
}

export function sincronizarCliente(idCliente) {
  return axios.post(`${API_CLIENTES}/sincronizarCliente?idCliente=${idCliente}`);
}

export function sincronizarProduto(idProduto) {
  return axios.post(`${API_PRODUTOS}/sincronizarProduto?idProduto=${idProduto}`);
}

export function sincronizarParceiro(idParceiro, parceiro?: string) {
  return axios.post(`${API_PARCEIROS}/sincronizarParceiro?idParceiro=${idParceiro}`);
}

export const getFormularios = ({
  descricao,
  nomeArquivo,
  status,
  page,
  size,
}: IRequestFormulariosParams) => {
  return axios.get(
    `${GET_FORMULARIOS}?descricao=${descricao}&nomeArquivo=${nomeArquivo}&status=${status}&page=${page}`
  );
};

export const getTiposEntrega = () => {
  return axios.get(`${GET_TIPO_ENTREGA}`);
};

export const getResumoSku = (id: number) => {
  return axios.get(`${GET_RESUMOSKU}/${id}`);
};

export const getCategorizacaoProdutoById = (id: number) => {
  return axios.get(`${GET_CATEGORIZACAO_PRODUTO}/${id}`);
};

export const getCategorizacaoProduto = (
  model: number,
  titulo?: string,
  status?: string,
  page?: number
) => {
  return axios.get(
    `${GET_CATEGORIZACAO_PRODUTO}/listar?modelo=${model}&titulo=${titulo}&status=${status}&page=${page}`
  );
};

export const getFluxoLogistico = ({page}) => {
  return axios.get(`${GET_FLUXO_LOGISTICO}/listar?page=${page}`);
};

export const getLogisticaSeparacao = async (page: number) => {
  return axios.get(`${GET_LOGISTICA_SEPARACAO}?page=${page}`);
};

export const getItemsBySeparacaoId = async (id: number | string) => {
  return axios.get(`${GET_LOGISTICA_SEPARACAO_BY_ID}/${id}`);
};

export const getItemSeparacaoById = async (id: number | string) => {
  return axios.get(`${GET_LOGISTICA_SEPARACAO_ITEMS}/${id}`);
};

export const postSeparacaoItemSync = async (id: number | string) => {
  return axios.post(`${SEPARACAO_SYNC}?idSeparacao=${id}`);
};

export const putLogisticaSeparacaoItem = async (id: number | string, data: IItemsSeparacao) => {
  return axios.put(`${GET_LOGISTICA_SEPARACAO_ITEMS}/${id}`, data);
};

export function imprimirPrevenda(idPrevenda, setLoading, tipoPDF) {
  let endpoint = tipoPDF === 'revenda' ? 'gerarPdfPrevendaRevenda' : 'gerarPdfPrevenda';
  const url = `${API_PREVENDA}/${endpoint}/${idPrevenda}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `prevenda_${idPrevenda}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function visualizarPDFPrevenda(
  setModalShowPDFViewer,
  idPrevenda,
  setLoading,
  setPdfUrl,
  setIdPrevenda,
  setTipoPDF,
  tipoPDF
) {
  let endpoint = tipoPDF === 'revenda' ? 'gerarPdfPrevendaRevenda' : 'gerarPdfPrevenda';
  setModalShowPDFViewer(true);
  setTipoPDF(tipoPDF);
  const url = `${API_PREVENDA}/${endpoint}/${idPrevenda}`;
  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }
      setIdPrevenda(idPrevenda);
      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}
