/* eslint-disable eqeqeq */
import React from 'react';
import {Badge} from 'react-bootstrap';
import { ListPrecoInfo } from '../Interface';
import { useProduto } from '../hooks/useProduto';

type Props = {
  onRemoveProduct: (index: number) => void;
  onEditProduct: (index: number, infoProd: ListPrecoInfo) => void;

  editingProduct: boolean;
};

const TableListagemPreco: React.FC<Props> = ({onRemoveProduct, onEditProduct, editingProduct}) => {
  const {tabelasDePrecosProduto} = useProduto();

  const formatStatus = (status) => {
    switch (status) {
      case '1':
        return (
          <Badge bg='success' text='light' style={{fontSize: '12px', letterSpacing: '0.5px'}}>
            ATIVO
          </Badge>
        );
      case '2':
        return (
          <Badge bg='danger' text='light' style={{fontSize: '12px', letterSpacing: '0.5px'}}>
            INATIVO
          </Badge>
        );

      default:
        return <Badge bg='light'>N/A</Badge>;
    }
  };

  return (
    <>
      <div className={`card card-flush h-md-100`}>
        <div></div>
        <div className='card-header'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fs-3'>Listagem de Preços</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
              <thead>
                <tr className='fs-7 fw-bold text-gray-400 border-bottom-0'>
                  <th className='p-0 pb-3 text-center'>ID</th>
                  <th className='p-0 pb-3 text-center'>Descrição</th>
                  <th className='p-0 pb-3 text-center'>Preço</th>
                  <th className='p-0 pb-3 text-center'>Preço Promocional</th>
                  <th className='p-0 pb-3 text-center'>Status</th>
                  <th className='p-0 pb-3 text-center'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {tabelasDePrecosProduto.map((infoTabelaPreco, index) => {
                  return (
                    <tr key={index}>
                      <td className='text-center'>{infoTabelaPreco.idtabeladepreco}</td>
                      <td className='text-center'>
                        <div>{infoTabelaPreco.descricao}</div>
                      </td>
                      <td className='text-center'>{infoTabelaPreco.precovenda}</td>
                      <td className='text-center'>{infoTabelaPreco.precopromocional}</td>
                      <td className='text-center'>
                        {formatStatus(infoTabelaPreco.status.toString())}
                      </td>

                      <td className='text-center' data-toggle='tooltip'>
                        <button
                          type='button'
                          className='btn btn-sm btn-primary bi bi-pencil-square'
                          onClick={() => onEditProduct(index, infoTabelaPreco)}
                          data-toggle='tooltip'
                          data-placement='top'
                          title={'Editar'}
                        ></button>
                        <button
                          disabled={editingProduct}
                          type='button'
                          className='btn btn-sm btn-danger'
                          style={{marginLeft: 10}}
                          onClick={() => onRemoveProduct(index)}
                          data-toggle='tooltip'
                          data-placement='top'
                          title={'Remover'}
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export {TableListagemPreco};
