type Props = {
  className: string
  description: string
  color: string
  decorativos: string
  dcrtMesAnterior: string
  dcrtAnoAnterior: string
}

const CardsWidget4 = ({className, description, color, decorativos, dcrtMesAnterior, dcrtAnoAnterior }: Props) => (
  <div
    className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
    style={{
      backgroundColor: color,
    }}
  >
    <div className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>
        <span id="totalGeralCaixaDecor" className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{decorativos}</span>

        <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>{description}</span>
      </div>
    </div>
    <div className="card-body d-flex align-items-end pt-0">
      <div className="d-flex align-items-center flex-column mt-3 w-100">
        <div className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2">
          <span>Mês Anterior</span>
          <span id="totalCaixaDecorMesAnt">{dcrtMesAnterior}</span>
        </div>
        <div className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2">
          <span>Ano Anterior</span>
          <span id="totalCaixaDecorAnoAnt">{dcrtAnoAnterior}</span>
        </div>
      </div>
    </div>
  </div>
)
export {CardsWidget4}
