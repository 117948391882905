import React from 'react'
import Input from './Input'
function FormControl(props) {
  const {control, ...rest} = props

  switch(control) {
    case "Text":
        return <Input{...rest}/>;
    case "TextArea":
        return <Input{...rest}/>;
    case "Select":
        // return <Input{...rest}/>;
    case "Checkbox":
        // return <Input{...rest}/>;
    case "Radio": 
        // return <Input{...rest}/>;
    case "Date":
        // return <Input{...rest}/>;
    default: 
        return null;
  }

}

export default FormControl;