import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { useEffect, useState } from 'react';
import AvatarConsultor from '../../proposta-pedido/components/AvatarConsultor';
import axios from 'axios';
import { Expedicao, ExpedicaoProdutoRomaneio } from './Interface';
import Swal from 'sweetalert2';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { imprimirPDF, visualizarPDF, enviarVuupt } from './listagem_requests';
import { Modal, Button, Spinner } from 'react-bootstrap';
import PDFPrevenda from '../../proposta-pedido/components/PdfPrevendaPedido';
import React from 'react';

import { Dropdown, Form, ButtonGroup } from 'react-bootstrap'

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const LogisticaExpedicao: React.FC = () => {

  const [expedicao, setExpedicao] = useState<Expedicao[]>([]);
  const [expedicaoRomaneio, setExpedicaoRomaneio] = useState<ExpedicaoProdutoRomaneio[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTables, setLoadingTables] = useState({});
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);

  const [valueDateRange, setValueDateRange] = useState<DateRange | null>([new Date(), new Date()]);
  const [idCliente, setIdCliente] = useState('');
  const [idG2, setIdG2] = useState('');
  const [idSysled, setIdSysled] = useState('');
  const [idEmpresa, setIdEmpresa] = useState('');
  const [numeroSeparacao, setNumeroSeparacao] = useState('');

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [tipoPDF, setTipoPDF] = useState('');
  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);

  const [visibleTables, setVisibleTables] = useState<{ [key: string]: boolean }>({});
  const [visibleTableId, setVisibleTableId] = useState<string | null>(null);

  const [selectedIds, setSelectedIds] = useState<{ [key: number]: string[] }>({});
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [currentParentId, setCurrentParentId] = useState<number | null>(null);

  function convertToLocalTimeZone(date) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = toZonedTime(date, timeZone);
    return formatInTimeZone(zonedDate, timeZone, 'yyyy-MM-dd');
  }

  const statusMappingStatus = {
    'A Separar': '1',
    'Armazenado': '5',
    'Em Separação': '2',
    'Entregue': '7',
    'Enviando Carga': '6',
    'Pendencia Compra': '8',
    'Pendencias': '3',
    'Separado': '4',
  };

  const statusMappingTipoSep = {
    'Balcão': '54',
    'Cliente em Loja': '32',
    'E-Commerce': '52',
    'Expedição': '5',
    'Produzir': '50',
    'Retirada Loja': '2',
    'Retirada CD': '31',
    'Transportadora': '48',
    'Uber': '53',
    'Urgente': '33'
  };

  const statusMappingData = {
    'A Separar': 'data_aseparar',
    'Em Separação': 'data_emseparacao',
    'Separado Parcial': 'data_separadoparcial',
    'Pendência': 'data_pendencia',
    'Separado': 'data_separado',
    'Armazenado': 'data_armazenado',
    'Carga': 'data_carga',
    'Retirada': 'data_retirada',
    'Entrega Prevista': 'dataentrega_prevista',
  };


  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [selectedOptionsStatus, setSelectedOptionsStatus] = useState<string[]>([]);

  const [isOpenTipoSep, setIsOpenTipoSep] = useState(false);
  const [selectedOptionsTipoSep, setSelectedOptionsTipoSep] = useState<string[]>([]);


  const [isOpenTipoData, setIsOpenTipoData] = useState(false);
  const [selectedOptionsTipoData, setSelectedOptionsTipoData] = useState<string[]>([]);

  const optionsStatus = Object.keys(statusMappingStatus);
  const optionsTipoSep = Object.keys(statusMappingTipoSep);
  const optionsTipoData = Object.keys(statusMappingData);

  const toggleDropdownStatus = () => setIsOpenStatus((prev) => !prev);
  const toggleDropdownTipoSep = () => setIsOpenTipoSep((prev) => !prev);
  const toggleDropdownTipoData = () => setIsOpenTipoData((prev) => !prev);

  const handleSelectStatus = (option) => {
    setSelectedOptionsStatus((prev) => {
      if (prev.includes(option)) {
        return prev.filter((item) => item !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const handleSelectTipoSep = (option) => {
    setSelectedOptionsTipoSep((prev) => {
      if (prev.includes(option)) {
        return prev.filter((item) => item !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const handleSelectTipoData = (option) => {
    setSelectedOptionsTipoData((prev) => {
      if (prev.includes(option)) {
        return prev.filter((item) => item !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const getSelectedValuesStatus = () => {
    return selectedOptionsStatus.map((option) => statusMappingStatus[option]);
  };

  const getSelectedValuesTipoSep = () => {
    return selectedOptionsTipoSep.map((option) => statusMappingTipoSep[option]);
  }

  const getSelectedValuesTipoData = () => {
    return selectedOptionsTipoData.map((option) => statusMappingData[option]);
  }

  useEffect(() => {
    setExpedicao([]);
    fetchExpedicao();
    setSelectedIds([]);
    setSelectedParentId(null);

    localStorage.removeItem('url');
    localStorage.removeItem('idRomaneio');

  }, []);

  const handleDateRangeChange = (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    if (value === null) {
      setValueDateRange(null);
    } else {
      setValueDateRange([value[0], value[1]]);
    }
  };

  const fetchExpedicao = async (
    idCliente = '',
    idPedidoG2 = '',
    idPedidoSysled = '',
    dataFinal = '',
    dataInicial = '',
    idEmpresa = '',
    numeroSeparacao = '',
    kanban = getSelectedValuesStatus().join(','),
    tipoSeparacao = getSelectedValuesTipoSep().join(','),
    dataCampoPadrao = getSelectedValuesTipoData().join(',')
  ) => {
    try {
      setLoading(true);

      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/painel/expedicao`,
        {
          params: {
            idCliente: idCliente,
            idPedidoG2: idG2,
            idPedidoSysled: idSysled,
            dataFinal: dataFinal,
            dataInicial: dataInicial,
            idEmpresa: idEmpresa,
            numeroSeparacao: numeroSeparacao,
            kanban: kanban,
            tipoSeparacao: tipoSeparacao,
            dataCampoPadrao: dataCampoPadrao,
          },
        }
      );

      if (response.status === 200) {
        setExpedicao([response.data]);
      } else if (response.status === 204) {
        setExpedicao([]);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {

    setSelectedIds([]);
    setSelectedParentId(null);
    setExpedicao([]);
    event.preventDefault();
    let dataInicial = '';
    let dataFinal = '';
    if (valueDateRange && valueDateRange.length > 0) {
      dataInicial = convertToLocalTimeZone(valueDateRange[0]);
      dataFinal = convertToLocalTimeZone(valueDateRange[1]);
    }

    fetchExpedicao(
      idCliente,
      idG2,
      idSysled,
      dataFinal,
      dataInicial,
      idEmpresa,
      numeroSeparacao,
    );
  };


  const fetchExpedicaoRomaneio = async (
    idsEntrega = ''
  ) => {
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/painel/produto-romaneio`,
        {
          params: {
            idsEntrega
          },
        }
      );

      if (response.status === 200) {
        setExpedicaoRomaneio(response.data);
      } else if (response.status === 204) {
        setExpedicaoRomaneio([]);
        Swal.fire({
          icon: 'info',
          title: `Não existe registros para essa pesquisa`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message === '401') {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          setLoading(false);
          window.open('/auth', '_blank');
        });
      }
    } finally {
      setLoading(false);
    }

  }

  const mostrarSKU = (id: string) => {
    setVisibleTables(prevState => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        // Define todas as tabelas como não visíveis
        acc[key] = false;
        return acc;
      }, {});

      // Se o ID clicado não é o ID visível atualmente, define o ID clicado como visível
      if (prevState[id]) {
        // Se o mesmo ID é clicado novamente, fecha a tabela
        newState[id] = false;
      } else {
        // Caso contrário, define o ID clicado como visível
        newState[id] = true;
      }

      return newState;
    });

    // Atualiza o estado de carregamento
    setLoadingTables(prevState => ({
      ...prevState,
      [id]: true,
    }));

    // Atualiza o ID da tabela visível
    setVisibleTableId(prevId => {
      // Se o mesmo ID é clicado novamente, fecha a tabela
      if (prevId === id) {
        return null;
      } else {
        return id;
      }
    });

    // Carrega os dados para o ID clicado
    fetchExpedicaoRomaneio(id).then(() => {
      setLoadingTables(prevState => ({
        ...prevState,
        [id]: false,
      }));
    });
  };

  const dadosAgrupados = expedicao.reduce((acc, item) => {
    Object.entries(item).forEach(([numdocumento, entregas]) => {
      if (!acc[numdocumento]) {
        acc[numdocumento] = [];
      }
      acc[numdocumento].push(...entregas);
    });
    return acc;
  }, {} as { [key: string]: Expedicao[] });

  const kanbanColors: { [key: string]: string } = {
    'ARMAZENADO': '#ff6f00',
    'EM_SEPARACAO': '#ffd700',
    'ENTREGUE': '#1e90ff',
    'ENVIADO_CARGA': '#6a0dad',
    'PENDENCIA_COMPRA': '#ff69b4',
    'PENDENCIAS': '#ff4500',
    'SEPARADO': '#32cd32',
    'A_SEPARAR': '#c4c1c1'
  };

  const formatKanban = (kanban: string) => {
    if (kanban === 'PENDENCIA_COMPRA') return 'Entrega Futura';
    return kanban
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const handleCheckboxChange = (id: string, isChecked: boolean, idPai: number) => {
    if (idPai !== currentParentId) {
      setSelectedIds({});
      setCurrentParentId(idPai);
    }

    if (isChecked) {
      setSelectedIds((prevSelected) => ({
        ...prevSelected,
        [idPai]: [...(prevSelected[idPai] || []), id],
      }));
    } else {
      setSelectedIds((prevSelected) => ({
        ...prevSelected,
        [idPai]: (prevSelected[idPai] || []).filter((selectedId) => selectedId !== id),
      }));

      if ((selectedIds[idPai] || []).length === 1) {
        setSelectedIds((prevSelected) => {
          const { [idPai]: _, ...rest } = prevSelected;
          return rest;
        });
      }
    }
  }


  useEffect(() => {
  }, [selectedIds]);

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
          Painel de Controle Logistica Expedição
        </h1>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <div style={{ borderBottom: '2px solid #878787' }}></div>
      </div>

      <div className='mt-8'>
        <h4 className='text-dark' style={{ marginBottom: '20px' }}>FILTROS DE PESQUISA</h4>
        <form onSubmit={handleSearch} className='gap-6'>

          <div className='row mt-3'>
            <div className='col-md-6 col-lg-3 mb-3'>
              <label htmlFor='' className='form-label'>
                Número Pedido G2
              </label>
              <input
                type='text'
                className='form-control w-100'
                value={idG2}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setIdG2(value);
                }}
              />
            </div>

            <div className='col-md-6 col-lg-3 mb-3'>
              <label htmlFor='' className='form-label'>
                Número Pedido Sysled
              </label>
              <input
                type='text'
                className='form-control w-100'
                value={idSysled}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setIdSysled(value);
                }}
              />
            </div>

            <div className='col-md-6 col-lg-3 mb-3'>
              <label htmlFor='' className='form-label'>
                Número Separação
              </label>
              <input
                type='text'
                className='form-control w-100'
                value={numeroSeparacao}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setNumeroSeparacao(value);
                }}
              />
            </div>

            <div className='col-md-6 col-lg-3 mb-3'>
              <label htmlFor='' className='form-label'>
                Id Cliente
              </label>
              <input
                type='text'
                className='form-control w-100'
                value={idCliente}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setIdCliente(value);
                }}
              />
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-6 col-lg-3 mb-3'>
              <label htmlFor='' className='form-label'>
                Id Empresa
              </label>
              <input
                type='text'
                className='form-control w-100'
                value={idEmpresa}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setIdEmpresa(value);
                }}
              />
            </div>

            <div className='col-md-6 col-lg-3 mb-3'>
              <label htmlFor='' className='form-label'>
                Tipo da Data
              </label>
              <Dropdown
                as={ButtonGroup}
                show={isOpenTipoData}
                autoClose="outside"
                onToggle={toggleDropdownTipoData}
                className="w-100"
                style={{ border: '1px solid #ced4da', borderRadius: '5px', backgroundColor: '#fff' }}
              >
                <Dropdown.Toggle
                  variant="outline-secondary"
                  className="form-control text-start no-caret"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    backgroundImage: 'none',
                  }}
                >
                  {selectedOptionsTipoData.length === 0 ? 'Selecione Tipo da Data' : selectedOptionsTipoData.join(', ')}
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100">
                  {optionsTipoData.map((option, index) => (
                    <Dropdown.Item key={index} as="div">
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${index}`}
                        label={option}
                        checked={selectedOptionsTipoData.includes(option)}
                        onChange={() => handleSelectTipoData(option)}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className='col-md-6 col-lg-3 mb-3'>
              <label htmlFor='' className='form-label'>
                Data
              </label>
              <DateRangePicker
                size='lg'
                appearance='default'
                onChange={handleDateRangeChange}
                value={valueDateRange}
                format='dd-MM-yyyy'
                ranges={[
                  { label: 'Ontem', value: [addDays(new Date(), -1), addDays(new Date(), -1)] },
                  { label: 'Hoje', value: [new Date(), new Date()] },
                  { label: 'Último 7 dias', value: [subDays(new Date(), 6), new Date()] },
                  { label: 'Este mês', value: [startOfMonth(new Date()), endOfMonth(new Date())] },
                ]}
                placeholder='Data'
                defaultValue={[new Date(), new Date()]}
                className={`w-100`}
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: '5px',
                  backgroundColor: '#fff',
                }}
              />
            </div>

            <div className='col-md-6 col-lg-3 mb-3'>
              <label htmlFor='' className='form-label'>
                Status
              </label>
              <Dropdown
                as={ButtonGroup}
                show={isOpenStatus}
                autoClose="outside"
                onToggle={toggleDropdownStatus}
                className="w-100"
                style={{ border: '1px solid #ced4da', borderRadius: '5px', backgroundColor: '#fff' }}
              >
                <Dropdown.Toggle
                  variant="outline-secondary"
                  className="form-control text-start no-caret"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    backgroundImage: 'none',
                  }}
                >
                  {selectedOptionsStatus.length === 0 ? 'Selecione Status' : selectedOptionsStatus.join(', ')}
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100">
                  {optionsStatus.map((option, index) => (
                    <Dropdown.Item key={index} as="div">
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${index}`}
                        label={option}
                        checked={selectedOptionsStatus.includes(option)}
                        onChange={() => handleSelectStatus(option)}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-6 col-lg-3 mb-3'>
              <label htmlFor='' className='form-label'>
                Tipo de Separação
              </label>
              <Dropdown
                as={ButtonGroup}
                show={isOpenTipoSep}
                autoClose="outside"
                onToggle={toggleDropdownTipoSep}
                className="w-100"
                style={{ border: '1px solid #ced4da', borderRadius: '5px', backgroundColor: '#fff' }}
              >
                <Dropdown.Toggle
                  variant="outline-secondary"
                  className="form-control text-start no-caret"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    backgroundImage: 'none',
                  }}
                >
                  {selectedOptionsTipoSep.length === 0 ? 'Selecione Tipo Separação' : selectedOptionsTipoSep.join(', ')}
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100">
                  {optionsTipoSep.map((option, index) => (
                    <Dropdown.Item key={index} as="div">
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${index}`}
                        label={option}
                        checked={selectedOptionsTipoSep.includes(option)}
                        onChange={() => handleSelectTipoSep(option)}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col-md-6 col-lg-3 mb-3">
            </div>

            <div className="col-md-6 col-lg-3 mb-3">
            </div>

            <div className="col-md-6 col-lg-3 mb-3">

              <label htmlFor='' className='form-label'>
                &nbsp;
              </label>

              <button type='submit' className='btn btn-success w-100'>
                Pesquisar
              </button>
            </div>

          </div>
        </form>
      </div >

      <div className='table-responsive'>
        {loading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ height: '100%' }}
          >
            <div
              className='spinner-border text-success m-5'
              style={{ width: '3rem', height: '3rem' }}
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <table className='table table-hover table-striped table-rounded gy-5 gs-5 p-2 table-responsive'
            style={{
              border: '0.5px solid #e2e2e2',
              borderRadius: '5px'
            }}>
            <thead className='thead-dark'>
              <tr className='fw-bold text-gray-800'>
                <th className='text-center' style={{ width: '30%' }}>Resumo Pedido</th>
                <th className='text-center' style={{ width: '70%' }}>Resumo SKU</th>
              </tr>
            </thead>
            <tbody style={{ borderTop: '1px solid #e2e2e2' }}>
              {Object.entries(dadosAgrupados).map(([numdocumento, expedicao]) => {
                const formatPhoneNumber = (number) => {
                  if (!number || number === '0') return '';

                  let cleaned = ('' + number).replace(/\D/g, '');

                  if (cleaned.length === 13) {
                    cleaned = cleaned.slice(2);
                  }

                  if (cleaned.length === 12) {
                    cleaned = cleaned.slice(2);
                  }

                  if (cleaned.length === 11) {
                    return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
                  }

                  if (cleaned.length === 10) {
                    return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 6)}-${cleaned.slice(6)}`;
                  }

                  if (number.match(/^\(\d{2}\)\d{5}-\d{4}$/)) {
                    return number;
                  }

                  if (cleaned.length === 8) {
                    return `${cleaned.slice(0, 4)}-${cleaned.slice(5 - 9)}`;
                  }

                  if (cleaned.length === 9) {
                    return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
                  }

                  return number;
                };

                const telefoneCliente1 = expedicao[0].telefone_cliente_1;
                const telefoneCliente2 = expedicao[0].telefone_cliente_2;

                const formattedPhone1 = formatPhoneNumber(telefoneCliente1);
                const formattedPhone2 = formatPhoneNumber(telefoneCliente2);

                const telefoneConsultor1 = expedicao[0].telefone_consultor_1;
                const telefoneConsultor2 = expedicao[0].telefone_consultor_2;

                const formattedPhone3 = formatPhoneNumber(telefoneConsultor1);
                const formattedPhone4 = formatPhoneNumber(telefoneConsultor2);

                const consultorPhoneString = [
                  formattedPhone3,
                  (formattedPhone4 && formattedPhone3 !== formattedPhone4) ? formattedPhone4 : ''
                ].filter(Boolean).join(' - ');

                const selectedEntregas = expedicao.filter(item => selectedIds[item.numdocumento]?.includes(item.entregaid));

                const hasKanbanArmazenado = selectedEntregas.some(item => item.kanban === "armazenado");

                const hasNonArmazenado = selectedEntregas.some(item => item.kanban !== "armazenado");

                const isButtonEnabled = hasKanbanArmazenado && !hasNonArmazenado;

                return (
                  <tr key={numdocumento} style={{ verticalAlign: 'top' }}>
                    <td className='text-end'>
                      <table className='table table-rounded' style={{ border: '1px solid #e2e2e2', backgroundColor: '#1e90ff', color: '#fff' }}>
                        <tbody>
                          <tr>
                            <th className='fw-bold text-start'>Número do G2:</th>
                            <td className='text-end'>{numdocumento}</td>
                          </tr>
                          <tr>
                            <th className='fw-bold text-start'>Número do Sysled:</th>
                            <td className='text-end'>{expedicao[0].IdSysledPrev}</td>
                          </tr>
                          <tr>
                            <th className='fw-bold text-start'>Cliente:</th>
                            <td>{expedicao[0].razaoSocial}</td>
                          </tr>
                          {formattedPhone1 && (
                            <tr>
                              <th className='fw-bold text-start'>Contato 1:</th>
                              <td>{formattedPhone1}</td>
                            </tr>
                          )}
                          {formattedPhone2 && formattedPhone1 !== formattedPhone2 && (
                            <tr>
                              <th className='fw-bold text-start'>Contato 2:</th>
                              <td>{formattedPhone2}</td>
                            </tr>
                          )}

                          {expedicao[0].clienteEnderecoEntrega === '' ? (
                            <tr>
                              <th className='fw-bold text-start'>Endereço de entrega:</th>
                              <td>
                                {expedicao[0].clienteEndereco?.toUpperCase()} - {expedicao[0].clienteEnderecoNumero?.toUpperCase()} - {expedicao[0].clienteEnderecoBairro?.toUpperCase()} - {expedicao[0].clienteEnderecoCidade?.toUpperCase()} - {expedicao[0].clienteEnderecoUF?.toUpperCase()} -  {expedicao[0].clienteEnderecoCEP?.toUpperCase()}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <th className='fw-bold text-start'>Endereço de entrega:</th>
                              <td>
                                {expedicao[0].clienteEnderecoEntrega?.toUpperCase()} - {expedicao[0].clienteNumeroEntrega?.toUpperCase()} - {expedicao[0].clienteBairroEntrega?.toUpperCase()} - {expedicao[0].clienteCidadeEntrega?.toUpperCase()} - {expedicao[0].clienteCepEntrega?.toUpperCase()}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th className='fw-bold text-start'>
                              <AvatarConsultor
                                idFuncionario={expedicao[0].idConsultor}
                                funcionario={expedicao[0].consultor_nome_fantasia}
                                style={{ borderRadius: '50px' }} />
                            </th>
                            <td className='text-end'>
                              {expedicao[0].consultor_nome_fantasia ? expedicao[0].consultor_nome_fantasia : expedicao[0].consultor_razao_social}
                              {consultorPhoneString && ` - ${consultorPhoneString}`}
                            </td>
                          </tr>
                          {expedicao[0].observacaoPrevenda && expedicao[0].observacaoPrevenda.trim() !== '' && (
                            <tr>
                              <th className='fw-bold text-start'>Observação do Pedido:</th>
                              <td className='text-end'>
                                {expedicao[0].observacaoPrevenda}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className='text-center'
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: '10px',
                        }}>
                        <div className='dropdown'>
                          <button
                            className='btn btn-sm btn-primary dropdown-toggle'
                            data-bs-toggle='dropdown'
                          >
                            <i className='bi bi-file-earmark-arrow-down'
                              style={{ fontSize: '14px' }}></i>
                            PDF
                          </button>
                          <ul className='dropdown-menu'>
                            <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                onClick={() => {
                                  const numDocumento = expedicao[0].numdocumento;
                                  const selectedIdsArray = Object.values(selectedIds).flat();

                                  const isIdsEntrega = selectedIdsArray.length > 0;

                                  const idsToSend = currentParentId && selectedIds[currentParentId] && currentParentId.toString() === numDocumento
                                    ? selectedIdsArray
                                    : [numDocumento]

                                  visualizarPDF(
                                    setModalShowPDFViewer,
                                    idsToSend,
                                    setLoadingPDF,
                                    setPdfUrl,
                                    expedicao[0].numdocumento,
                                    setTipoPDF,
                                    'romaneio',
                                    isIdsEntrega
                                  );
                                }}
                              >
                                ROMANEIO
                              </button>
                            </li>
                            <li>
                              <hr className='dropdown-divider' />
                            </li>
                            <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                onClick={() => {
                                  const numDocumento = expedicao[0].numdocumento;
                                  const selectedIdsArray = Object.values(selectedIds).flat();

                                  const isIdsEntrega = selectedIdsArray.length > 0;

                                  const idsToSend = currentParentId && selectedIds[currentParentId] && currentParentId.toString() === numDocumento
                                    ? selectedIdsArray
                                    : [numDocumento]

                                  visualizarPDF(
                                    setModalShowPDFViewer,
                                    idsToSend,
                                    setLoadingPDF,
                                    setPdfUrl,
                                    expedicao[0].numdocumento,
                                    setTipoPDF,
                                    'ambientado',
                                    isIdsEntrega
                                  );
                                }}
                              >
                                AMBIENTADO
                              </button>
                            </li>
                          </ul>
                        </div>
                        {expedicao.length > 0 && (
                          <button
                            className="btn btn-success btn-sm"
                            aria-label="Sincronizar com Vuupt"
                            onClick={() => {
                              const numDocumento = expedicao[0].numdocumento;
                              const selectedIdsArray = Object.values(selectedIds).flat();

                              const isIdsEntrega = selectedIdsArray.length > 0;

                              const idsToSend = currentParentId && selectedIds[currentParentId] && currentParentId.toString() === numDocumento
                                ? selectedIdsArray
                                : [numDocumento];

                              enviarVuupt(
                                idsToSend,
                                expedicao[0].numdocumento,
                                isIdsEntrega,
                              );
                            }}
                            disabled={!isButtonEnabled}
                          >
                            <i className="bi bi-arrow-down-up" style={{ fontSize: '14px' }} />
                            &nbsp;
                            Sincronizar
                          </button>
                        )}
                      </div>
                    </td>

                    <td className='text-center'>
                      <div style={{ padding: '10px', marginBottom: '10px' }}>
                        <table className="table table-hover table-striped table-rounded" style={{ border: '1px solid #e2e2e2', textAlign: 'center' }}>
                          <thead>
                            <tr className="fw-bold" style={{ backgroundColor: '#1e90ff', color: '#ffffff' }}>
                              <th style={{ textAlign: 'left' }}> <span className="bi bi-box-seam" style={{ fontSize: '20px' }}></span></th>
                              <th style={{ textAlign: 'left' }}>Observação Entrega</th>
                              <th style={{ textAlign: 'left' }}>Tipo Separação</th>
                              <th style={{ textAlign: 'right' }}>Data Emissão</th>
                              <th>Status Kanban</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {expedicao
                              .filter((item, index, self) =>
                                index === self.findIndex(e => e.entregaid === item.entregaid)
                              )
                              .map((expedicao, index) => (
                                <tr key={index} style={{ verticalAlign: 'middle' }}>
                                  <td style={{ textAlign: 'left' }}>
                                    {expedicao.qtdProdutosAmbientados > 0 && (
                                      <span
                                        className="bi bi-lightbulb-fill"
                                        style={{
                                          marginRight: '5px',
                                          color: '#FFD700',
                                          borderRadius: '0 4px 4px 0',
                                          fontSize: '16px',
                                        }}
                                      />
                                    )}
                                    {expedicao.entregaid}
                                    <span
                                      className={`bi ${visibleTables[expedicao.entregaid] ? 'bi-caret-up' : 'bi-caret-down'}`}
                                      id={`arrowList-${expedicao.entregaid}`}
                                      style={{
                                        marginLeft: '5px',
                                        borderRadius: '0 4px 4px 0',
                                        color: '#888989',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                      }}
                                      onClick={() => mostrarSKU(expedicao.entregaid)}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'left' }}>{expedicao.observacao_entrega ? expedicao.observacao_entrega : '-'}</td>
                                  <td style={{ textAlign: 'left' }}>{expedicao.tipo_separacao ? expedicao.tipo_separacao : '-'}</td>
                                  <td style={{ textAlign: 'right' }}>
                                    {new Date(expedicao.dataemissao).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                  </td>
                                  <td>
                                    <span
                                      style={{
                                        backgroundColor: kanbanColors[expedicao.kanban?.toUpperCase()] || '#000000',
                                        color: '#FFF',
                                        borderRadius: '5px',
                                        width: '150px',
                                        fontWeight: '550',
                                        display: 'inline-block',
                                        textAlign: 'center'
                                      }}
                                    >
                                      {formatKanban(expedicao.kanban?.toUpperCase() || '')}
                                    </span>
                                  </td>

                                  <td className='text-center'
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`checkbox-${expedicao.entregaid}`}
                                      checked={selectedIds[expedicao.numdocumento]?.includes(expedicao.entregaid) || false}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          expedicao.entregaid,
                                          (e.target as HTMLInputElement).checked,
                                          parseInt(expedicao.numdocumento)
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>

                        {expedicao
                          .filter((item, index, self) =>
                            index === self.findIndex(e => e.entregaid === item.entregaid)
                          )
                          .map((expedicao, index) => (
                            visibleTableId === expedicao.entregaid && (
                              loadingTables[expedicao.entregaid] ? (
                                <div key={index} className='alert alert-info'>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </div>
                              ) : (
                                expedicaoRomaneio.length > 0 ? (
                                  <table key={index} className='table table-striped table-rounded' style={{ border: '1px solid #e2e2e2' }}>
                                    <thead>
                                      <tr className='fw-bold' >
                                        <th style={{ textAlign: 'left' }} ><span className="bi bi-lamp" style={{ fontSize: '20px' }}></span> </th>
                                        <th style={{ textAlign: 'left' }}>SKU </th>
                                        <th style={{ textAlign: 'left' }}>MARCA</th>
                                        <th style={{ textAlign: 'left' }}>AMBIENTE</th>
                                        <th style={{ textAlign: 'right' }}>QUANTIDADE</th>
                                        <th style={{ textAlign: 'right' }}>PREÇO UNITÁRIO</th>
                                        <th style={{ textAlign: 'right' }}>TOTAL</th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ borderTop: '1px solid grey' }}>
                                      {expedicaoRomaneio.map((romaneioItem, index) => (
                                        <tr key={index}>
                                          <td style={{ textAlign: 'left' }}>{romaneioItem.idProduto}</td>
                                          <td style={{ textAlign: 'left' }}>{romaneioItem.produto}</td>
                                          <td style={{ textAlign: 'left' }}>{romaneioItem.marca}</td>

                                          <td style={{ textAlign: 'left' }}>
                                            {romaneioItem.ambiente || romaneioItem.ambienteComplemento ? (
                                              <>
                                                {romaneioItem.ambiente}
                                                {romaneioItem.ambiente && romaneioItem.ambienteComplemento ? ' - ' : ''}
                                                {romaneioItem.ambienteComplemento}
                                              </>
                                            ) : (
                                              '-'
                                            )}
                                          </td>

                                          <td style={{ textAlign: 'right' }}>{parseInt(romaneioItem.quantidade)}</td>
                                          <td style={{ textAlign: 'right' }}>{parseFloat(romaneioItem.precoLiquido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                          <td style={{ textAlign: 'right' }}>{parseFloat(romaneioItem.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div key={index} className='alert alert-info'>Nenhum SKU encontrado para o número de separação {expedicao.entregaid}</div>
                                )
                              )
                            )
                          ))}

                        <div style={{ overflow: 'auto', height: '300px', width: '100%', float: 'left', marginTop: '20px' }}>
                          <table className='table table-hover ' style={{ border: '1px solid #e2e2e2' }}>
                            <thead>
                              <tr className='fw-bold'>
                                <th>Cód Volume:</th>
                                <th>Núm Volume:</th>
                                <th>Cód Pedido:</th>
                                <th>Cód Cliente:</th>
                                <th>Nome Cliente:</th>
                                <th>Cód Box Posição:</th>
                                <th>Posição Box:</th>
                                <th>Desc Box:</th>
                                <th>Cód Conferente:</th>
                                <th>Nome Conferente:</th>
                                <th>Tipo Pedido:</th>
                                <th>Obs:</th>
                              </tr>
                            </thead>
                            <tbody style={{ borderTop: '1px solid #e2e2e2' }}>
                              {expedicao.map((expedicao, index) => (
                                <tr key={index} style={{ verticalAlign: 'middle' }}>
                                  <td>{expedicao.CodVolume ? expedicao.CodVolume : '-'} </td>
                                  <td>{expedicao.NumeroDoVolume ? expedicao.NumeroDoVolume : '-'}</td>
                                  <td>{expedicao.CodigoPedido ? expedicao.CodigoPedido : '-'}</td>
                                  <td>{expedicao.CodigoCliente ? expedicao.CodigoCliente : '-'}</td>
                                  <td>{expedicao.razaoSocial ? expedicao.razaoSocial : '-'}</td>
                                  <td>{expedicao.CodBoxPosicao ? expedicao.CodBoxPosicao : '-'}</td>
                                  <td>{expedicao.PosicaoBox ? expedicao.PosicaoBox : '-'}</td>
                                  <td>{expedicao.DescBox ? expedicao.DescBox : '-'}</td>
                                  <td>{expedicao.CodConferente ? expedicao.CodConferente : '-'}</td>
                                  <td>{expedicao.NomeConferente ? expedicao.NomeConferente : '-'}</td>
                                  <td>{expedicao.TipoPedido ? expedicao.TipoPedido : '-'}</td>
                                  <td>{expedicao.Observacao ? expedicao.Observacao : '-'}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              }
              )}
            </tbody>
          </table>
        )}
      </div >

      <Modal
        size={'xl'}
        show={modalShowPDFViewer}
        onHide={() => setModalShowPDFViewer(false)}
        style={{ width: '100%' }}
      >
        <Modal.Header style={{ justifyContent: 'flex-end', gap: 10 }}>
          <Button
            variant='primary'
            onClick={() => {
              imprimirPDF(tipoPDF);
            }}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => {
            localStorage.removeItem('url');
            localStorage.removeItem('idRomaneio');
            setModalShowPDFViewer(false)
          }}>
            Fechar
          </Button>
        </Modal.Header>

        {loadingPDF ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </Spinner>
          </div>
        ) : (
          <Modal.Body>
            {pdfUrl && <PDFPrevenda source={pdfUrl} />}
          </Modal.Body>
        )}
      </Modal>
    </div >
  );
}

export default LogisticaExpedicao;