import { useEffect } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type Props = {
  description: string;
  withLink?: boolean;
  redirectTo?: string;
  buttonTitle?: string;
  isSub?: boolean;
  isButtonAction?: boolean;
  variantButton?: string;
  handleAction?: () => void;
  isDisabled?: boolean;
  showTooltip?: boolean;          // Nova prop para controlar o tooltip
  tooltipText?: string;           // Nova prop para definir o texto do tooltip
};

const DescriptionPage: React.FC<Props> = ({
  description,
  withLink,
  redirectTo,
  buttonTitle,
  isSub,
  isButtonAction,
  handleAction,
  variantButton,
  isDisabled,
  showTooltip = false,           // Valor padrão para não mostrar tooltip
  tooltipText = "Ação indisponível no momento.", // Texto padrão para o tooltip
}) => {
  useEffect(() => {
    console.log('disable in component', isDisabled);
  }, [isDisabled]);

  // Tooltip content usando o texto passado como prop
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipText}
    </Tooltip>
  );

  return (
    <>
      <div
        className="mb-3 form-label-container"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '30px',
        }}
      >
        <h1
          className="fw-bolder"
          style={{ color: '#3f3f3f', fontSize: isSub ? '1.4rem' : '2rem' }}
        >
          {description}
        </h1>

        {withLink && redirectTo ? (
          <Link
            to={redirectTo}
            className="btn btn-success btn-sm"
            style={{ fontSize: '14px' }}
          >
            {buttonTitle}
          </Link>
        ) : null}

        {isButtonAction && handleAction ? (
          <OverlayTrigger
            placement="top"
            overlay={isDisabled && showTooltip ? renderTooltip : <></>}
          >
            <span className="d-inline-block">
              <Button
                variant={variantButton || 'success'}
                onClick={() => handleAction()}
                disabled={isDisabled}
                style={{
                  opacity: isDisabled ? '0.5' : '1',
                  pointerEvents: isDisabled ? 'none' : 'auto',
                }}
              >
                {buttonTitle}
              </Button>
            </span>
          </OverlayTrigger>
        ) : null}
      </div>
      <div style={{ marginBottom: '8px' }}>
        <div style={{ borderBottom: '2px solid #878787' }}></div>
      </div>
    </>
  );
};

export default DescriptionPage;
