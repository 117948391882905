import axios from "axios";
import Swal from "sweetalert2";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

export const API_PAINEL_EXP = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/painel`

export function imprimirPDF(tipoPDF) {

  let url = localStorage.getItem('url') ?? '';
  localStorage.removeItem('url');
  
  let idRomaneio = localStorage.getItem('idRomaneio') ?? '';
  localStorage.removeItem('idRomaneio');

  axios.get(url, { responseType: 'blob' })
    .then(response => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `Documento${idRomaneio || 'documento'}_${tipoPDF}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
    .catch(error => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      // setLoading(false);
    });
}


export function visualizarPDF(
  setModalShowPDFViewer, 
  idsOuRomaneio,  
  setLoadingPDF, 
  setPdfUrl, 
  idG2, 
  setTipoPDF, 
  tipoPDF, 
  isIdsEntrega  
) {

  if (idG2 === idsOuRomaneio[0]) {
    isIdsEntrega = false;
  }

  let endpoint = tipoPDF === 'romaneio' ? 'gerarPdfRomaneio' : 'gerarPdfRomaneioAmbiente';
  setModalShowPDFViewer(true);
  setTipoPDF(tipoPDF);
  setLoadingPDF(true);

  const url = isIdsEntrega
    ? `${API_PAINEL_EXP}/${endpoint}?idsEntrega=${idsOuRomaneio.join(',')}`
    : `${API_PAINEL_EXP}/${endpoint}?idRomaneio=${idsOuRomaneio[0]}`;

    localStorage.setItem('url', url);
    localStorage.setItem('idRomaneio', idsOuRomaneio.join(','));
    
  axios.get(url, { responseType: 'blob' })
    .then(response => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }
      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch(error => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoadingPDF(false);
    });
}


export function enviarVuupt(
  idsOuRomaneio, 
  idG2,
  isIdsEntrega,
) {
  if (idG2 === idsOuRomaneio[0]) {
    isIdsEntrega = false;
  }
  
  const url = isIdsEntrega
    ? `${API_PAINEL_EXP}/integracao-vuupt?idsEntrega=${idsOuRomaneio.join(',')}`
    : `${API_PAINEL_EXP}/integracao-vuupt?idSeparacao=${idsOuRomaneio[0]}`;

  localStorage.setItem('url', url);
  localStorage.setItem('idRomaneio', idsOuRomaneio.join(','));

  Swal.fire({
    title: 'Carregando...',
    html: 'Por favor, aguarde enquanto sincronizamos sua solicitação.',
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading(); 
    },
  });

  axios.post(url)
    .then(response => {
      Swal.close();

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Sincronizado com o vuupt com sucesso!',
          timer: 2000, 
          timerProgressBar: true, 
          showConfirmButton: false, 
        });
      } else {
        throw new Error('Erro ao Enviar para Vuupt');
      }
    })
    .catch(error => {
      console.error('Erro ao enviar para o Vuupt', error);
   
      Swal.close();

    
      Swal.fire({
        icon: 'error',
        title: 'Erro ao enviar para o Vuupt',
        text: 'Ocorreu um erro ao tentar enviar para o Vuupt. Por favor, tente novamente mais tarde.',
        timer: 2000, 
        timerProgressBar: true, 
        showConfirmButton: false, 
      });
    });
}
