import React, { useEffect, useState } from "react";
import axios from "axios";
interface OptionType {
    value: number;
    label: string;
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT


const useOptionsVendedores = () => {
    const [optionsVendedores, setOptions] = useState<OptionType[]>([]);

    useEffect(()=>{
        async function fetchVendedorOptions() {
            try {
                const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/vendedores/optionsVendedores`)
                const data = await response.data;
                const formattedOptions = data.map(item =>({
                    value: item.codigog2,
                    label: item.nome
                  }));
                setOptions(formattedOptions);
            } catch (error) {
                console.error("Erro ao buscar opções:", error);
            }
        }
        fetchVendedorOptions();
    }, []);

    return {optionsVendedores};
};

export default useOptionsVendedores;