export const formatarData = (dataString: string) => {
  if (!dataString || dataString === 'null') {
    return ' - ';
  }

  const data = new Date(dataString);
  return data.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};
