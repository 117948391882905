import React, { useEffect, useState } from 'react'
import axios from 'axios'

interface OptionType {
  value: number
  label: string
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

const useOptionsBanco = () => {
  const [optionsBanco, setOptionsBanco] = useState<OptionType[]>([])

  useEffect(() => {
    async function fetchSegmentoOptions() {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/financeiro/bancos`
        )
        const data = await response.data
        const formattedOptions = data.map((item) => ({
          value: item.id,
          label: `${item.codbanco} - ${item.titulo}`,
        }))
        setOptionsBanco(formattedOptions)
      } catch (error) {
      }
    }
    fetchSegmentoOptions()
  }, [])

  return { optionsBanco }
}

export default useOptionsBanco
