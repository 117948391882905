import { toZonedTime } from 'date-fns-tz';
import format from 'date-fns/format';
import { Badge } from 'react-bootstrap';

export const formatStatus = (status) => {
  switch (status) {
    case 9:
      return <Badge bg='warning'>SINCRONIZADO</Badge>;
    case 4:
      return <Badge bg='success'>APROVADO</Badge>;
    case 3:
      return <Badge bg='danger' style={{ color: 'white' }}>EM APROVAÇÃO</Badge>;
    case 2:
      return <Badge bg='secondary'>NEGOCIACAO ENCERRADA</Badge>;
    case 1:
      return <Badge bg='success'>EM ABERTO</Badge>;
    case 0:
      return <Badge bg='danger' style={{ color: 'white' }}>CANCELADO</Badge>;
    default:
      return <Badge bg='light'>N/A</Badge>;
  }
};

export const formatStatusWorkflow = (status) => {
  switch (status) {
    case 0:
      return (
        <Badge bg='danger' text='light' style={{ fontSize: '12px', letterSpacing: '0.5px' }}>
          RECUSADO
        </Badge>
      );
    case 1:
      return (
        <Badge bg='success' text='light' style={{ fontSize: '12px', letterSpacing: '0.5px' }}>
          APROVADO
        </Badge>
      );
    case 2:
      return (
        <Badge bg='warning' text='light' style={{ fontSize: '12px', letterSpacing: '0.5px' }}>
          Aguardando Aprovação
        </Badge>
      );
    default:
      return <Badge bg='light'>N/A</Badge>;
  }
};

export const formatStatusPagamento = (status) => {
  switch (status) {
    case 1:
      return <Badge bg='success'>Pago</Badge>;
    default:
      return <Badge bg='light'></Badge>;
  }
};

export const formatClone = (versao, idPrevendaOriginal) => {
  if (versao !== null) {
    return (
      <Badge bg='info' style={{ color: 'white' }}>
        Cópia: {idPrevendaOriginal} / {versao}
      </Badge>
    );
  }
};

export const formataCliente = (status) => {
  if (status != null) {
    if (status === 9) {
      return (
        <Badge bg='info' style={{ color: 'white' }}>
          Sincronizado
        </Badge>
      );
    } else {
      return (
        <Badge bg='secondary' style={{ color: 'white' }}>
          Não Sincronizado
        </Badge>
      );
    }
  }
};

export const formataCnpjCpf = (cpf, cnpj) => {
  if (cpf === null && cnpj === null) {
    return (
      <Badge bg='danger' style={{ color: 'white' }}>
        Cadastro Incompleto
      </Badge>
    );
  } else {
    return <Badge bg='secondary' style={{ color: 'white' }}></Badge>;
  }
};

export const formatarTotal = (total: number) => {
  if (total != null) {
    return 'R$ ' + total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  } else {
    return '';
  }
};

export const formatarData = (data: string) => {
  if (data && data.includes('T')) {
    const dataFormatada = data.split('T')[0].split('-').reverse().join('/');
    return dataFormatada;
  }
  return 'Data não definida';
};

export const formatarHorario = (data: string) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = new Date(data);
  const zonedDate = toZonedTime(date, timeZone);
  return format(zonedDate, 'HH:mm:ss');
};

export const formatTipoParceiro = (tipoParceiro) => {
  switch (tipoParceiro) {
    case 1:
      return <Badge bg='warning'>Pessoa Física</Badge>;
    case 0:
      return <Badge bg='primary'>Pessoa Jurídica</Badge>;
    default:
      return <Badge bg='light'>N/A</Badge>;
  }
};