import { useEffect, useState } from 'react';
import axios from 'axios';
import { useProduto } from '../hooks/useProduto';
import useOptionsOrigemProduto from '../../../selectOptions/useOptionsOrigemProduto';
import useOptionsEmbalagem from '../../../selectOptions/useOptionsEmbalagem';
import useOptionsTipoEmbalagem from '../../../selectOptions/useOptionsTipoEmbalagem';
import { useOptionsTipoProduto } from '../../../selectOptions/useOptionsTipoProduto';



const DadosGerais = () => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const {
    formik,
    setSelectedOrigem,
    setSelectedTipo_Embalagem,
    setSelectedTipo,
    setSelectedEmbalagem,
    handleSelectChange,
    handleChange,
    handleChangeNumber,
    selectedEmbalagem,
    selectedOrigem,
    selectedTipo,
    selectedTipo_Embalagem,
  } = useProduto();

  const { optionsOrigemProduto } = useOptionsOrigemProduto();
  const { optionsTipoEmbalagem } = useOptionsTipoEmbalagem();
  const { optionsTipoProduto } = useOptionsTipoProduto();
  const { optionsEmbalagem } = useOptionsEmbalagem();

  const handleSelectChangeOrigem = (event) => {
    const origemId = event.target.value;
    const origemDesc = event.target.options[event.target.selectedIndex].text;
    if (origemDesc === 'Selecione') {
      setSelectedOrigem({ id: '', desc: '' });
    } else {
      setSelectedOrigem({ id: origemId, desc: origemDesc });
    }
  };

  const handleSelectChangeEmbalagem = async (event) => {
    const embalagemId = event.target.value;
    const embalagemDesc = event.target.options[event.target.selectedIndex].text;

    if (embalagemId > 0) {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/embalagens/${embalagemId}`
        );
        const embalagem = response.data;
        formik.setFieldValue('largura', embalagem.largura);
        formik.setFieldValue('altura', embalagem.altura);
        formik.setFieldValue('comprimento', embalagem.comprimento);
      } catch (error) {
        console.error('Erro ao buscar opções:', error);
        return [];
      }
    } else {
      formik.setFieldValue('largura', 0);
      formik.setFieldValue('altura', 0);
      formik.setFieldValue('comprimento', 0);
    }

    if (embalagemDesc === 'Selecione') {
      setSelectedEmbalagem({ id: '', desc: '' });
    } else {
      setSelectedEmbalagem({ id: embalagemId, desc: embalagemDesc });
    }
  };

  const handleEstoque = (event) => {
    const novoValor = event.target.value;
    formik.setFieldValue('controlar_estoque', Number(novoValor));

    if (novoValor === 2) {
      formik.setFieldValue('estoque_inicial', null);
      formik.setFieldValue('estoque_min', null);
      formik.setFieldValue('estoque_max', null);
      formik.setFieldValue('sob_encomenda', null);
      formik.setFieldValue('controlar_lotes', null);
    }
  };

  useEffect(() => {
    if (formik.values.controlar_estoque === 2) {
      formik.setFieldValue('estoque_inicial', null);
      formik.setFieldValue('estoque_min', null);
      formik.setFieldValue('estoque_max', null);
      formik.setFieldValue('sob_encomenda', null);
      formik.setFieldValue('controlar_lotes', null);
    }
  }, [
    formik.values.controlar_estoque,
    formik.values.controlar_lotes,
    formik.values.estoque_inicial,
    formik.values.estoque_min,
    formik.values.estoque_max,
    formik.values.sob_encomenda,
  ]);

  return (
    <div className='mb-5 mt-8 '>
      <div className='mb-5 row'>
        <div className='col-md-6 mt-4'>
          <label className='form-label'>Descrição</label>
          <input
            type='text'
            placeholder='Descrição completa do produto'
            {...formik.getFieldProps('descricao')}
            className='form-control bg-transparent'
            onChange={handleChange}
          />
          {formik.touched.descricao && formik.errors.descricao && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.descricao}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-1'> </div>
        <div className='col-md-4  mt-4'>
          <label className='form-label'>Código (SKU)</label>
          <input
            type='text'
            placeholder='Código (SKU) ou referência (opcional)'
            {...formik.getFieldProps('codigo_sku')}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />
          {formik.touched.codigo_sku && formik.errors.codigo_sku && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.codigo_sku}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='mb-5 row'>
        <div className='col-md-6 mt-4'>
          <label className='form-label'>Origem</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent'
            onChange={handleSelectChangeOrigem}
            value={selectedOrigem?.id}
            style={{ marginTop: '0px' }}
          >
            <option value='' disabled selected>
              Selecione...
            </option>

            {optionsOrigemProduto.map((option) => (
              <option key={option.value} value={option.value}>
                {option.desc}
              </option>
            ))}
          </select>
          <small className='form-text text-muted'>Origem do produto conforme ICMS</small>
          {formik.touched.origem_id && formik.errors.origem_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.origem_id}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-1'> </div>
        <div className='col-md-4  mt-4'>
          <label className='form-label'>Tipo</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent'
            {...formik.getFieldProps('tipo_id')}
            value={selectedTipo?.id}
            onChange={(e) => handleSelectChange(e, setSelectedTipo)}
            style={{ marginTop: '0px' }}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            {optionsTipoProduto.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {formik.touched.tipo_id && formik.errors.tipo_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.tipo_id}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='mb-5 row'>
        <div className='col-md-3 mt-4'>
          <label className='form-label'>NCM</label>
          <input
            type='text'
            placeholder='(Exemplo: 1001.10.10)'
            {...formik.getFieldProps('ncm')}
            className='form-control bg-transparent'
            onChange={handleChangeNumber}
          />
          <small id='' className='form-text text-muted'>
            Nomenclatura comum do Mercosul
          </small>
          {formik.touched.ncm && formik.errors.ncm && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.ncm}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-3  mt-4'>
          <label className='form-label'>GTIN/EAN</label>
          <input
            type='number'
            placeholder='Código de barras'
            {...formik.getFieldProps('gtin_ean')}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />
          <small id='' className='form-text text-muted'>
            Global Trade Item Number
          </small>
          {formik.touched.gtin_ean && formik.errors.gtin_ean && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.gtin_ean}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-1'></div>
        <div className='col-md-4 mt-4'>
          <label className='form-label'>Código CEST</label>
          <input
            type='text'
            placeholder='(Exemplo: 1001.10.10)'
            {...formik.getFieldProps('cest')}
            className='form-control bg-transparent'
            onChange={handleChangeNumber}
          />
          <small id='' className='form-text ' style={{ color: ' #0050DC' }}>
            Código Especificador da Substituição Tributária
          </small>
          {formik.touched.cest && formik.errors.cest && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.cest}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='mb-8 row'>
        <div className='col-md-2 mt-4'>
          <label className='form-label'>Preço de venda</label>
          <input
            type='number'
            placeholder='0,00'
            {...formik.getFieldProps('preco_venda')}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />

          {formik.touched.preco_venda && formik.errors.preco_venda && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.preco_venda}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-2  mt-4'>
          <label className='form-label'>Preço promocional</label>
          <input
            type='number'
            placeholder='0,00'
            {...formik.getFieldProps('preco_promocional')}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />

          {formik.touched.preco_promocional && formik.errors.preco_promocional && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.preco_promocional}</span>
              </div>
            </div>
          )}
        </div>

        <div className='col-md-2 mt-4'>
          <label className='form-label'>Unidade</label>
          <input
            type='text'
            placeholder='Exemplo: MT, UND, 10MT'
            {...formik.getFieldProps('unidade')}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />

          {formik.touched.unidade && formik.errors.unidade && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.unidade}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-1'></div>
        <div className='col-md-4 mt-4'>
          <label className='form-label'>Status</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent'
            onChange={handleChange}
            {...formik.getFieldProps('status')}
            value={formik.values.status}
            style={{ marginTop: '0px' }}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            <option value='1'> Ativo </option>
            <option value='2'> Inativo </option>
          </select>

          {formik.touched.status && formik.errors.status && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.status}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-1'></div>
      </div>
      <div className='mb-form-label-container' style={{ display: 'flex', alignItems: 'center' }}>
        <h5 style={{ color: '#3f3f3f' }}>Dimensões e Peso</h5>
        <div style={{ flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px' }}></div>
      </div>
      <div className='mb-3 row'>
        <div className='col-md-3 mt-4'>
          <label className='form-label'>Peso Líquido</label>
          <input
            type='number'
            placeholder='Em Kg'
            {...formik.getFieldProps('peso_liq')}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />

          {formik.touched.peso_liq && formik.errors.peso_liq && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.peso_liq}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-3  mt-4'>
          <label className='form-label'>Peso Bruto</label>
          <input
            type='number'
            placeholder='Em Kg'
            {...formik.getFieldProps('peso_bruto')}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />

          {formik.touched.peso_bruto && formik.errors.peso_bruto && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.peso_bruto}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-3 mt-4'>
          <label className='form-label'>Nº de volumes</label>
          <input
            type='number'
            placeholder='(Exemplo: 01003.00)'
            {...formik.getFieldProps('num_volumes')}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />

          {formik.touched.num_volumes && formik.errors.num_volumes && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.num_volumes}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-1'></div>
      </div>
      <div className='mb-3 row'>
        <div className='col-md-3  mt-4'>
          <label className='form-label'>Tipo da Embalagem</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent'
            value={selectedTipo_Embalagem?.id}
            onChange={(e) => handleSelectChange(e, setSelectedTipo_Embalagem)}
            style={{ marginTop: '0px' }}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            {optionsTipoEmbalagem.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {formik.touched.tipo_embalagem_id && formik.errors.tipo_embalagem_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.tipo_embalagem}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 mt-4'>
          <label className='form-label'>Embalagem</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent'
            {...formik.getFieldProps('embalagem_id')}
            value={selectedEmbalagem?.id}
            onChange={handleSelectChangeEmbalagem}
            style={{ marginTop: '0px' }}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            <option value='0'>Embalagem Customizada</option>
            {optionsEmbalagem.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {formik.touched.embalagem_id && formik.errors.embalagem_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.embalagem_id}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-1'></div>
      </div>
      <div className='mb-8 row'>
        <div className='col-md-3 mt-4'>
          <label className='form-label'>Largura</label>
          <input
            disabled={selectedEmbalagem!.id === '0' ? false : true}
            type='number'
            placeholder='Em cm'
            {...formik.getFieldProps('largura')}
            value={formik.values.largura}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />

          {formik.touched.largura && formik.errors.largura && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.largura}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-3  mt-4'>
          <label className='form-label'>Altura</label>
          <input
            disabled={selectedEmbalagem!.id === '0' ? false : true}
            type='number'
            placeholder='Em cm'
            {...formik.getFieldProps('altura')}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />

          {formik.touched.altura && formik.errors.altura && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.altura}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-3 mt-4'>
          <label className='form-label'>Comprimento</label>
          <input
            disabled={selectedEmbalagem!.id === '0' ? false : true}
            type='number'
            placeholder='Em cm'
            {...formik.getFieldProps('comprimento')}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />

          {formik.touched.comprimento && formik.errors.comprimento && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.comprimento}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-1'></div>
      </div>
      <div className='mb-form-label-container' style={{ display: 'flex', alignItems: 'center' }}>
        <h5 style={{ color: '#3f3f3f' }}>Estoque</h5>
        <div style={{ flexGrow: 1, borderBottom: '2px solid #878787', marginLeft: '10px' }}></div>
      </div>
      <div className='mb-3 row'>
        <div
          className='col-md-2  mt-4'
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          <label className='form-label'>Controlar Estoque</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent'
            {...formik.getFieldProps('controlar_estoque')}
            //value={}
            onChange={handleEstoque}
            style={{ marginTop: '0px' }}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            <option value={1}> SIM </option>
            <option value={2}> NÃO </option>
          </select>
          <small id='' className='form-text text-muted'>
            Acompanhamento da movimentação de estoque
          </small>

          {formik.touched.controlar_estoque && formik.errors.controlar_estoque && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.controlar_estoque}</span>
              </div>
            </div>
          )}
        </div>

        {formik.values.controlar_estoque !== 2 && (
          <>
            <div
              className='col-md-2 mt-4'
              style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
            >
              <label className='form-label'>Estoque Inicial (Geral)</label>
              <input
                type='number'
                placeholder='Unidade'
                {...formik.getFieldProps('estoque_inicial')}
                className='form-control bg-transparent'
              />
              <small id='' className='form-text text-muted'>
                Informar se deseja lançar o estoque inicial do produto
              </small>

              {formik.touched.estoque_inicial && formik.errors.estoque_inicial && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.estoque_inicial}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-2 mt-4'>
              <label className='form-label'>Estoque mínimo</label>
              <input
                type='number'
                placeholder='Unidade'
                {...formik.getFieldProps('estoque_min')}
                className='form-control bg-transparent'
              />
              <small id='' className='form-text text-muted'>
                Quantidade mínima do produto no estoque
              </small>

              {formik.touched.estoque_min && formik.errors.estoque_min && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.estoque_min}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-2 mt-4'>
              <label className='form-label'>Estoque máximo</label>
              <input
                type='number'
                placeholder='Unidade'
                {...formik.getFieldProps('estoque_max')}
                className='form-control bg-transparent'
              />
              <small id='' className='form-text text-muted'>
                Quantidade máxima do produto no estoque
              </small>

              {formik.touched.estoque_max && formik.errors.estoque_max && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.estoque_max}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-2  mt-4'>
              <label className='form-label'>Sob encomenda</label>
              <select
                defaultValue={''}
                required
                className='form-select bg-transparent'
                {...formik.getFieldProps('sob_encomenda')}
                value={formik.values.sob_encomenda}
                //onChange={handleTipoCadastroChange}
                style={{ marginTop: '0px' }}
              >
                <option value='' disabled selected>
                  Selecione...
                </option>
                <option value='1'> SIM </option>
                <option value='2'> NÃO </option>
              </select>

              {formik.touched.sob_encomenda && formik.errors.sob_encomenda && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.sob_encomenda}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-2  mt-4'>
              <label className='form-label'>Controlar lotes</label>
              <select
                defaultValue={''}
                required
                className='form-select bg-transparent'
                {...formik.getFieldProps('controlar_lotes')}
                value={formik.values.controlar_lotes}
                //onChange={handleTipoCadastroChange}
                style={{ marginTop: '0px' }}
              >
                <option value='' disabled selected>
                  Selecione...
                </option>
                <option value='1'> SIM </option>
                <option value='2'> NÃO </option>
              </select>

              {formik.touched.controlar_lotes && formik.errors.controlar_lotes && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.controlar_lotes}</span>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className='mb-8 row'>
        <div className='col-md-4 mt-4'>
          <label className='form-label'>Localização</label>
          <input
            type='text'
            placeholder='(Ex: corredor A)'
            {...formik.getFieldProps('localizacao')}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />
          <small id='' className='form-text text-muted'>
            Localização física no estoque
          </small>

          {formik.touched.localizacao && formik.errors.localizacao && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.localizacao}</span>
              </div>
            </div>
          )}
        </div>
        <div
          className='col-md-2 mt-4'
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          <label className='form-label'>Dias para preparação</label>
          <input
            type='number'
            placeholder=''
            {...formik.getFieldProps('dias_preparacao')}
            className='form-control bg-transparent'
          //onChange={handleChange}
          />

          {formik.touched.dias_preparacao && formik.errors.dias_preparacao && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.dias_preparacao}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DadosGerais;
