import {ChangeEvent, useEffect, useState} from 'react';
import {useProduto} from '../hooks/useProduto';
import Divider from './Divider';
import Select from 'react-select';
import fetchMarcaProductOptions from '../../../selectOptions/useOptionsMarcaProduto';
import {OptionType} from '../Interface';
import useOptionsCategoria from '../../../selectOptions/useOptionsCategoria';
import '../style/style.css';

const DadosComplementares = () => {
  const [inputs, setInputs] = useState<string[]>(['']);
  const {
    formik,
    setSelectedMarca,
    selectedMarca,
    base64Image,
    setBase64Image,
    selectedCategoria,
    setSelectedCategoria,
    handleSelectChange,
  } = useProduto();
  const [optionsMarcaProdutos, setOptionsMarcaProdutos] = useState<OptionType[]>([]);
  const {optionsCategoria} = useOptionsCategoria();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
    }),
  };

  // const addInput = () => {
  //   setInputs([...inputs, '']);
  // };

  // const handleInputChange = (value: string, index: number) => {
  //   const newInputs = [...inputs];
  //   newInputs[index] = value;
  //   setInputs(newInputs);
  // };

  const handleSelectChangeMarca = (selectedMarca: OptionType | null) => {
    setSelectedMarca({value: selectedMarca?.value, label: selectedMarca?.label});
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
        alert('Formato de arquivo inválido. Por favor, selecione um arquivo PNG, JPG ou JPEG.');
        event.target.value = '';
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        alert('O arquivo é muito grande. Por favor, selecione um arquivo menor que 2MB.');
        event.target.value = '';
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        setBase64Image(base64data);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const loadOptions = async () => {
      const options = await fetchMarcaProductOptions();
      setOptionsMarcaProdutos(options);
    };
    loadOptions();
  }, []);

  return (
    <div className='mb-5 mt-8 '>
      <div className='mb-5 row'>
        <div className='mb-form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h4 style={{color: '#3f3f3f'}}>Categorização</h4>
        </div>
        <div className='col-md-8 mt-4'>
          <label className='form-label'>Categoria</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent'
            value={selectedCategoria?.id}
            onChange={(e) => handleSelectChange(e, setSelectedCategoria)}
            style={{marginTop: '0px'}}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            {optionsCategoria.map((option) => (
              <option key={option.id} value={option.id}>
                {option.desc}
              </option>
            ))}
          </select>
          <small id='' className='form-text text-muted'>
            Categorização conforme a árvore de categorias dos produtos
          </small>
          {formik.touched.categoria_id && formik.errors.categoria_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.categoria_id}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-1'> </div>
        <div className='col-md-4  mt-4 mb-4'>
          <label className='form-label'>Marca</label>
          <Select
            value={selectedMarca}
            options={optionsMarcaProdutos}
            onChange={handleSelectChangeMarca}
            isClearable={true}
            isSearchable={true}
            styles={customStyles}
            placeholder='Selecione uma marca...'
          />
          {formik.touched.marca_id && formik.errors.marca_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.marca_id}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <Divider />
      <div className='mb-3 row'>
        <div className='col-md-8 mt-4 mb-4'>
          <h4 style={{color: '#3f3f3f'}}>Descrição Complementar</h4>
          <textarea
            className='form-control bg-transparent'
            {...formik.getFieldProps('desc_complementar')}
            onChange={(e) => {
              const value = e.target.value;
              formik.setFieldValue('desc_complementar', value);
            }}
          />
          {/* <div className='char-counter' style={{color: 'black'}}>
            {75 - formik.values.desc_complementar.length} caracteres restantes
          </div> */}
        </div>
        {formik.touched.desc_complementar && formik.errors.desc_complementar && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.desc_complementar}</span>
            </div>
          </div>
        )}
      </div>

      <Divider />

      <div className='col-md-8 mt-4 mb-6'>
        <h4 style={{color: '#3f3f3f', marginTop: '10px', marginBottom: 16}}>Imagem</h4>
        <div
          style={{
            width: '200px',
            height: '200px',
            backgroundColor: '#dbdfe9',
            borderRadius: '6px',
            boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.5)',
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {base64Image ? (
            <img
              src={base64Image ? base64Image : ''}
              alt={formik.values.descricao}
              style={{width: '196px', height: '196px', borderRadius: '6px'}}
            />
          ) : (
            <label
              htmlFor='selecao-arquivo'
              style={{color: '#99a1b7', fontWeight: '600', fontSize: '18px'}}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 14,
                  cursor: 'pointer',
                  width: '200px',
                  height: '200px',
                }}
              >
                <i className='bi bi-lightbulb' style={{fontSize: '60px'}}></i>
                Selecionar Imagem
              </div>
            </label>
          )}
          <input
            id='selecao-arquivo'
            type='file'
            accept='.png,.jpg,.jpeg'
            className='form-personalizado choose'
            maxLength={2000000}
            onChange={handleFileChange}
          />
        </div>
        <small id='' className='form-text text-muted'>
          O tamanho do arquivo não deve ultrapassar 2 MB
        </small>
      </div>
      {base64Image && (
        <button type='button' className='btn btn-secondary' onClick={() => setBase64Image('')}>
          Remover Imagem
        </button>
      )}

      <Divider />

      <div className='col-md-8 mt-4 mb-8'>
        <h4 style={{color: '#3f3f3f', marginTop: '10px'}}>Imagens externas</h4>
        <small id='' className='form-text text-muted' style={{marginBottom: 16}}>
          As imagens externas são utilizadas apenas no envio de produtos para o e-commerce e somente
          sua URL ficará armazenada.
        </small>
        <h3 className='form-label mt-6'>Endereço da imagem URL </h3>
        {/*aqui é feito o map do numero de inputs gerados para salvar as imagens url*/}
        {inputs.map((value, index) => (
          <>
            <div key={index} style={{display: 'flex', flexDirection: 'row', gap: 4}}>
              <input
                type='text'
                placeholder='https://exemplo.url.com.br'
                {...formik.getFieldProps('img_url')}
                className='form-control bg-transparent mt-4'
              />
              {/* <button
                type='button'
                style={{
                  background: 'transparent',
                  border: 'none',
                  color: '#0050DC',
                  fontWeight: '500',
                  marginTop: '8px',
                  fontSize: '10pt',
                  textDecoration: 'underline',
                }}
              >
                salvar
              </button> */}
            </div>
          </>
        ))}
        {/* <button
          onClick={addInput}
          style={{
            background: 'transparent',
            border: 'none',
            color: '#0050DC',
            fontWeight: '500',
            marginTop: '12px',
            fontSize: '10pt',
          }}
          type='button'
        >
          <i className='bi bi-plus-circle' style={{color: '#0050DC', fontSize: '10pt'}}></i>
          adicionar url
        </button> */}
      </div>

      <Divider />
      <div className='col-md-8 mt-4 mb-8'>
        <h4 style={{color: '#3f3f3f', marginTop: '10px'}}>Campos adicionais</h4>
        <div>
          <label className='form-label mt-6'>Link do vídeo</label>
          <input
            type='text'
            placeholder=''
            {...formik.getFieldProps('link_video')}
            className='form-control bg-transparent'
            //onChange={handleChange}
          />
          {formik.touched.link_video && formik.errors.link_video && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.link_video}</span>
              </div>
            </div>
          )}
        </div>

        <div>
          <label className='form-label mt-4'>Slug</label>
          <input
            type='text'
            placeholder=''
            {...formik.getFieldProps('slug')}
            className='form-control bg-transparent'
            //onChange={handleChange}
          />
          <small id='' className='form-text text-muted' style={{marginBottom: 16}}>
            Ultilizado para identificação legível no link do produto no e-commerce
          </small>
          {formik.touched.slug && formik.errors.slug && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.slug}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          <label className='form-label mt-4'>Keywords</label>
          <input
            type='text'
            placeholder=''
            {...formik.getFieldProps('keywords')}
            className='form-control bg-transparent'
            //onChange={handleChange}
          />
          <small id='' className='form-text text-muted' style={{marginBottom: 16}}>
            Utilizado para SEO ou metadados que ajudam a descrever um produto. Informe os valores
            separados por vírgula.
          </small>
          {formik.touched.keywords && formik.errors.keywords && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.keywords}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          <label className='form-label mt-4'>Título para SEO</label>
          <input
            type='text'
            placeholder=''
            {...formik.getFieldProps('titulo_seo')}
            className='form-control bg-transparent'
            //onChange={handleChange}
          />
          <small id='' className='form-text text-muted' style={{marginBottom: 16}}>
            Título do produto que será exibido nos resultados da busca no Google
          </small>
          {formik.touched.titulo_seo && formik.errors.titulo_seo && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.titulo_seo}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DadosComplementares;
