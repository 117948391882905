import {Route, Routes, BrowserRouter, Navigate, useLocation} from 'react-router-dom';
import {MasterLayout} from '../../_sysled/layout/MasterLayout';
import {HomePage} from '../pages/home/HomePage';
import {RelatorioVendasWrapper} from '../pages/dashboard/comercial/RelatorioVendas';
import {CadastroPromocao} from '../pages/campanha-promocional/criacao-promocao/CadastroPromocao';
import {ListagemPromocao} from '../pages/campanha-promocional/pesquisa-promocao/PesquisaListagemPromocao';
import {EdicaoPromocao} from '../pages/campanha-promocional/criacao-promocao/EdicaoPromocao';
import {ConsultaEstoqueWMS} from '../pages/consultas/estoques/ConsultaEstoqueWMS';
import {CadastroClientes} from '../pages/cadastros/clientes/CadastroCliente';
import {CadastroUsuarios} from '../pages/cadastros/usuarios/CadastroUsuario';
import {CadastroEmpresas} from '../pages/cadastros/empresas/CadastroEmpresa';
import {FormCadEmpresa} from '../pages/cadastros/empresas/indexEmpresas';
import {CadastroConsultores} from '../pages/cadastros/consultores/CadastroConsultores';
import {CadastroVendedores} from '../pages/cadastros/vendedores/CadastroVendedor';
import {CadastroPerfis} from '../pages/cadastros/permissoes/CadastroPerfis';
import {EditarUsuario} from '../pages/cadastros/usuarios/EditarUsuario';
import {EditarEmpresas} from '../pages/cadastros/empresas/EditarEmpresa';
import {EditarClientes} from '../pages/cadastros/clientes/EditarClientes';
import {ListagemClientes} from '../pages/listagem/clientes/ListagemClientes';
import {ListagemUsuariosTable} from '../pages/listagem/usuarios/ListagemUsuariosTable';
import {ListagemEmpresa} from '../pages/listagem/empresas/ListagemEmpresas';
import {ListagemPerfis} from '../pages/listagem/perfis/ListagemPerfis';
import {FormComponentIndex} from '../pages/cadastros/test-form-comp/TestFormComp';
import {useAuth} from '../modules/auth';
import CadastroParceiros from '../pages/cadastros/parceiros/CadastroParceiros';
import ListagemParceiros from '../pages/listagem/parceiros/ListagemParceiros';
import {ListagemPropostaPedido} from '../pages/listagem/proposta-pedido/ListagemPropostaPedido';
import {CadastrarPrevenda} from '../pages/cadastros/proposta-pedido/CadastrarPropostaPedido';
import WorkflowAprovacao from '../pages/workflow/workflow_aprovacao/WorkflowAprovacao';
import ListagemWorkflowAprovacao from '../pages/workflow/workflow_aprovacao/ListagemWorkFlowAprovação';
import WorkflowComponentes from '../pages/workflow/workflow_componente/WorkflowComponentes';
import ListagemWorkFlowComponentes from '../pages/workflow/workflow_componente/ListagemWorkFlowComponentes';
import WorkflowSequencia from '../pages/workflow/workflow_sequencia/WorkflowSequencia';
import ListagemWorkFlowSequencia from '../pages/workflow/workflow_sequencia/ListagemWorkFlowSequencia';
import WorkflowPendencia from '../pages/workflow/workflow_pendencia/WorkflowPendencia';
import CadastrarProduto from '../pages/cadastros/produto/CadastroProduto';
import {ListagemProduto} from '../pages/listagem/produtos/ListagemProduto';
import {
  CategorizacaoProdutosCadastroPage,
  CategorizacaoProdutosListagemPage,
  LogisticaExpedicaoPage,
} from '../pages';
import {FluxoLogisticoPage, FluxoLogisticoCadastroPage} from '../pages';
import LogisticaSeparacaoCadastroPage from '../pages/cadastros/separacao-logistica/SeparacaoLogisticaPage';
import LogisticaSeparacaoListagemPage from '../pages/listagem/seperacao-logistica/SeperacaoLogisticaListagem';
import {FormularioCadastroPage, FormulariosListagemPage} from '../pages';

const PrivateRoutes = () => {
  const {currentUser} = useAuth();

  function organizaPermissoes(permissions) {
    const permissionMap = {};
    permissions.forEach((permission) => {
      permissionMap[permission.arquivo] = true;
    });
    return permissionMap;
  }

  const permissoes = currentUser?.usuariosPermissoes;
  const permissionMap = organizaPermissoes(permissoes);

  function renderRouteIfPermission(permissionKey, routeComponent) {
    return permissionMap[permissionKey] ? routeComponent : null;
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/inicio' />} />
        <Route path='inicio' element={<HomePage />} />
        {currentUser?.tipouser === 'ADMIN' ? (
          <>
            {/* Pages */}
            {/* Consultores */}
            <Route path='/consultor' element={<CadastroConsultores />} />
            <Route path='/consultor/:id' element={<CadastroConsultores />} />
            {/* Perfis */}
            <Route path='/perfis' element={<CadastroPerfis />} />
            <Route path='/perfis/:id' element={<CadastroPerfis />} />
            <Route path='/perfis-listagem' element={<ListagemPerfis />} />
            {/* Propostas Comerciais e/ou Pedido*/}
            <Route path='/propostas-comerciais' element={<CadastrarPrevenda tipo='propostas' />} />
            <Route
              path='/editar-proposta-comercial/:id'
              element={<CadastrarPrevenda tipo='propostas' />}
            />
            <Route
              path='/listagem-propostas-comerciais'
              element={<ListagemPropostaPedido tipo='propostas' />}
            />
            {/* Pedidos */}
            {/* <Route path='/pedidos' element={<CadastrarPrevenda tipo="pedidos" />} /> */}
            <Route path='/editar-pedidos/:id' element={<CadastrarPrevenda tipo='pedidos' />} />
            <Route path='/listagem-pedidos' element={<ListagemPropostaPedido tipo='pedidos' />} />
            {/* Clientes */}
            <Route path='/cadastro-clientes' element={<CadastroClientes />} />
            <Route path='/listagem-clientes' element={<ListagemClientes />} />
            <Route path='/editar-cliente/:id' element={<EditarClientes />} />
            {/* Empresas */}
            <Route path='/cadastro-empresas' element={<FormCadEmpresa />} />
            <Route path='/listagem-empresas' element={<ListagemEmpresa />} />
            <Route path='/editar-empresa/:id' element={<EditarEmpresas />} />
            {/* Usuarios */}
            <Route path='/cadastro-usuarios' element={<CadastroUsuarios />} />
            <Route path='/listagem-usuarios' element={<ListagemUsuariosTable />} />
            <Route path='/editar-usuario/:id' element={<EditarUsuario />} />
            {/* Parceiros */}
            <Route path='/cadastro-parceiros' element={<CadastroParceiros />} />
            <Route path='/editar-parceiro/:id' element={<CadastroParceiros />} />
            <Route path='/listagem-parceiros' element={<ListagemParceiros />} />
            {/* Vendedores */}
            <Route path='/cadastro-vendedores' element={<CadastroVendedores />} />
            {/* Promoções */}
            <Route path='/promocao-cadastro' element={<CadastroPromocao />} />
            <Route path='/promocao-listagem' element={<ListagemPromocao />} />
            <Route path='/promocao-edicao/:id' element={<EdicaoPromocao />} />

            {/* Produtos */}
            <Route path='/produto-cadastro' element={<CadastrarProduto />} />
            <Route path='/produto-listagem' element={<ListagemProduto />} />
            <Route path='/produto-edicao/:id' element={<CadastrarProduto />} />

            <Route path='/produto-categorizacao' element={<CategorizacaoProdutosCadastroPage />} />
            <Route
              path='/produto-categorizacao/:id'
              element={<CategorizacaoProdutosCadastroPage />}
            />
            <Route
              path='/produto-categorizacao-listagem'
              element={<CategorizacaoProdutosListagemPage />}
            />

            {/* Workflow-Aprovação */}
            <Route path='/workflow-aprovacao' element={<WorkflowAprovacao />} />
            <Route path='/workflow-aprovacao/:id' element={<WorkflowAprovacao />} />
            <Route path='/listagem-workflow-aprovacao' element={<ListagemWorkflowAprovacao />} />
            {/* Workflow-Componente */}
            <Route path='/workflow-componente' element={<WorkflowComponentes />} />
            <Route path='/workflow-componente/:id' element={<WorkflowComponentes />} />
            <Route path='/listagem-workflow-componente' element={<ListagemWorkFlowComponentes />} />
            {/* Workflow-Sequencia */}
            <Route path='/workflow-sequencia' element={<WorkflowSequencia />} />
            <Route path='/workflow-sequencia/:id' element={<WorkflowSequencia />} />
            <Route path='/listagem-workflow-sequencia' element={<ListagemWorkFlowSequencia />} />
            {/* Workflow Pendência */}
            <Route path='/workflow-pendencia-listagem' element={<WorkflowPendencia />} />

            {/* Logística Expedição */}
            <Route path='/logistica-expedicao-listagem' element={<LogisticaExpedicaoPage />} />

            {/* Fluxo Logistico Listagem */}
            <Route path='/logistica-fluxo-listagem' element={<FluxoLogisticoPage />} />
            {/* Fluxo Logistico Edição */}
            <Route path='/logistica-fluxo-edicao/:id' element={<FluxoLogisticoCadastroPage />} />
            {/* Fluxo Logistico Cadastro */}
            <Route path='/logistica-fluxo-cadastro' element={<FluxoLogisticoCadastroPage />} />

            {/* Modules */}
            <Route
              path='relatorios/dashboards/comercial/relatorio-vendas'
              element={<RelatorioVendasWrapper />}
            />
            <Route path='consultas/estoques/consulta-estoque' element={<ConsultaEstoqueWMS />} />

            {/* Formulários */}
            <Route path='/formularios-listagem' element={<FormulariosListagemPage />} />

            <Route path='/formularios-cadastro' element={<FormularioCadastroPage />} />

            <Route path='/editar-formularios/:id' element={<FormularioCadastroPage />} />

            {/* { Logistica Separação } */}
            <Route path='/logistica-separacao' element={<LogisticaSeparacaoCadastroPage />} />

            <Route path='/logistica-separacao/:id' element={<LogisticaSeparacaoCadastroPage />} />

            <Route
              path='/logistica-separacao-listagem'
              element={<LogisticaSeparacaoListagemPage />}
            />
          </>
        ) : (
          <>
            {/* Produtos */}
            <Route
              path='/produto-cadastro'
              element={renderRouteIfPermission('Produtos', <CadastrarProduto />)}
            />
            <Route
              path='/produto-listagem'
              element={renderRouteIfPermission('Produtos', <ListagemProduto />)}
            />
            <Route
              path='/produto-edicao/:id'
              element={renderRouteIfPermission('Produtos', <CadastrarProduto />)}
            />

            <Route
              path='/produto-categorizacao'
              element={renderRouteIfPermission('Produtos', <CategorizacaoProdutosCadastroPage />)}
            />
            <Route
              path='/produto-categorizacao-listagem'
              element={renderRouteIfPermission('Produtos', <CategorizacaoProdutosListagemPage />)}
            />
            {/* Logística Expedição */}
            <Route
              path='/logistica-expedicao-listagem'
              element={renderRouteIfPermission('logisticaExpedicao', <LogisticaExpedicaoPage />)}
            />

            {/* Fluxo Logistico */}
            <Route
              path='/logistica-fluxo-listagem'
              element={renderRouteIfPermission('logisticaFluxo', <FluxoLogisticoPage />)}
            />

            <Route
              path='/logistica-fluxo-edicao/:id'
              element={renderRouteIfPermission('logisticaFluxo', <FluxoLogisticoCadastroPage />)}
            />

            <Route
              path='/logistica-fluxo-cadastro'
              element={renderRouteIfPermission('logisticaFluxo', <FluxoLogisticoCadastroPage />)}
            />

            {/* { Logistica Separação } */}
            <Route
              path='/logistica-separacao'
              element={renderRouteIfPermission(
                'logisticaExpedicao',
                <LogisticaSeparacaoCadastroPage />
              )}
            />

            <Route
              path='/logistica-separacao/:id'
              element={renderRouteIfPermission(
                'logisticaExpedicao',
                <LogisticaSeparacaoCadastroPage />
              )}
            />

            <Route
              path='/logistica-separacao-listagem'
              element={renderRouteIfPermission(
                'logisticaExpedicao',
                <LogisticaSeparacaoListagemPage />
              )}
            />

            {/* Workflow Pendência */}
            <Route path='/workflow-pendencia-listagem' element={<WorkflowPendencia />} />

            {/* Propostas Comerciais*/}
            <Route
              path='/propostas-comerciais'
              element={renderRouteIfPermission('preVendas', <CadastrarPrevenda tipo='propostas' />)}
            />
            <Route
              path='/editar-proposta-comercial/:id'
              element={renderRouteIfPermission('preVendas', <CadastrarPrevenda tipo='propostas' />)}
            />
            <Route
              path='/listagem-propostas-comerciais'
              element={renderRouteIfPermission(
                'preVendas',
                <ListagemPropostaPedido tipo='propostas' />
              )}
            />
            {/* Pedidos */}
            <Route
              path='/pedidos'
              element={renderRouteIfPermission('preVendas', <CadastrarPrevenda tipo='pedidos' />)}
            />
            <Route
              path='/editar-pedidos/:id'
              element={renderRouteIfPermission('preVendas', <CadastrarPrevenda tipo='pedidos' />)}
            />
            <Route
              path='/listagem-pedidos'
              element={renderRouteIfPermission(
                'preVendas',
                <ListagemPropostaPedido tipo='pedidos' />
              )}
            />
            {/* Promoções */}
            <Route
              path='/promocao-cadastro'
              element={renderRouteIfPermission('encartePromocional', <CadastroPromocao />)}
            />
            <Route
              path='/promocao-listagem'
              element={renderRouteIfPermission('encartePromocional', <ListagemPromocao />)}
            />
            <Route
              path='/promocao-edicao/:id'
              element={renderRouteIfPermission('encartePromocional', <EdicaoPromocao />)}
            />
            {/* Empresas */}
            <Route
              path='/cadastro-empresas'
              element={renderRouteIfPermission('empresas', <FormCadEmpresa />)}
            />
            <Route
              path='/listagem-empresas'
              element={renderRouteIfPermission('empresas', <ListagemEmpresa />)}
            />
            <Route
              path='/editar-empresa/:id'
              element={renderRouteIfPermission('empresas', <EditarEmpresas />)}
            />
            {/* Clientes */}
            <Route
              path='/cadastro-clientes'
              element={renderRouteIfPermission('clientes', <CadastroClientes />)}
            />
            <Route
              path='/listagem-clientes'
              element={renderRouteIfPermission('clientes', <ListagemClientes />)}
            />
            <Route
              path='/editar-cliente/:id'
              element={renderRouteIfPermission('clientes', <EditarClientes />)}
            />
            {/* Usuarios */}
            <Route
              path='/cadastro-usuarios'
              element={renderRouteIfPermission('usuarios', <CadastroUsuarios />)}
            />
            <Route
              path='/listagem-usuarios'
              element={renderRouteIfPermission('usuarios', <ListagemUsuariosTable />)}
            />
            <Route
              path='/editar-usuario/:id'
              element={renderRouteIfPermission('usuarios', <EditarUsuario />)}
            />
            {/*Parceiros*/}
            <Route
              path='/cadastro-parceiros'
              element={renderRouteIfPermission('parceiros', <CadastroParceiros />)}
            />
            <Route
              path='/editar-parceiro/:id'
              element={renderRouteIfPermission('parceiros', <CadastroParceiros />)}
            />
            <Route
              path='/listagem-parceiros'
              element={renderRouteIfPermission('parceiros', <ListagemParceiros />)}
            />

            {/* Perfis */}
            <Route path='/perfis' element={renderRouteIfPermission('perfis', <CadastroPerfis />)} />
            <Route
              path='/perfis/:id'
              element={renderRouteIfPermission('perfis', <CadastroPerfis />)}
            />
            <Route path='/perfis-listagem' element={<ListagemPerfis />} />

            {/* Modules */}
            <Route
              path='relatorios/dashboards/comercial/relatorio-vendas'
              element={renderRouteIfPermission('dashboardGerencial', <RelatorioVendasWrapper />)}
            />
            <Route
              path='consultas/estoques/consulta-estoque'
              element={renderRouteIfPermission('estoqueConsulta', <ConsultaEstoqueWMS />)}
            />
          </>
        )}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

export {PrivateRoutes};
