import {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {useFormik} from 'formik';
import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import Swal from 'sweetalert2';
import {DescriptionComponent, ICategorizacaoProduto, ModelCategorizacao} from '../../../shared';
import {CustomRow} from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {getCategorizacaoProduto} from '../listagem_requests/listagem_requests';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CategorizacaoProdutosListagem: React.FC = () => {
  const navigate = useNavigate();

  const formularioColumns: Column<ICategorizacaoProduto>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({value}) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Título',
      accessor: 'titulo',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Modelo',
      accessor: 'modelo',
      Cell: ({row, value}) => {
        let modeloText;
        switch (value) {
          case 1:
            modeloText = 'Segmento';
            break;
          case 2:
            modeloText = 'Tipo de Produto';
            break;
          case 3:
            modeloText = 'Grupo de Categoria';
            break;
          case 4:
            modeloText = 'Categoria';
            break;
          default:
            modeloText = 'DESCONHECIDO';
        }

        return <div className='text-start'>{modeloText}</div>;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div className='text-start'>
          <button
            onClick={() => handleOnEdit(row.original.id!)}
            className='btn btn-success btn-sm bi bi-pencil'
            data-toggle='tooltip'
            data-placement='top'
            title='Editar'
          />
        </div>
      ),
    },
  ];

  const [isCategorizacaProdutoData, setIsCategorizacaoProdutoData] = useState<
    ICategorizacaoProduto[]
  >([]);
  const [isActiveSection, setIsActiveSection] = useState<string>('segmento');
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isPage, setIsPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const data = useMemo(() => isCategorizacaProdutoData, [isCategorizacaProdutoData]);
  const columns = useMemo(() => formularioColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  //Fazendo o mapeamento do enum de Categorização de modelo
  const mapStringToEnum = (value: string): ModelCategorizacao | undefined => {
    return ModelCategorizacao[value as keyof typeof ModelCategorizacao];
  };

  const initialValues = {
    titulo: '',
    status: 'ATIVO',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoadingSearch(true);
      try {
        const modelEnumValue = mapStringToEnum(isActiveSection);

        const response = await getCategorizacaoProduto(
          modelEnumValue!,
          values.titulo,
          values.status,
          isPage
        );

        const {content} = response.data;

        setIsLoadingSearch(false);
        setSubmitting(false);

        setIsCategorizacaoProdutoData(content);
      } catch (errors: any) {
        const {error} = errors;

        if (error.response) {
          const status = error.response.status;

          if (status === 401 || 403) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: error,
              confirmButtonText: 'Ok',
            });
          }
        }

        setIsLoadingSearch(false);
        setSubmitting(false);
      }
    },
  });

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  //Navigate para a listagem de categorização de produtos
  const handleOnEdit = (id: string | number) => {
    navigate(`/produto-categorizacao/${id}`);
  };

  //Get de categorias por modelo
  const getCategories = async (model: number, page: number = 0) => {
    setIsLoading(true);

    try {
      const response = await getCategorizacaoProduto(model, '', 'ATIVO', page);

      const {content, totalPages} = response.data;

      setIsHasMore(isPage < totalPages - 1);

      setIsCategorizacaoProdutoData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsLoading(false);
    } catch (errors: any) {
      const {error} = errors;

      if (error.response) {
        const status = error.response.status;

        const {data} = errors.response;

        if (status === 409) {
          Swal.fire({
            icon: 'error',
            title:
              'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
            confirmButtonText: 'Ok',
          });
        } else if (status === 401) {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            window.open('/auth', '_blank');
          });
        }

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }

      setIsLoading(false);
    }
  };

  //Load all de categorização de produtos
  useEffect(() => {
    const modelEnumValue = mapStringToEnum(isActiveSection);
    if (modelEnumValue !== undefined) {
      getCategories(modelEnumValue, isPage);
    }
  }, [isActiveSection, isPage]);

  return (
    <>
      <DescriptionComponent
        description='Listagem de Categorização de Produtos'
        redirectTo='/produto-categorizacao'
        withLink
        buttonTitle='+ Cadastro de Categorização Produtos'
      />

      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>
        <form onSubmit={formik.handleSubmit} className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
            <Col sm='6' className='col-12'>
              <label className='form-label'>Título:</label>
              <input
                type='text'
                placeholder='Digite o titulo '
                {...formik.getFieldProps('titulo')}
                className='form-control'
              />
            </Col>
            <Col sm='6' className='col-12'>
              <div>
                <label className='form-label'>Status:</label>
                <select className='form-select' {...formik.getFieldProps('status')}>
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </div>
            </Col>
          </RowBootstrap>
          <div className='row mt-6 flex-end'>
            <div className='col-sm-2'>
              <button type='submit' className='btn btn-success form-control'>
                {isLoadingSearch ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
              </button>
            </div>
          </div>
        </form>
      </div>

      <DescriptionComponent description='Selecione a Categorização' isSub />

      <div className='btn-group custom-nav-tabs mb-8' role='group' style={{width: '100%'}}>
        <button
          type='button'
          className={`btn btn-outline-secondary ${isActiveSection === 'segmento' ? 'active' : ''}`}
          onClick={() => setIsActiveSection('segmento')}
        >
          Segmento
        </button>
        <button
          type='button'
          className={`btn btn-outline-secondary ${
            isActiveSection === 'tipoProduto' ? 'active' : ''
          }`}
          onClick={() => setIsActiveSection('tipoProduto')}
        >
          Tipo de Produto
        </button>
        <button
          type='button'
          className={`btn btn-outline-secondary ${
            isActiveSection === 'grupoCategoria' ? 'active' : ''
          }`}
          onClick={() => setIsActiveSection('grupoCategoria')}
        >
          Grupo de Categoria
        </button>
        <button
          type='button'
          className={`btn btn-outline-secondary ${isActiveSection === 'categoria' ? 'active' : ''}`}
          onClick={() => setIsActiveSection('categoria')}
        >
          Categoria
        </button>
      </div>

      {!isLoading ? (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<ICategorizacaoProduto>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<ICategorizacaoProduto>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={(id) => handleOnEdit(id)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {isHasMore && (
            <div className='d-flex justify-content-center align-items-center'>
              <button className='btn btn-primary m-5' onClick={loadMore}>
                {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
              </button>
            </div>
          )}
        </div>
      ) : (
        <Skeleton count={10} height={25} />
      )}
    </>
  );
};

export default CategorizacaoProdutosListagem;
