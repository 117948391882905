import axios from 'axios';
import Swal from 'sweetalert2';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export const fetchData = async <T,>(parametro: string): Promise<T | null> => {
  try {
    const response = await axios.get(
      `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/${parametro}`
    );

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      Swal.fire({
        icon: 'info',
        title: 'Informação',
        text: 'Não existe registros de solicitações para essa pesquisa',
        timer: 3000,
        showConfirmButton: false,
        toast: true,
        position: 'center',
        timerProgressBar: true,
      });
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao carregar dados',
      text: 'Ocorreu um erro ao carregar as solicitações. Por favor, tente novamente mais tarde.',
      confirmButtonText: 'Ok',
    });
  }

  return null;
};


export const fetchDataById = async <T,>(parametro: string , id: string): Promise<T | null> => {
  try {
    const response = await axios.get(
      `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/${parametro}/${id}`
    );

    if (response.status === 200) {
     
      return response.data;

    } else if (response.status === 204) {
      Swal.fire({
        icon: 'info',
        title: 'Informação',
        text: 'Não existe registros de solicitações para essa pesquisa',
        timer: 3000,
        showConfirmButton: false,
        toast: true,
        position: 'center',
        timerProgressBar: true,
      });
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao carregar dados',
      text: 'Ocorreu um erro ao carregar as solicitações. Por favor, tente novamente mais tarde.',
      confirmButtonText: 'Ok',
    });
  }

  return null;
};

