import {tr} from 'date-fns/locale';
import React, {useState, useEffect} from 'react';

type Props = {
  className: string;
  infosProd: ProdutoInfo[];
  onRemoveProduct: (idProduto: string) => void;
  onPromoPriceChange: (idProduto: string, value: string) => void;
  promoPrices: {[key: string]: string};
  showStock: boolean;
};

interface ProdutoInfo {
  idProduto: string;
  produto: string;
  precoUnitario: string;
  estoque: Estoque[];
  precoPromocional?: string;
}

interface Estoque {
  wms: string;
  CodigoProduto: number;
  EstoqueProduto: number;
}

interface EstoqueFilto {
  wms: string;
  EstoqueProduto: number;
}

const TableProdutosSelecionados: React.FC<Props> = ({
  className,
  infosProd,
  onRemoveProduct,
  onPromoPriceChange,
  promoPrices,
  showStock,
}) => {
  const [localPromoPrices, setLocalPromoPrices] = useState<{[key: string]: string}>(promoPrices);

  useEffect(() => {
    setLocalPromoPrices(promoPrices);
  }, [promoPrices]);

  function formatarPreco(preco) {
    const precoArredondado = Number(preco).toFixed(2);
    return `R$ ${precoArredondado}`;
  }

  const handlePromoPriceChange = (idProduto: string, e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    value = value.replace(/[^\d.,]/g, '');
    value = value.replace(',', '.');

    const match = value.match(/^\d+(\.\d{0,2})?/);
    value = match ? match[0] : '';

    setLocalPromoPrices((prev) => ({...prev, [idProduto]: value}));

    onPromoPriceChange(idProduto, value);
  };

  function filtrarEstoqueDuplicados(estoque) {
    const estoqueFiltrado: EstoqueFilto[] = [];
    const wmsExistentes = new Set<String>();

    estoque.forEach((item) => {
      if (!wmsExistentes.has(item.wms)) {
        estoqueFiltrado.push(item);
        wmsExistentes.add(item.wms);
      }
    });

    return estoqueFiltrado;
  }

  function filtraEstoqueZerado(estoque) {
    return estoque.every((item) => item.EstoqueProduto.toString() === '0.0');
  }

  return (
    <>
      <div className={`card ${className}`}>
        <div className='card-header'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fs-3'>Listagem de produtos selecionados</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
              <thead>
                <tr className='fs-7 fw-bold text-gray-400 border-bottom-0'>
                  <th className='p-0 pb-3 text-center'>Id Produto</th>
                  <th className='p-0 pb-3 text-center'>Descricao</th>
                  {showStock && <th className='p-0 pb-3 text-center'>Estoque</th>}
                  <th className='p-0 pb-3 text-center'>
                    <b>Preço Promocional</b>
                  </th>
                  <th className='p-0 pb-3 text-center'>Remover</th>
                </tr>
              </thead>
              <tbody>
                {infosProd.map((infoProd, index) => {
                  const estoqueFiltrado = filtrarEstoqueDuplicados(infoProd.estoque);
                  const estoqueEstaZerado = filtraEstoqueZerado(estoqueFiltrado);

                  return (
                    <tr key={infoProd.idProduto || index}>
                      <td className='text-center'>{infoProd.idProduto}</td>
                      <td className='text-center'>{infoProd.produto}</td>
                      {showStock && (
                        <td
                          className='text-center'
                          style={{
                            backgroundColor: estoqueFiltrado.every(
                              (item) => item.EstoqueProduto.toString() === '0.0'
                            )
                              ? '#f7b7b7'
                              : 'transparent',
                          }}
                        >
                          <table className='table table-sm table-striped'>
                            <thead>
                              <tr>
                                <th scope='col'>WMS</th>
                                <th scope='col'>Estoque</th>
                              </tr>
                            </thead>
                            <tbody>
                              {estoqueFiltrado.map((itemEstoque, indexEstoque) => (
                                <tr key={indexEstoque}>
                                  <td>{itemEstoque.wms}</td>
                                  <td
                                    style={{
                                      color:
                                        itemEstoque.EstoqueProduto.toString() === '0.0'
                                          ? 'red'
                                          : '',
                                    }}
                                  >
                                    {parseInt(itemEstoque.EstoqueProduto.toString())}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      )}
                      <td className='text-center'>
                        <input
                          className='form-control'
                          value={localPromoPrices[infoProd.idProduto] || ''}
                          onChange={(e) => handlePromoPriceChange(infoProd.idProduto, e)}
                          placeholder='Preço Promocional'
                        />
                      </td>
                      <td className='text-center'>
                        <button
                          type='button'
                          className='btn btn-sm btn-danger'
                          onClick={() => onRemoveProduct(infoProd.idProduto)}
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export {TableProdutosSelecionados};
