import React, {useEffect, useState} from "react";
import axios from "axios";

interface OptionType {
  value: number;
  label: string;
}

interface OptionTypeWithIcon extends OptionType {
  icone: string;
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

const useOptionsFormaPagamento = () => {
    const [optionsFormaPagamento, setOptionsFormaPagamento] = useState<OptionTypeWithIcon[]>([]);
  
    useEffect(() => {
      async function fetchOptions() {
        try {
          const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formaspagamento/buscaFormasPagamento`);
          const data = await response.data;
          const formattedOptions = data.map(item => ({
            value: item.idFormaPagamento,
            label: item.formaPagamento,
            icone: item.icone
          }));
          setOptionsFormaPagamento(formattedOptions);
        } catch (error) {
          console.error("Erro ao buscar opções:", error);
        }
      }
      fetchOptions();
    }, []);
  
    return { optionsFormaPagamento };
    
  };
  
  export default useOptionsFormaPagamento;