import { useState } from 'react';


import { validate } from 'webpack';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useProduto } from '../hooks/useProduto';
import { Divider } from 'rsuite';

// import { Container } from './styles';

const Custos = () => {
  const { formik } = useProduto();
  const [inputs, setInputs] = useState<string[]>(['']);
  // const addInput = () => {
  //   setInputs([...inputs, '']);
  // };
  // const val = useFormik({
  //   initialValues: {
  //     custo_preco_venda: '',
  //   },
  //   validationSchema: Yup.object({
  //     custo_preco_venda: Yup.string()
  //       .max(4, 'Máximo de 4 caracteres')
  //   }),
  //   onSubmit: values => {
  //   },
  // });

  return (
    <div className='mb-5 mt-8 '>
      {inputs.map((value, index) => (
        <>
          <div className='mb-5 row' key={index}>
            <div className='col-md-3 mt-4'>
              <label className='form-label'>A partir de</label>
              <input
                type='date'
                placeholder=''
                {...formik.getFieldProps('custo_a_partir_de')}
                className='form-control bg-transparent'
              />

              {formik.touched.custo_a_partir_de && formik.errors.custo_a_partir_de && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.custo_a_partir_de}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-3 mt-4'>
              <label className='form-label'>Saldo Atual</label>
              <input
                type='number'
                placeholder='0,00'
                maxLength="6"
                {...formik.getFieldProps('custo_saldo_atual')}
                className='form-control bg-transparent'
              />

              {formik.touched.custo_saldo_atual && formik.errors.custo_saldo_atual && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.custo_saldo_atual}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-3  mt-4'>
              <label className='form-label'>Saldo Anterior</label>
              <input
                type='number'
                placeholder='0,00'
                {...formik.getFieldProps('custo_saldo_anterior')}
                className='form-control bg-transparent'
              />

              {formik.touched.custo_saldo_anterior && formik.errors.custo_saldo_anterior && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.custo_saldo_anterior}</span>
                  </div>
                </div>
              )}
            </div>
            <div
              className='col-md-3  mt-4'
              style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
            >
              <label className='form-label'>Impostos recuperáveis</label>
              <input
                type='number'
                placeholder='0,00'
                {...formik.getFieldProps('custo_impostos_rec')}
                className='form-control bg-transparent'
              />

              {formik.touched.custo_impostos_rec && formik.errors.custo_impostos_rec && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.custo_impostos_rec}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='mb-5 row'>
            <div className='col-md-3  mt-4'>
              <label className='form-label'>Preço custo</label>
              <input
                type='number'
                placeholder='0,00'
                {...formik.getFieldProps('custo_preco')}
                className='form-control bg-transparent'
              />

              {formik.touched.custo_preco && formik.errors.custo_preco && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.custo_preco}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-3  mt-4'>
              <label className='form-label'>Custo Médio</label>
              <input
                type='number'
                placeholder='0,00'
                maxLength='4'
                {...formik.getFieldProps('custo_medio')}
                className='form-control bg-transparent'
              />

              {formik.touched.custo_medio && formik.errors.custo_medio && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.custo_medio}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-3  mt-4'>
              <label className='form-label'>Preço Venda</label>
              <input
                type='number'
                placeholder='0,00'
                {...formik.getFieldProps('custo_preco_venda')}
                className='form-control bg-transparent'
              />

              {formik.touched.custo_preco_venda && formik.errors.custo_preco_venda && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.custo_preco_venda}</span>
                  </div>
                </div>
              )}
            </div>
            {/* <div className='col-md-2  mt-10'>
              <label className='form-label'></label>
              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                  color: '#0050DC',
                  fontWeight: '500',
                  marginTop: '8px',
                  fontSize: '10pt',
                  textDecoration: 'underline',
                }}
                type='button'
              >
                salvar
              </button>
            </div> */}
          </div>
          <Divider />
        </>
      ))}

      {/* <div className='col-md-4 mt-4'>
        <button
          onClick={addInput}
          style={{
            background: 'transparent',
            border: 'none',
            color: '#0050DC',
            fontWeight: '500',
            marginTop: '12px',
            fontSize: '10pt',
          }}
          type='button'
        >
          informar novo preço
        </button>
      </div> */}
    </div>
  );
};

export default Custos;
