import {useEffect, useState} from 'react';
import axios from 'axios';
interface OptionType {
  value: number;
  label: string;
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const formatOptions = (data) => {
  return data.map((item) => ({
    value: item.id,
    label: item.descricao,
  }));
};

const fetchEmbalagemOptions = async () => {
  try {
    const response = await axios.get(
      `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/embalagens`
    );
    return formatOptions(response.data);
  } catch (error) {
    console.error('Erro ao buscar opções:', error);
    return [];
  }
};

const useOptionsEmbalagem = () => {
  const [optionsEmbalagem, setOptionsEmbalagem] = useState<OptionType[]>([]);

  useEffect(() => {
    const loadOptions = async () => {
      const options = await fetchEmbalagemOptions();
      setOptionsEmbalagem(options);
    };
    loadOptions();
  }, []);

  return {optionsEmbalagem};
};

export default useOptionsEmbalagem;
