import { useEffect, useState } from 'react';
import { formatStatusPendencia } from '../components/FormStatus';
import { fetchData } from '../services/FetchWorkflow';
import { useNavigate } from 'react-router-dom';
import { formatIsFinal } from '../components/FormatIsFinal';
import axios from 'axios';
import dayjs from 'dayjs';
import ModalWorkflow from '../components/ModalWorkflow';
import { getUserByToken } from '../../../modules/auth/core/_requests';
import { AUTH_LOCAL_STORAGE_KEY } from '../../../modules/auth';


export interface WorkflowPendenciaTypes {
  id: number;
  WorkflowSequencia: number;
  idEntidade: number;
  status: number;
  idUserCreate: number;
  idWorkflowComponente: number;
  idUsuarioAprovador: number;
  wkSequencia: number;
  isFinal: number;
  workflowSequenciaStatus: number;
  wkCodigoComponente: string;
  formArquivo: string;
  nomeUserCreate: string;
  tituloAprovacao: string;
  createdAt: string;
  updatedAt: string;
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const WorkflowPendencia = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formularios, setFormularios] = useState<WorkflowPendenciaTypes[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMode, setModalMode] = useState<'Aprovar' | 'Recusar' | ''>('');
  const [modalId, setModalId] = useState<number | null>(null);
  const [status, setStatus] = useState(null);
  const [idEntidade, setidEntidade] = useState<number | null>(null);
  const [idWorkflowComponente, setidWorkflowComponente] = useState<number | null>(null);
  const [userId, setUserId] = useState<number | null>(null);

  const openModal = (
    mode: 'Aprovar' | 'Recusar',
    title: string,
    id: number,
    idEntidade: number,
    idWorkflowComponente: number,
    status?
  ) => {
    setModalMode(mode);
    setModalTitle(title);
    setModalId(id);
    setShowModal(true);
    setStatus(status);
    setidEntidade(idEntidade)
    setidWorkflowComponente(idWorkflowComponente)
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMode('');
    setModalTitle('');
    setModalId(null);
    fetchPendencias();
  };

  const fetchPendencias = async () => {
    try {
      setLoading(true);
      const data = await fetchData<WorkflowPendenciaTypes[]>('workflowpendencias/pendencias');

      // if (data != null) {
      //   data.forEach((item, index) => {
      //     setidEntidade(item.idEntidade)
      //     setidWorkflowComponente(item.idWorkflowComponente)
      //   });
      // }
      if (data) {
        setFormularios(data);
      } else {
        setFormularios([]);
      }

      const tokenString = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

      if (tokenString) {
        const tokenObject = JSON.parse(tokenString);
        const token = tokenObject.api_token;


        const response = await getUserByToken(token);

        if (response && response.data) {
          const userId = response.data.id;
          setUserId(userId);
        }
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const aprovarSolicitacao = async (modalId) => {
    if (modalId !== null) {
      try {
        await axios.put(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/workflowpendencias/aprovaPendencia?idPendencia=${modalId}`
        );

        await fetchPendencias();
        closeModal();

      } catch (error) {
        console.error('Erro ao Aprovar solicitação:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const recusarSolicitacao = async (modalId) => {
    if (modalId !== null) {
      try {
        await axios.put(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/workflowpendencias/rejeitaPendencia?idPendencia=${modalId}`
        );
        await fetchPendencias();
        closeModal();
      } catch (error) {
        console.error('Erro ao recusar solicitação:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchPendencias();
  }, []);

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <h1 className='text-dark fw-bolder'>Listagem de Workflow Pendência</h1>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <div style={{ borderBottom: '2px solid #000' }}></div>
      </div>

      <div className='table-responsive mt-10'>
        {loading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ height: '100%' }}
          >
            <div
              className='spinner-border text-success m-5'
              style={{ width: '3rem', height: '3rem' }}
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7'>
            <thead className='thead-dark'>
              <tr className='fw-bold fs-6 text-gray-800 '>
                <th className='text-center'>Id</th>
                <th className='text-center'>Titulo</th>
                <th className='text-center'>Codigo Componente</th>
                <th className='text-center'>Usuário Solicitador</th>
                <th className='text-center'>Aprovador Final? </th>
                <th className='text-center'>Status </th>
                <th className='text-center'>Data Solicitação </th>
                <th className='text-center'>Data Última Modificação </th>
                <th className='text-center'>Ações</th>
              </tr>
            </thead>

            <tbody>
              {formularios ? (
                formularios.map((formulario) => (
                  <tr key={formulario.id}>
                    <td className='text-center' style={{ alignContent: 'center' }}>
                      {formulario.id}
                    </td>
                    <td className='text-center' style={{ alignContent: 'center' }}>
                      {formulario.tituloAprovacao}
                      <p> ID: {formulario.idEntidade}</p>
                    </td>
                    <td className='text-center' style={{ alignContent: 'center' }}>
                      {formulario.wkCodigoComponente}
                    </td>
                    <td className='text-center' style={{ alignContent: 'center' }}>
                      {formulario.nomeUserCreate}
                    </td>

                    <td className='text-center' style={{ alignContent: 'center' }}>
                      {formatIsFinal(formulario.isFinal)}
                    </td>
                    <td className='text-center' style={{ alignContent: 'center' }}>
                      {formatStatusPendencia(formulario.status)}
                    </td>
                    <td className='text-center' style={{ alignContent: 'center' }}>
                      {dayjs(formulario.createdAt).format('	DD/MM/YYYY - HH:mm:ss')}
                    </td>
                    <td className='text-center' style={{ alignContent: 'center' }}>
                      {formulario.updatedAt != null
                        ? dayjs(formulario.updatedAt).format('DD/MM/YYYY - HH:mm:ss')
                        : 'Sem Alteração'}
                    </td>

                    <td className='text-center row gap-4' >
                      {/* Aprovar solicitação */}
                      <button
                        type='button'
                        className='btn btn-success'
                        onClick={() => openModal('Aprovar', 'Aprovar Solicitação', formulario.id, formulario.idEntidade, formulario.idWorkflowComponente)}
                        data-toggle="tooltip" data-placement="top" title="Aprovar"
                      >
                        <i
                          className='bi bi-check-circle'
                          style={{ color: 'white', fontSize: '18px', paddingRight: 0 }}
                        ></i>
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Aguarde...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>

                      {/* Rejeitar solicitação */}

                      <button
                        type='button'
                        className=' btn btn-danger'
                        onClick={() => openModal('Recusar', 'Recusar Solicitação', formulario.id, formulario.idEntidade, formulario.idWorkflowComponente)}
                        style={{
                          display: formulario.wkSequencia === 0 ? 'none' : '',
                        }}
                        data-toggle="tooltip" data-placement="top" title="Recusar"
                      >
                        <i
                          className='bi bi-x-circle'
                          style={{ color: 'white', fontSize: '18px', paddingRight: 0 }}
                        ></i>
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Aguarde...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <h1> NENHUMA SOLICITAÇÃO ENCONTRADA </h1>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      <ModalWorkflow
        showModal={showModal}
        onHide={closeModal}
        modalTitle={modalTitle}
        mode={modalMode}
        id={modalId}
        status={status}
        userId={userId}
        entidade={idEntidade}
        workflowComponente={idWorkflowComponente}
        onConfirm={() => {
          switch (modalMode) {
            case 'Aprovar':
              aprovarSolicitacao(modalId);
              break;
            case 'Recusar':
              recusarSolicitacao(modalId);
              break;
            default:
              break;
          }
        }}
      />
    </div>
  );
};

export default WorkflowPendencia;
