import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {Badge} from 'react-bootstrap';
import {sincronizarProduto} from '../listagem_requests/listagem_requests';
import InputMask from 'react-input-mask';
import {useProduto} from '../../cadastros/produto/hooks/useProduto';
import img_not_found from '../../../pages/../assets/icon_img_not_found (1).jpg'

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

interface Produto {
  id: number;
  img_produto_base64: string;
  codigo_sku: number;
  descricao: string;
  preco_venda: number;
  status: number;
  marca: string;
}

export function ListagemProduto() {
  const {setIdDoFormulario, navigate} = useProduto();
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFiltro, setSearchFiltro] = useState('todos');

  useEffect(() => {
    setLoading(true);
    fetchProdutos();
  }, []);

  useEffect(() => {
    setSearchTerm('');
  }, [searchFiltro]);

  const fetchProdutos = async (search = '', filtro = '') => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos`,
        {
          params: {
            search: search,
            filtro: filtro,
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        setProdutos(response.data.content);
      } else if (response.status === 204) {
        setLoading(false);
        const Toast = Swal.mixin({
          toast: true,
          position: 'center-end',
          iconColor: 'blue',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: 'info',
          title: 'Não existe registros de Produtos para essa pesquisa',
        });
        setProdutos([]);
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao carregar produtos',
        text: 'Ocorreu um erro ao carregar os produtos. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    fetchProdutos(searchTerm, searchFiltro);
  };

  const formatStatus = (statusProduto) => {
    switch (statusProduto) {
      case 1:
        return (
          <Badge bg='success' text='light'>
            ATIVO
          </Badge>
        );
      case 2:
        return (
          <Badge bg='danger' text='light'>
            INATIVO
          </Badge>
        );
      case 9:
        return (
          <Badge bg='warning' text='light'>
            SINCRONIZADO
          </Badge>
        );
      default:
        return (
          <Badge bg='light' text='light'>
            N/A
          </Badge>
        );
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (searchFiltro === 'id') {
      if (/^\d*$/.test(value)) {
        setSearchTerm(value);
      }
    } else {
      setSearchTerm(value);
    }
  };

  const handleEditProduto = (id) => {
    setIdDoFormulario(id);
    navigate(`/produto-edicao/${id}`);
  };

  const renderInput = () => {
    if (searchFiltro === 'codigo') {
      return (
        <InputMask
          type='text'
          placeholder='Código SKU'
          className='form-control'
          value={searchTerm}
          onChange={handleInputChange}
        />
      );
    } else if (searchFiltro === 'id') {
      return (
        <input
          type='text'
          placeholder='Pesquisar Produto'
          className='form-control'
          value={searchTerm}
          onChange={handleInputChange}
        />
      );
    } else {
      return (
        <input
          type='text'
          placeholder='Pesquisar Produto'
          className='form-control'
          value={searchTerm}
          onChange={handleInputChange}
        />
      );
    }
  };

  const onClickNovoCadastro = () => {
    setIdDoFormulario(null);
    navigate('/produto-cadastro');
  };

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f'}}>
          Listagem de Produtos
        </h1>
        <button
          onClick={onClickNovoCadastro}
          className='btn btn-success btn-sm '
          style={{fontSize: '14px'}}
        >
          + Novo Produto
        </button>
      </div>
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA</h4>

        <form onSubmit={handleSearch} className='form-control-solid row mb-5'>
          <div className='row mt-8 gap-4'>
            <div className='col-sm-3 '>
              <select
                className='form-select'
                value={searchFiltro}
                onChange={(e) => setSearchFiltro(e.target.value)}
              >
                <option value='todos'>Selecione</option>
                <option value='id'>Id Produto</option>
                <option value='codigo'>Código produto (SKU)</option>
                <option value='nome'>Nome</option>
                <option value='marca'>Marca</option>
              </select>
            </div>
            <div className='col-sm-3'>{renderInput()}</div>
          </div>
          <div className='row mt-6'>
            <div className='col-sm-2  mb-6'>
              <button type='submit' className='btn btn-success form-control'>
                Pesquisar
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className='table-responsive'>
        {loading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{height: '100%'}}
          >
            <div
              className='spinner-border text-success m-5'
              style={{width: '3rem', height: '3rem'}}
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7'>
            <thead className='thead-dark'>
              <tr className='fw-bold fs-6 text-gray-800'>
                <th className='text-center'>Id</th>
                <th className='text-center'>Imagem</th>
                <th className='text-center'>Nome</th>
                <th className='text-center'>Cod. Fornecedor(SKU)</th>
                <th className='text-center'>Marca</th>
                <th className='text-center'>Preço Venda</th>
                <th className='text-center'>Ação</th>
              </tr>
            </thead>
            <tbody>
              {produtos.map((produto) => (
                <tr key={produto.id}>
                  <td className='text-center'>{produto.id}</td>
                  <td className='text-center'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <img
                        src={
                          produto.img_produto_base64 ? produto.img_produto_base64 : img_not_found
                        }
                        title={produto.descricao}
                        alt=''
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '4px',
                          boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.4)',
                        }}
                      />
                    </div>
                  </td>
                  <td className='text-center'>
                    <div>{produto.descricao ? produto.descricao : 'nada'}</div>
                    <div>{formatStatus(produto.status)}</div>
                  </td>
                  <td className='text-center'>{produto.codigo_sku}</td>
                  <td className='text-center'>{produto.marca ? produto.marca : '-'}</td>
                  <td className='text-center'>R$ {produto.preco_venda}</td>
                  <td className='text-center'>
                    <button
                      onClick={() => handleEditProduto(produto.id)}
                      className='btn btn-success btn-sm bi bi-pencil'
                      data-toggle='tooltip'
                      data-placement='top'
                      title={'Editar'}
                    ></button>
                    {produto.status === 9 || produto.status === 0 ? (
                      <button className='btn btn-primary btn-sm disabled' style={{display: 'none'}}>
                        Sincronizar
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          Swal.fire({
                            title:
                              'Você tem certeza que deseja sincronizar o produto com id: ' +
                              produto.id +
                              '?',
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: 'Sim',
                            cancelButtonText: 'Não',
                            reverseButtons: true,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setLoading(true);
                              sincronizarProduto(produto.id)
                                .then((response) => {
                                  let responseinfo = response.data;
                                  setLoading(false);
                                  if (response.status === 200) {
                                    Swal.fire({
                                      icon: 'success',
                                      title: 'Produto sincronizado com sucesso!',
                                      confirmButtonText: 'Ok',
                                      reverseButtons: true,
                                    });
                                    fetchProdutos();
                                  } else if (response.status === 400) {
                                    setLoading(false);
                                    const errorMessage = responseinfo.message;
                                    Swal.fire({
                                      icon: 'info',
                                      title: 'Verifique as informações',
                                      text:
                                        errorMessage ||
                                        'Ocorreu um erro ao tentar sincronizar o produto. Por favor, tente novamente mais tarde.',
                                      confirmButtonText: 'Ok',
                                    });
                                  }
                                })
                                .catch((error) => {
                                  setLoading(false);
                                  console.error(error);
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Erro ao sincronizar produto',
                                    text:
                                      error.response?.data?.message ||
                                      'Ocorreu um erro ao tentar sincronizar o produto. Por favor, tente novamente mais tarde.',
                                    confirmButtonText: 'Ok',
                                  });
                                });
                            }
                          });
                        }}
                        className='btn btn-primary btn-sm bi bi-arrow-repeat'
                        style={{marginLeft: 10, backgroundColor: '#0d6efd', borderColor: '#0099ff'}}
                        data-toggle='tooltip'
                        data-placement='top'
                        title={'Sincronizar'}
                      ></button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
