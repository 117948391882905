import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_sysled/layout/core'
import Swal from 'sweetalert2'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {Badge} from 'react-bootstrap'
import {useAuth} from '../../../modules/auth'

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

interface Perfil {
  id: number
  titulo: string
  descricao: string
  status: number
}

export function ListagemPerfis() {
  const [perfis, setPerfis] = useState<Perfil[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [idPerfil, setIdPerfil] = useState('')
  const [statusPerfil, setStatusPerfil] = useState('')
  const [tituloPerfil, setTituloPerfil] = useState('')
  const [descricaoPerfil, setDescricaoPerfil] = useState('')
  const [searchFiltro, setSearchFiltro] = useState('todos')
  const {currentUser} = useAuth()

  const fetchPerfis = async () => {
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/perfis`,
        {
          // params: {
          //     idPrevenda: idPrevenda,
          //     idCliente: idCliente,
          //     statusOrcamento: statusOrcamento,
          // }
        }
      )

      if (response.status === 200) {
        setPerfis(response.data.content)
      } else if (response.status === 204) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          iconColor: 'blue',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer
            toast.onmouseleave = Swal.resumeTimer
          },
        })
        Toast.fire({
          icon: 'info',
          title: 'Não existe registros de Perfil para essa pesquisa',
        })
        setPerfis([])
      }
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: 'Erro ao carregar Perfis',
        text: 'Ocorreu um erro ao carregar os Perfis. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchPerfis()
  }, [])

  const handleSearch = (event) => {
    event.preventDefault()
    fetchPerfis()
  }

  const formatStatus = (status) => {
    switch (status) {
      case 1:
        return <Badge bg='success'>ATIVO</Badge>
      case 0:
        return <Badge bg='danger'>INATIVO</Badge>
      default:
        return <Badge bg='light'>N/A</Badge>
    }
  }

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='text-dark fw-bolder'>Listagem de Perfis</h1>
        <Link to='/perfis' className='btn btn-success btn-sm ' style={{fontSize: '14px'}}>
          + Novo Perfil
        </Link>
      </div>
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #000'}}></div>
      </div>

      {/* Temporariamente comentado */}
      {/* <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA: </h4>
        <form onSubmit={handleSearch} className='form-control-solid row mb-5'>
          <div className='row mt-8 gap-2'>
            <div className='col-sm-2'>
              <label htmlFor='' className='form-label'>
                ID Perfil
                <input
                  type='text'
                  className='form-control '
                  value={idPerfil}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, '')
                    setIdPerfil(value)
                  }}
                />
              </label>
            </div>
            <div className='col-sm-2'>
              <label htmlFor='' className='form-label'>
                Titulo
                <input
                  type='text'
                  className='form-control '
                  value={tituloPerfil}
                  onChange={(e) => {
                    setTituloPerfil(e.target.value)
                  }}
                />
              </label>
            </div>
            <div className='col-sm-2'>
              <label htmlFor='' className='form-label'>
                Descricao
                <input
                  type='text'
                  className='form-control '
                  value={descricaoPerfil}
                  onChange={(e) => {
                    setDescricaoPerfil(e.target.value)
                  }}
                />
              </label>
            </div>
            <div className='col-sm-3'>
              <select
                className='form-select '
                value={statusPerfil}
                onChange={(e) => setStatusPerfil(e.target.value)}
                style={{marginTop: '20px'}}
              >
                <option value=''>SELECIONE O STATUS</option>
                <option value='1'>ATIVO</option>
                <option value='0'>INATIVO</option>
                <option value='-1'>TODOS</option>
              </select>
            </div>
          </div>
          <div className='row mt-6'>
            <div className='col-sm-2  mb-6'>
              <button type='submit' className='btn btn-success form-control'>
                Pesquisar
              </button>
            </div>
          </div>
        </form>
      </div> */}

      <div className='table-responsive mt-10'>
        {loading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{height: '100%'}}
          >
            <div
              className='spinner-border text-success m-5'
              style={{width: '3rem', height: '3rem'}}
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7'>
            <thead className='thead-dark'>
              <tr className='fw-bold fs-6 text-gray-800'>
                <th className='text-center'>Id</th>
                <th className='text-center'>Titulo</th>
                <th className='text-center'>Descricao</th>
                <th className='text-center'>Status</th>
                <th className='text-center'>Editar</th>
              </tr>
            </thead>
            <tbody>
              {perfis.map((perfil) => (
                <tr key={perfil.id}>
                  <td className='text-center'>{perfil.id}</td>
                  <td className='text-center'>{perfil.titulo}</td>
                  <td className='text-center'>{perfil.descricao || ''}</td>
                  <td className='text-center'>{formatStatus(perfil.status)}</td>
                  <td className='text-center'>
                    {perfil.status === 9 || perfil.status === 0 ? (
                      <Link
                        to={`/perfis/${perfil.id}`}
                        style={{display: 'none'}}
                        className='btn btn-success btn-sm'
                      >
                        Editar
                      </Link>
                    ) : (
                      <Link to={`/perfis/${perfil.id}`} className='btn btn-success btn-sm'>
                        Editar
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
