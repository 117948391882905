import {useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const useBuscaOrcament = (orcamentistaid) => {
  useEffect(() => {
    let isMounted = true;

    async function buscaOrcament() {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscaFuncionario/${orcamentistaid}`
        );
        let data;
        const nomeOrcamentista = document.getElementById('nomeOrcamentista') as HTMLInputElement;

        if (response.status === 404 || response.status === 400) {
          data = await response.data;
          const Toast = Swal.mixin({
            toast: true,
            position: 'center-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: 'info',
            title: data,
          });
          nomeOrcamentista.value = '';
        }

        if (response.status === 200) {
          data = await response.data;

          if (data.length > 0) {
            nomeOrcamentista.value = data[0].funcionario;
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'center-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            Toast.fire({
              icon: 'info',
              title: 'Não foram encontrados dados para o orçamentista fornecido.',
            });
            nomeOrcamentista.value = '';
          }
        }
      } catch (error) {
        console.error('Erro ao buscar Orçamentista pelo código! Detalhes:', error);
      }
    }

    if (isMounted && orcamentistaid && orcamentistaid.length >= 2) {
      buscaOrcament();
    }

    return () => {
      isMounted = false;
    };
  }, [orcamentistaid]);
};

export default useBuscaOrcament;
