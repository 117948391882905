import React, {useState, useEffect} from 'react'
import {Badge} from 'react-bootstrap'
import Swal from 'sweetalert2'
import axios from 'axios'
import {Link} from 'react-router-dom'
// import "./styles/ListagemTable.css";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

interface Usuario {
  id: number
  nome: string
  sobrenome: string
  email: string
  login: string
  tipousuario: string
  status: number
}

export function ListagemUsuariosTable() {
  const [usuarios, setUsuarios] = useState<Usuario[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchFiltro, setSearchFiltro] = useState('todos')

  useEffect(() => {
    setLoading(true)
    fetchUsuarios()
  }, [])

  const fetchUsuarios = async (search = '', filtro = '') => {
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/usuarios`,
        {
          params: {
            search: search,
            filtro: filtro,
          },
        }
      )
      if (response.status === 200) {
        setUsuarios(response.data.content)
      } else if (response.status === 204) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center-end',
          iconColor: 'blue',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer
            toast.onmouseleave = Swal.resumeTimer
          },
        })
        Toast.fire({
          icon: 'info',
          title: 'Não existe registros de Usuarios para essa pesquisa',
        })
        setUsuarios([])
      }
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: 'Erro ao carregar usuários',
        text: 'Ocorreu um erro ao carregar os usuários. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = (event) => {
    event.preventDefault()
    fetchUsuarios(searchTerm, searchFiltro)
  }

  const formatStatus = (status) => {
    switch (status) {
      case 1:
        return <Badge bg='success'>ATIVO</Badge>
      default:
        return <Badge bg='danger'>INATIVO</Badge>
    }
  }

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <h1 className='text-dark fw-bolder'>Listagem de Usuários</h1>
        <Link
          to='/cadastro-usuarios'
          className='btn btn-success btn-sm '
          style={{fontSize: '14px'}}
        >
          + Novo Usuário
        </Link>
      </div>
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #000'}}></div>
      </div>
      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA:</h4>
        <form onSubmit={handleSearch} className='form-control-solid row mb-5'>
          <div className='row mt-8 gap-4'>
            <div className='col-sm-2'>
              <select
                className='form-select'
                value={searchFiltro}
                onChange={(e) => setSearchFiltro(e.target.value)}
              >
                <option value='todos'>Selecione</option>
                <option value='nome'>Nome</option>
                <option value='email'>Email</option>
                <option value='login'>Login</option>
              </select>
            </div>
            <div className='col-sm-3'>
              <input
                type='text'
                placeholder='Pesquisar usuário'
                className='form-control'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className='row mt-6'>
            <div className='col-sm-2  mb-6'>
              <button type='submit' className='btn btn-success form-control'>
                Pesquisar
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className='table-responsive'>
        {loading ? (
          <p>Carregando...</p>
        ) : (
          <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7'>
            <thead className='thead-dark'>
              <tr className='fw-bold fs-6 text-gray-800'>
                <th className='text-center' scope='col'>
                  Id
                </th>
                <th scope='col'>Nome</th>
                <th scope='col'>Login</th>
                <th scope='col'>Email</th>
                <th scope='col'>Tipo</th>
                <th scope='col'>Status</th>
                <th className='text-center' scope='col'>
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              {usuarios.map((usuario) => (
                <tr key={usuario.id}>
                  <td className='text-center'>
                    <b>{usuario.id}</b>
                  </td>
                  <td>{usuario.nome || 'N/A'}</td>
                  <td>{usuario.login || 'N/A'}</td>
                  <td>{usuario.email || 'N/A'}</td>
                  <td>{usuario.tipousuario || 'N/A'}</td>
                  <td>{formatStatus(usuario.status)}</td>
                  <td className='text-center'>
                    <Link to={`/editar-usuario/${usuario.id}`} className='btn btn-success btn-sm'>
                      Editar
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
