import React, {SetStateAction, useEffect} from 'react';
import {ReactNode, createContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {
  cadastroValidationSchema,
  formProduto,
  ListPrecoInfo,
  OptionType,
  ProdutoOption,
} from '../Interface';
import {submitFormProduto} from '../../../useUtils/useSubmitFormProduto';
import axios from 'axios';
import dayjs from 'dayjs';

type ProdutoProviderProps = {
  children: ReactNode;
};

type ProdutoContextType = {
  formik: any;
  loading: boolean;
  navigate: any;
  handleSelectChange: any;
  handleChange: any;
  handleChangeNumber: any;
  selectedEmbalagem: ProdutoOption | undefined;
  setTabelasDePrecosProduto: React.Dispatch<React.SetStateAction<ListPrecoInfo[]>>;
  setSelectedOrigem: React.Dispatch<SetStateAction<ProdutoOption>>;
  setSelectedMarca: React.Dispatch<SetStateAction<OptionType | null>>;
  setSelectedTipo: React.Dispatch<SetStateAction<ProdutoOption>>;
  setSelectedTipo_Embalagem: React.Dispatch<SetStateAction<ProdutoOption>>;
  setSelectedEmbalagem: React.Dispatch<SetStateAction<ProdutoOption>>;
  setSelectedCategoria: React.Dispatch<SetStateAction<ProdutoOption>>;
  setBase64Image: React.Dispatch<SetStateAction<string>>;
  setSelectedLinhaProduto: React.Dispatch<SetStateAction<ProdutoOption>>;
  tabelasDePrecosProduto: ListPrecoInfo[];
  selectedOrigem: ProdutoOption | undefined;
  selectedMarca: OptionType | null;
  selectedTipo: ProdutoOption | undefined;
  selectedTipo_Embalagem: ProdutoOption | undefined;
  selectedCategoria: ProdutoOption | undefined;
  selectedLinhaProduto: ProdutoOption | undefined;
  base64Image: string;
  setIdDoFormulario: any;
};
const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export const ProdutoContext = createContext({} as ProdutoContextType);

export const ProdutoContextProvider = ({children}: ProdutoProviderProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [idDoFormulario, setIdDoFormulario] = useState(undefined);
  const [base64Image, setBase64Image] = useState<string>('');
  const [tabelasDePrecosProduto, setTabelasDePrecosProduto] = useState<ListPrecoInfo[]>([]);
  const [selectedCategoria, setSelectedCategoria] = useState<ProdutoOption>({id: '', desc: ''});
  const [selectedOrigem, setSelectedOrigem] = useState<ProdutoOption>({id: '', desc: ''});
  const [selectedLinhaProduto, setSelectedLinhaProduto] = useState<ProdutoOption>({
    id: '',
    desc: '',
  });
  const [selectedTipo, setSelectedTipo] = useState<ProdutoOption>({id: '', desc: ''});
  const [selectedMarca, setSelectedMarca] = useState<OptionType | null>({
    value: undefined,
    label: undefined,
  });
  const [selectedTipo_Embalagem, setSelectedTipo_Embalagem] = useState<ProdutoOption>({
    id: '',
    desc: '',
  });
  const [selectedEmbalagem, setSelectedEmbalagem] = useState<ProdutoOption>({
    id: '',
    desc: '',
  });

  const formik = useFormik({
    initialValues: formProduto,
    validationSchema: cadastroValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      submitFormProduto(
        idDoFormulario,
        values,
        selectedOrigem,
        selectedMarca,
        selectedTipo,
        selectedTipo_Embalagem,
        selectedEmbalagem,
        selectedCategoria,
        selectedLinhaProduto,
        base64Image,
        tabelasDePrecosProduto,
        setTabelasDePrecosProduto,
        setSelectedMarca,
        setSelectedTipo_Embalagem,
        setSelectedLinhaProduto,
        setSelectedCategoria,
        setSelectedEmbalagem,
        setSelectedOrigem,
        setSelectedTipo,
        setBase64Image,
        setLoading,
        setSubmitting,
        setStatus,
        resetForm,
        navigate
      );
    },
  });

  const handleSelectChange = (event, setSelected) => {
    const id = event.target.value;
    const desc = event.target.options[event.target.selectedIndex].text;

    if (desc === 'Selecione') {
      setSelected({id: '', desc: ''});
    } else {
      setSelected({id: id, desc: desc});
    }
  };

  const handleChange = (event) => {
    const {name, value} = event.target;
    formik.setFieldValue(name, value);
  };

  const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    const newValue = value.replace(/[^0-9.]/g, '');
    formik.setFieldValue(name, newValue);
  };

  const fetchProdutoData = async (idDoFormulario: string) => {
    const response = await axios.get(
      `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/${idDoFormulario}`
    );

    return response.data;
  };

  const fetchProduto = async () => {
    try {
      const produto = await fetchProdutoData(idDoFormulario!);
      setSelectedOrigem({
        id: produto.origem_id,
        desc: produto.origem_desc,
      });

      setSelectedTipo({
        id: produto.tipo_id,
        desc: produto.tipo_desc,
      });

      setSelectedLinhaProduto({
        id: produto.linha_de_produto_id || '',
        desc: produto.linha_de_produto_desc || '',
      });

      setSelectedTipo_Embalagem({
        id: produto.tipo_embalagem_id || '',
        desc: produto.tipo_embalagem_desc || '',
      });

      setSelectedMarca({value: produto.marca_id || '', label: produto.marca_desc || ''});

      setSelectedCategoria({id: produto.categoria_id || '', desc: produto.categoria_desc || ''});
      setSelectedEmbalagem({id: produto.embalagem_id || '', desc: produto.embalagem_desc || ''});
      setBase64Image(produto.img_produto_base64 || '');

      const detalhesPrecoAjustados = produto.tabelasDePrecosProduto.map((detalhe) => ({
        idtabeladepreco: detalhe.idtabeladepreco,
        descricao: detalhe.descricao,
        precovenda: detalhe.precovenda,
        precopromocional: detalhe.precopromocional,
        status: detalhe.status,
      }));

      setTabelasDePrecosProduto(detalhesPrecoAjustados);

      formik.setValues({
        descricao: produto.descricao || '',
        codigo_sku: produto.codigo_sku || '',
        origem_id: selectedOrigem.desc,
        origem_desc: selectedOrigem.id,
        tipo_id: selectedTipo.id,
        tipo_desc: selectedTipo.desc,
        ncm: produto.ncm || '',
        gtin_ean: produto.gtin_ean || '',
        cest: produto.cest || '',
        preco_venda: produto.preco_venda || '',
        preco_promocional: produto.preco_promocional || '',
        unidade: produto.unidade || '',
        peso_liq: produto.peso_liq || '',
        peso_bruto: produto.peso_bruto || '',
        num_volumes: produto.num_volumes || '',
        tipo_embalagem_id: selectedTipo_Embalagem.id,
        tipo_embalagem_desc: selectedTipo_Embalagem.desc,
        embalagem_id: selectedEmbalagem.id,
        embalagem_desc: selectedEmbalagem.desc,
        largura: produto.largura || '',
        altura: produto.altura || '',
        comprimento: produto.comprimento || '',
        controlar_estoque: produto.controlar_estoque || null,
        estoque_inicial: produto.estoque_inicial || '',
        estoque_min: produto.estoque_min || '',
        estoque_max: produto.estoque_max || '',
        sob_encomenda: produto.sob_encomenda || '',
        controlar_lotes: produto.controlar_lotes || '',
        localizacao: produto.localizacao || '',
        dias_preparacao: produto.dias_preparacao || '',
        categoria_id: selectedCategoria.id,
        categoria_desc: selectedCategoria.desc,
        marca_id: selectedMarca?.value,
        marca_desc: selectedMarca?.label,
        desc_complementar: produto.desc_complementar || '',
        img_produto_base64: base64Image,
        img_url: produto.img_url || '',
        link_video: produto.link_video || '',
        slug: produto.slug || '',
        keywords: produto.keywords || '',
        titulo_seo: produto.titulo_seo || '',
        custo_a_partir_de: produto.custo_a_partir_de !== null ? dayjs(produto.custo_a_partir_de).format('YYYY-MM-DD') : '',
        custo_saldo_atual: produto.custo_saldo_atual || '',
        custo_saldo_anterior: produto.custo_saldo_anterior || '',
        custo_impostos_rec: produto.custo_impostos_rec || '',
        custo_preco: produto.custo_preco || '',
        custo_medio: produto.custo_medio || '',
        custo_preco_venda: produto.custo_preco_venda || '',
        fabricante: produto.fabricante || '',
        codigo_fabricante: produto.codigo_fabricante || '',
        uni_por_caixas: produto.uni_por_caixas || '',
        linha_de_produto_id: selectedLinhaProduto.id,
        linha_de_produto_desc: selectedLinhaProduto.desc,
        garantia: produto.garantia || '',
        gtin_ean_tributavel: produto.gtin_ean_tributavel || '',
        unidade_tributavel: produto.unidade_tributavel || '',
        fator_de_conversao: produto.fator_de_conversao || '',
        codigo_enquadramento_ipi: produto.codigo_enquadramento_ipi || '',
        valor_ipi_fixo: produto.valor_ipi_fixo || '',
        obs_geral_produto: produto.obs_geral_produto || '',
        status: produto.status || '',
        
      });

    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  useEffect(() => {
    if (!idDoFormulario) {
      setTabelasDePrecosProduto([]);
      setSelectedMarca({value: undefined, label: undefined});
      setSelectedTipo_Embalagem({id: '', desc: ''});
      setSelectedLinhaProduto({id: '', desc: ''});
      setSelectedCategoria({id: '', desc: ''});
      setSelectedEmbalagem({id: '', desc: ''});
      setSelectedOrigem({id: '', desc: ''});
      setSelectedTipo({id: '', desc: ''});
      setBase64Image('');

      formik.setFieldValue('descricao', '');
      formik.setFieldValue('codigo_sku', '');
      formik.setFieldValue('ncm', '');
      formik.setFieldValue('gtin_ean', '');
      formik.setFieldValue('cest', '');
      formik.setFieldValue('preco_venda', '');
      formik.setFieldValue('preco_promocional', '');
      formik.setFieldValue('unidade', '');
      formik.setFieldValue('peso_liq', '');
      formik.setFieldValue('peso_bruto', '');
      formik.setFieldValue('num_volumes', '');
      formik.setFieldValue('largura', '');
      formik.setFieldValue('altura', '');
      formik.setFieldValue('comprimento', '');
      formik.setFieldValue('controlar_estoque', '');
      formik.setFieldValue('estoque_inicial', '');
      formik.setFieldValue('estoque_min', '');
      formik.setFieldValue('estoque_max', '');
      formik.setFieldValue('sob_encomenda', '');
      formik.setFieldValue('controlar_lotes', '');
      formik.setFieldValue('localizacao', '');
      formik.setFieldValue('dias_preparacao', '');
      formik.setFieldValue('desc_complementar', '');
      formik.setFieldValue('img_produto_base64', '');
      formik.setFieldValue('img_url', '');
      formik.setFieldValue('link_video', '');
      formik.setFieldValue('slug', '');
      formik.setFieldValue('keywords', '');
      formik.setFieldValue('titulo_seo', '');
      formik.setFieldValue('custo_a_partir_de', '');
      formik.setFieldValue('custo_saldo_atual', '');
      formik.setFieldValue('custo_saldo_anterior', '');
      formik.setFieldValue('custo_impostos_rec', '');
      formik.setFieldValue('custo_preco', '');
      formik.setFieldValue('custo_medio', '');
      formik.setFieldValue('custo_preco_venda', '');
      formik.setFieldValue('fabricante', '');
      formik.setFieldValue('codigo_fabricante', '');
      formik.setFieldValue('uni_por_caixas', '');
      formik.setFieldValue('garantia', '');
      formik.setFieldValue('gtin_ean_tributavel', '');
      formik.setFieldValue('unidade_tributavel', '');
      formik.setFieldValue('fator_de_conversao', '');
      formik.setFieldValue('codigo_enquadramento_ipi', '');
      formik.setFieldValue('valor_ipi_fixo', '');
      formik.setFieldValue('obs_geral_produto', '');
      formik.setFieldValue('status', '');
    }
  }, [idDoFormulario]);

  useEffect(() => {
    if (idDoFormulario) fetchProduto();
  }, [idDoFormulario]);

  const ProviderValues = {
    formik,
    loading,
    navigate,
    handleSelectChange,
    handleChange,
    handleChangeNumber,
    setBase64Image,
    setTabelasDePrecosProduto,
    setSelectedOrigem,
    setSelectedMarca,
    setSelectedTipo,
    setSelectedTipo_Embalagem,
    setSelectedEmbalagem,
    setSelectedCategoria,
    setIdDoFormulario,
    setSelectedLinhaProduto,
    tabelasDePrecosProduto,
    selectedOrigem,
    selectedMarca,
    selectedTipo,
    selectedTipo_Embalagem,
    selectedEmbalagem,
    selectedCategoria,
    selectedLinhaProduto,
    base64Image,
  };

  return <ProdutoContext.Provider value={ProviderValues}>{children}</ProdutoContext.Provider>;
};
