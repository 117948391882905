import {Chart as ChartJS} from 'chart.js/auto';
import { useState } from 'react';
import {Doughnut} from 'react-chartjs-2';
import './EngageWidget1.css';

type Props = {
  className: string
  description: string
  color: string
  faturamento: string
  ftrMesAnterior: string
  ftrAnoAnterior: string
  lojas: ResultLojas[]
}

type ResultLojas = {
  idLoja: string,
  loja: string,
  totalCaixa: string,
  qtdPedidos: string,
  ticketMedio: string
}

const EngageWidget1 = ({ className, description, color, faturamento, ftrMesAnterior, ftrAnoAnterior, lojas }: Props) => {

  const lojasArray = Array.isArray(lojas) ? lojas : [];

  const defaultColors = [

    '#00c421', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#1307f7', '#FF6384', '#8f8a85', '#f7073a'

  ];

  const getColorByIndex = (index) => {
    return defaultColors[index % defaultColors.length];
  };

  const backgroundColors = lojasArray.map((_, index) => getColorByIndex(index));

return (
  <div 
     className={`card card-flush ${className}`}
     style={{
       backgroundColor: color,
     }}>
     <div className="card-header pt-10">
       <div className="card-title d-flex flex-column">
         <div className="d-flex align-items-center">
           <span id="totalGeralCaixa" className="fs-4hx fw-bold text-dark me-2 lh-1 ls-n2">
           {faturamento}
           </span>
         </div>
         <div className="text-gray-600 pt-1 fw-semibold fs-1">{description}</div>
       </div>
     </div>
     <div className="card-body pt-5 pb-2 d-flex flex-wrap align-items-center">
       <div className="d-flex flex-center me-5 pt-2">
         <div id="kt_card_widget_17_chart" min-width="180px" min-height="180px" data-kt-size="180" data-kt-line="35"></div>
       </div>
        <div id="fat-lojas" className="d-flex flex-column content-justify-center flex-row-fluid">
          <Doughnut
            options={{ maintainAspectRatio: false }} 
            data={{
              labels: [],
              datasets: [{
                label: "Faturamento do período",
                data: lojasArray.map(loja => loja.totalCaixa),
                backgroundColor: backgroundColors, 
                borderColor: ['#fafafa'],
              }]
            }}  
            />
        </div>
        <div className="lojas-details">
            {lojasArray.map((loja, index) => (
              <div key={index} className="loja-detail">
                <span>{loja.loja}: </span>
                <span> <b>R$ {loja.totalCaixa}</b></span>
              </div>
            ))}
        </div>
     </div>
     <div className="card-body d-flex align-items-end pt-0">
       <div className="d-flex align-items-center flex-column mt-3 w-100">
         <div className="d-flex justify-content-between fw-bold fs-3 text-dark opacity-75 w-100 mt-auto mb-2">
           <span>Mês Anterior</span>
           <span id="totalCaixaMesAnterior">{ftrMesAnterior}</span>
         </div>
         <div className="d-flex justify-content-between fw-bold fs-3 text-dark opacity-75 w-100 mt-auto mb-2">
           <span>Ano Anterior</span>
           <span id="totalCaixaAnoAnterior">{ftrAnoAnterior}</span>
         </div>
       </div>
     </div>
  </div>
  );
}
 export { EngageWidget1 }