import {Badge} from 'react-bootstrap';

export const formatIsFinal = (isFinal) => {
  switch (isFinal) {
    case 1:
      return (
        <Badge bg='success' text='light' style={{fontSize: '12px', letterSpacing: '0.5px'}}>
          SIM
        </Badge>
      );
    case 0:
      return (
        <Badge bg='danger' text='light' style={{fontSize: '12px', letterSpacing: '0.5px'}}>
          NÃO
        </Badge>
      );
    default:
      return (
        <Badge bg='light' text='light' style={{fontSize: '12px', letterSpacing: '0.5px'}}>
          N/A
        </Badge>
      );
  }
};
