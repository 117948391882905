import {ColumnInstance} from 'react-table';

type Props<T extends object> = {
  column: ColumnInstance<T>;
};

const CustomHeaderColumn = <T extends object>({ column }: Props<T>) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th
        style={{
          textAlign: 'center',
          width: '150px',
          height: '50px',
          color: '#000',
          verticalAlign: 'middle',
        }}
        {...column.getHeaderProps()}
      >
        {column.render('Header')}
      </th>
    ) : (
      column.render('Header')
    )}
  </>
);

export { CustomHeaderColumn };