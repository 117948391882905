const Divider = () => {
    return (
      <div
        style={{
          flexGrow: 1,
          borderBottom: '2px solid #E9E9E9',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      ></div>
    );
  };
  
  export default Divider;
  