import {useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const useBuscaClienteCredito = () => {
  return async (clienteid) => {
    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes/buscaSaldoCreditoCliente/${clienteid}`
      );
      let data;

      const saldoCredCliente = document.getElementById('saldoCreditoCliente') as HTMLInputElement;

      if (response.status === 404 || response.status === 400) {
        data = response.data;

        const Toast = Swal.mixin({
          toast: true,
          position: 'center-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: 'info',
          title: data,
        });
      }

      if (response.status === 200) {
        data = response.data;

        if (data.length > 0) {
          saldoCredCliente.value = data[0].valorCredito || '';
        } else {
          // Caso o array esteja vazio, indica que não há dados para o cliente fornecido
          const Toast = Swal.mixin({
            toast: true,
            position: 'center-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: 'info',
            title: 'Não foram encontrados dados para o cliente fornecido.',
          });
        }
      }
    } catch (error) {
      console.error('Erro ao buscar cliente pelo código! Detalhes:', error);
    }
  };
};

export default useBuscaClienteCredito;
