import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { sendUser } from '../cadastro_requests/cadastro_requests';
import useOptionsEmpresas from '../../selectOptions/useOptionsEmpresas';
import useOptionsIps from '../../selectOptions/useOptionsIps';
import useOptionsPermissoes from '../../selectOptions/useOptionsPermissoes';
import useOptionsFuncionariosClientes from '../../selectOptions/useOptionsFuncionariosClientes';
import PageTitulo from '../../components/Pagetitulo';

interface Vendedores {
  value: number;
  label: string;
}

const cadastroValidationSchema = Yup.object().shape({
  nome: Yup.string()
    .min(2, 'Seu nome deve conter pelo menos 2 caracteres')
    .required('Por favor, informe seu nome'),
  sobrenome: Yup.string()
    .min(2, 'Seu sobrenome deve conter pelo menos 2 caracteres')
    .required('Por favor, infome seu sobreome'),
  email: Yup.string()
    .email('Endereço de email inválido')
    .min(1, 'O campo email não pode ser vazio')
    .required('Por favor, informe um email'),
  login: Yup.string()
    .min(4, 'O campo login não pode ser vazio')
    .required('Por favor, informe um login'),
  password: Yup.string()
    .min(8, 'Sua senha deve conter mais de 8 caracteres')
    .max(50, 'Maximum 50 symbols')
    .required('Por favor, adicione uma senha'),
});

const initialValues = {
  nome: '',
  sobrenome: '',
  email: '',
  login: '',
  tipousuario: 1,
  idPerfil: 0,
  password: '',
  idempresas: [],
  ips: [],
  status: 1,
  isFinanceiro: 0,
  ipAddressForce: -1,
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : '#fff',
    color: state.isSelected ? '#fff' : '#000',
  }),
};

export function CadastroUsuarios() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { options } = useOptionsEmpresas();
  const { optionsIp } = useOptionsIps();
  const { optionsPermissoes } = useOptionsPermissoes();
  const { optionsFuncionariosClientes } = useOptionsFuncionariosClientes();
  const [selectedVendedor, setSelectedVendedor] = useState<Vendedores[]>([]);

  const formik = useFormik({
    initialValues,
    validationSchema: cadastroValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        if (values.tipousuario === 1 && values.idempresas.length === 0) {
          Swal.fire({
            icon: 'warning',
            title:
              "Caso o tipo de usuário selecionado seja 'Usuário', é necessário vincular pelo menos uma empresa",
            text: 'Por favor, vincule uma empresa e tente novamente',
            confirmButtonText: 'Ok',
          });
          setLoading(false);
          setSubmitting(false);
          return;
        }

        if (Number(values.ipAddressForce) === -1) {
          Swal.fire({
            icon: 'info',
            title: 'Por favor, selecione a Restrição de Ip',
            confirmButtonText: 'Ok',
          });
          setLoading(false);
          setSubmitting(false);
          return;
        }

        if (Number(values.tipousuario) === 1 && Number(values.idPerfil) === 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Por favor, selecione a permissão para o usuário',
            confirmButtonText: 'Ok',
          });
          setLoading(false);
          setSubmitting(false);
          return;
        }

        if (Number(values.tipousuario) === 1 && Number(values.idPerfil) === 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Por favor, selecione a permissão para o usuário',
            confirmButtonText: 'Ok',
          });
          setLoading(false);
          setSubmitting(false);
          return;
        }

        const idConsultorPadrao = selectedVendedor.map((tipo) => tipo.value);

        const response = await sendUser(
          values.nome,
          values.sobrenome,
          values.email,
          values.tipousuario,
          values.idPerfil,
          values.password,
          values.login,
          values.status,
          values.isFinanceiro,
          values.idempresas,
          values.ips,
          values.ipAddressForce,
          idConsultorPadrao.toString()
        );
        if (response.status === 201) {
          setSubmitting(false);
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Usuário cadastrado com sucesso!',
            text: 'O que você gostaria de fazer?',
            showCancelButton: false,
            confirmButtonText: 'Voltar a listagem',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => {
              navigate('/listagem-usuarios');
            },
          });
        } else {
          // Exibir mensagem de erro se a resposta não for bem-sucedida
          Swal.fire({
            icon: 'error',
            title:
              'Erro ao salvar o usuário, verifique as informações preenchidas e tente novamente',
            text: 'Something went wrong!',
            confirmButtonText: 'Ok',
          });
          setStatus('Ocorreu um erro ao salvar o usuário. Por favor, tente novamente.');
        }
      } catch (error: any) {
        console.error(error);
        const errorMessage = error.response.data.message;
        if (error.response && error.response.data && error.response.data.message === '401') {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            setSubmitting(false); // Resetar ou atualizar o estado conforme necessário
            setLoading(false);
            window.open('/auth', '_blank');
          });
        } else if (error.response && error.response.status === 409) {
          Swal.fire({
            icon: 'info',
            title: errorMessage,
            text: 'Por favor, verifique as informações e tente novamente.',
            confirmButtonText: 'Ok',
          });
          setSubmitting(false);
          setLoading(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao salvar o usuário',
            text: 'Por favor, verifique sua conexão de internet e tente novamente.',
            confirmButtonText: 'Ok',
          });

          setSubmitting(false);
          setLoading(false);
        }
        setStatus('Por favor, preencha as informações de usuário corretamente');
      }
    },
  });

  const handleEmpresasChange = (selectedOptions) => {
    formik.setFieldValue(
      'idempresas',
      selectedOptions.map((option) => option.value)
    );
  };

  const handleIpsChange = (selectedOptions) => {
    formik.setFieldValue(
      'ips',
      selectedOptions.map((option) => option.value)
    );
  };

  const handleVendedores = (selectedVendedor) => {
    setSelectedVendedor(selectedVendedor);
  };

  const handlePermissaoUsuarioChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue('idPerfil', event.target.value);
  };

  const handleTipoUsuarioChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const tipoUsuario = Number(event.target.value);
    formik.setFieldValue('tipousuario', tipoUsuario);

    if (tipoUsuario === 0) {
      // Resetar idPerfil para admin e desabilitar permissão
      formik.setFieldValue('idPerfil', '1');
    } else {
      // Limpar idPerfil para que o usuário escolha uma permissão
      formik.setFieldValue('idPerfil', '0');
    }
  };

  const handleStatusUsuarioChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  const handleRestricaoIp = (event) => {
    formik.setFieldValue('ipAddressForce', event.target.value);
  };

  const handleIsFinanceiroChange = (event) => {
    formik.setFieldValue('isFinanceiro', event.target.value);
  };


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
    }),
  };

  return (
    <form className='form-control-solid' onSubmit={formik.handleSubmit} noValidate>
      <div className='text-light-dark'>
        <PageTitulo id={null} tipoFormulario='Usuário' />
        <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center' }}>
          <h5>Informações Gerais</h5>
          <div style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px' }}></div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-6'>
            <label className='form-label'>Nome *</label>
            <input
              type='text'
              placeholder='Insira seu nome'
              {...formik.getFieldProps('nome')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.nome && formik.errors.nome },
                {
                  'is-valid': formik.touched.nome && !formik.errors.nome,
                }
              )}
              onChange={(event) => {
                const value = event.target.value.replace(/[^a-zA-ZÀ-ú\s]/g, ''); // Remover caracteres não alfabéticos
                formik.setFieldValue('nome', value); // Atualizar o valor do campo nome
              }}
            />
            {formik.touched.nome && formik.errors.nome && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.nome}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-6'>
            <label className='form-label'>Sobrenome *</label>
            <input
              type='text'
              placeholder='Insira seu sobrenome'
              {...formik.getFieldProps('sobrenome')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.sobrenome && formik.errors.sobrenome },
                {
                  'is-valid': formik.touched.sobrenome && !formik.errors.sobrenome,
                }
              )}
              onChange={(event) => {
                const value = event.target.value.replace(/[^a-zA-ZÀ-ú\s]/g, ''); // Remover caracteres não alfabéticos
                formik.setFieldValue('sobrenome', value); // Atualizar o valor do campo nome
              }}
            />
            {formik.touched.sobrenome && formik.errors.sobrenome && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.sobrenome}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-6'>
            <label className='form-label'>Email *</label>
            <input
              type='email'
              placeholder='Digite seu e-mail'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-3'>
            <label className='form-label'>Tipo de Usuario</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={handleTipoUsuarioChange}
              value={formik.values.tipousuario}
            >
              <option value='1'>Usuário</option>
              <option value='0'>Administrador</option>
            </select>
          </div>
          <div className="col-sm-3">
            <label className="form-label">Permissão</label>
            <select
              className="form-select"
              aria-label="Select example"
              onChange={handlePermissaoUsuarioChange}
              disabled={Number(formik.values.tipousuario) === 0}
              value={formik.values.idPerfil}
            >
              <option value="0">Selecione</option>
              {optionsPermissoes.map(permissao => (
                <option key={permissao.value} value={permissao.value}>
                  {permissao.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='mb-5 row'>
          <div className='col-sm-6 mb-5'>
            <label className='form-label'>Login *</label>
            <input
              type='text'
              placeholder='Digite seu login'
              {...formik.getFieldProps('login')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.login && formik.errors.login },
                {
                  'is-valid': formik.touched.login && !formik.errors.login,
                }
              )}
            />
            {formik.touched.login && formik.errors.login && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.login}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-sm-6'>
            <label className='form-label'>Senha *</label>
            <input
              type='password'
              autoComplete='off'
              placeholder='Digite sua senha'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.password && formik.errors.password },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-sm-6'>
            <label className='form-label'>Vincular Empresas</label>
            <Select
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              options={options}
              placeholder='Selecione empresas vinculadas a esse usuario'
              isMulti
              styles={customStyles}
              onChange={handleEmpresasChange}
            />
            {/* <OptionsEmpresas options={options} onChange={handleEmpresasChange}/>  */}
          </div>
          <div className='col-sm-6'>
            <label className='form-label'>Status</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={handleStatusUsuarioChange}
              value={formik.values.status}
            >
              <option value='1'>Ativo</option>
              <option value='0'>Inativo</option>
            </select>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='mb-5'>
              <label className='form-label'>Vincular Ips</label>
              <Select
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                options={optionsIp}
                placeholder="Selecione os ip's permitidos a esse usuario"
                isMulti
                styles={customStyles}
                onChange={handleIpsChange}
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <label className='form-label'>Restrição de IP</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={handleRestricaoIp}
              value={formik.values.ipAddressForce}
            >
              <option value='-1'>Selecione</option>
              <option value='1'>Sim</option>
              <option value='0'>Não</option>
            </select>
          </div>
        </div>
        <div className='row mb-10'>
          <div className='col-sm-6'>
            <label htmlFor='' className='form-label'>
              Selecione o Vendedor padrão para o Usuário
            </label>
            <Select
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              options={optionsFuncionariosClientes}
              value={selectedVendedor}
              placeholder='Selecione uma opção'
              styles={customStyles}
              isMulti
              onChange={handleVendedores}
            />
          </div>

          <div className='col-sm-6'>
            <label className='form-label'>Financeiro</label>
            <select
              className='form-select'
              aria-label='Select example'
              onChange={handleIsFinanceiroChange}
              value={formik.values.isFinanceiro}
            >
              <option value='-1'>Selecione</option>
              <option value='1'>Sim</option>
              <option value='0'>Não</option>
            </select>
          </div>
        </div>
        <div>
          <div className='d-flex gap-8'>
            <button type='submit' className=' btn-cadastro btn-bg-success' id='post_user_submit'>
              Cadastrar
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Aguarde...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <button
              type='button'
              className='btn-cadastro btn-danger'
              id='back'
              onClick={() => navigate('/listagem-usuarios')}
            >
              Voltar
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Aguarde...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
