import useOptionsWorkflowComponentes from '../../selectOptions/useOptionsWorkflowComponentes';
import { handleError, handleException, handleSuccess } from '../utils/Swal';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClienteOption } from '../../cadastros/proposta-pedido/types/interface';
import { fetchDataById } from '../services/FetchWorkflow';
import { useFormik } from 'formik';
import {
  send_W_Sequencia,
  update_W_Sequencia,
} from '../../cadastros/cadastro_requests/cadastro_requests';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import clsx from 'clsx';
import '../style.css';

interface WorkflowSequenciaType {
  idWorkflowComponente: number | null;
  sequencia: number;
  isFinal: number | undefined;
  idUsuarioAprovador: number;
  status: number | undefined;
  codigoComponente: string | undefined;
}

const WorkflowSequencia = () => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const { id } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedFormulario, setSelectedFormulario] = useState<string>('');
  const [selectedUserAprovador, setSelectedUserAprovador] = useState<ClienteOption | null>();
  const { optionsComponentes } = useOptionsWorkflowComponentes();
  const [showFiltroUsuario, setShowFiltroUsuario] = useState(false);
  // const buscaCliente = useBuscaClienteSelect();

  // const cadastroValidationSchema = Yup.object().shape({
  //   idWorkflowComponente: Yup.string().required('Por favor, informe o tipo de solicitação'),
  // });

  const initialValues: WorkflowSequenciaType = {
    idWorkflowComponente: null,
    sequencia: 0,
    isFinal: undefined,
    idUsuarioAprovador: 0,
    status: undefined,
    codigoComponente: undefined,
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleStatusChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  const handleIsFinalChange = (event) => {
    formik.setFieldValue('isFinal', event.target.value);
  };

  function updateWorkflow(
    id,
    idWorkflowComponente,
    sequencia,
    isFinal,
    idUsuarioAprovador,
    status
  ) {
    return update_W_Sequencia(
      id,
      idWorkflowComponente,
      sequencia,
      isFinal,
      idUsuarioAprovador,
      status
    );
  }

  function createWorkflow(idWorkflowComponente, sequencia, isFinal, idUsuarioAprovador, status) {
    return send_W_Sequencia(idWorkflowComponente, sequencia, isFinal, idUsuarioAprovador, status);
  }

  const formik = useFormik({
    initialValues,
    //validationSchema: cadastroValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      try {
        let response;

        if (id) {
          response = await updateWorkflow(
            id,
            values.idWorkflowComponente,
            values.sequencia,
            values.isFinal,
            values.idUsuarioAprovador,
            values.status
          );
        } else {
          response = await createWorkflow(
            values.idWorkflowComponente,
            values.sequencia,
            values.isFinal,
            values.idUsuarioAprovador,
            values.status
          );
        }

        if (response.status === 201) {
          handleSuccess(
            id ? 'atualizada' : 'cadastrada',
            navigate,
            '/listagem-workflow-sequencia',
            setSubmitting,
            setLoading
          );
        } else {
          handleError(setStatus, setSubmitting, setLoading);
        }
      } catch (error) {
        handleException(error, setSubmitting, setLoading);
      }
    },
  });

  const fetchSequencia = async () => {
    if (id) {
      try {
        setLoading(true);
        const data = await fetchDataById<WorkflowSequenciaType>('workflowsequencia', id);

        if (data) {
          formik.setValues({
            ...formik.values,
            idWorkflowComponente: data.idWorkflowComponente,
            sequencia: data.sequencia,
            isFinal: data.isFinal,
            idUsuarioAprovador: data.idUsuarioAprovador,
            status: data.status,
            codigoComponente: data.codigoComponente,
          });

          if (data.idWorkflowComponente) {
            setSelectedFormulario(data.idWorkflowComponente.toString());
          }

          const userAprov = await axios.get(
            `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/usuarios/optionsUsers/${data.idUsuarioAprovador}`
          );
          const dataUserAprov = userAprov.data[0];
          setSelectedUserAprovador({
            label: dataUserAprov.nome,
            value: dataUserAprov.id,

          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const loadOptionsUserAprovador = async (inputValue) => {
    if (!inputValue) return [];

    try {
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/usuarios/optionsUsers/${inputValue}`
      );

      const data = response.data;  // Não é necessário usar `await` aqui

      return data.map((item) => ({
        label: item.nome,
        value: item.id,

      }));
    } catch (error: any) {
      return [];
    }
  };



  useEffect(() => {
    fetchSequencia();
  }, [id]);

  return (
    <form
      className='form-control-solid'
      onSubmit={formik.handleSubmit}
      noValidate
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
    >
      <div className='row'>
        <div className='col-sm-5'>
          <h1 className='text-dark fw-bolder mb-5'>
            {id ? 'Edição de Workflow Sequência' : 'Cadastro de Workflow Sequência'}
          </h1>
        </div>
        <div style={{ marginBottom: '8px' }}>
          <div style={{ borderBottom: '2px solid #000' }}></div>
        </div>
      </div>
      <div className='container-workflow mt-10 sm:gap-6'>
        <div
          className='col-sm-3'
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          <label className='form-label'>Selecione o Código do Componente</label>
          <select
            className={clsx('form-select  bg-transparent')}
            placeholder='Selecione a página que a Permissão terá acesso'
            {...formik.getFieldProps('idWorkflowComponente')}
            value={selectedFormulario}
            onChange={(e) => {
              setSelectedFormulario(e.target.value);
              formik.setFieldValue('idWorkflowComponente', e.target.value);
            }}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            {optionsComponentes.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div
          className='col-sm-2'
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          <label className='form-label'>Sequencias de Aprovação</label>
          <input
            type='number'
            placeholder='Insira o número de sequencias'
            {...formik.getFieldProps('sequencia')}
            className={clsx('form-control bg-transparent')}
            onChange={handleChange}
          />
        </div>

        <div className='col-sm-2'>
          <label className='form-label'>Is Final?</label>
          <select
            className={clsx('form-select  bg-transparent')}
            aria-label='Select example'
            value={formik.values.isFinal}
            onChange={handleIsFinalChange}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            <option value='1'>Sim</option>
            <option value='0'>Não</option>
          </select>
        </div>

        <div
          className='col-sm-2'
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}

        >
          <label className='form-label'>Usuário Aprovador</label>
          <AsyncSelect
            className='react-select-styled react-select-solid'
            classNamePrefix='react-select'
            placeholder='Pesquise o Usuário Aprovador'
            loadOptions={loadOptionsUserAprovador}
            isDisabled={showFiltroUsuario}
            value={selectedUserAprovador}
            onChange={(selectedUseraprovadorOption: ClienteOption | null) => {
              setSelectedUserAprovador(selectedUseraprovadorOption);
              formik.setFieldValue('idUsuarioAprovador', selectedUseraprovadorOption?.value);
            }}

          />


        </div>

        <div className='col-sm-2'>
          <label className='form-label'>Status</label>
          <select
            className={clsx('form-select  bg-transparent')}
            aria-label='Select example'
            value={formik.values.status}
            onChange={handleStatusChange}
            disabled={id && formik.values.isFinal === 1 ? true : false}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            <option value='1'>Ativo</option>
            <option value='0'>Inativo</option>
          </select>
        </div>
      </div>

      <div className='d-flex gap-8 mt-10'>
        <button type='submit' className=' btn-cadastro btn-bg-success' id='post_user_submit'>
          {id ? 'Atualizar' : 'Cadastrar'}

          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <button
          type='button'
          className='btn-cadastro btn-danger'
          id='back'
          onClick={() => navigate('/listagem-workflow-sequencia')}
        >
          Voltar
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
};

export default WorkflowSequencia;
