import {handleError, handleException, handleSuccess} from '../utils/Swal';
import {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {fetchDataById} from '../services/FetchWorkflow';
import {useFormik} from 'formik';
import clsx from 'clsx';
import {
  send_W_Componente,
  update_W_Componente,
} from '../../cadastros/cadastro_requests/cadastro_requests';
import useOptionsWorkflowAprovacao from '../../selectOptions/useOptionsWorkflowAprovacao';
import * as Yup from 'yup';
import '../style.css';

interface WorkflowComponentesTypes {
  id: number | undefined;
  idWorkflowAprovacao: string;
  codigoComponente: string;
  status: string;
}

const WorkflowComponentes = () => {

  const {id} = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedFormulario, setSelectedFormulario] = useState<string>('');
  const {optionsAprovacao} = useOptionsWorkflowAprovacao();

  const cadastroValidationSchema = Yup.object().shape({
    idWorkflowAprovacao: Yup.string().required('Por favor, informe o tipo de solicitação'),
    codigoComponente: Yup.string().required('Por favor, informe o código do compoente'),
    status: Yup.string().required('Por favor, infome o status'),
  });

  const initialValues: WorkflowComponentesTypes = {
    idWorkflowAprovacao: '',
    codigoComponente: '',
    status: '',
    id: undefined,
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    formik.setFieldValue(name, value);
  };

  const handleStatusChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  function updateWorkflow(id, idWorkflowAprovacao, codigoComponente, status) {
    return update_W_Componente(id, idWorkflowAprovacao, codigoComponente, status);
  }

  function createWorkflow(idWorkflowAprovacao, codigoComponente, status) {
    return send_W_Componente(idWorkflowAprovacao, codigoComponente, status);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: cadastroValidationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);

      try {
        let response;

        if (id) {
          response = await updateWorkflow(
            id,
            values.idWorkflowAprovacao,
            values.codigoComponente,
            Number(values.status)
          );
        } else {
          response = await createWorkflow(
            values.idWorkflowAprovacao,
            values.codigoComponente,
            Number(values.status)
          );
        }

        if (response.status === 201) {
          handleSuccess(
            id ? 'atualizada' : 'cadastrada',
            navigate,
            '/listagem-workflow-componente',
            setSubmitting,
            setLoading
          );
        } else {
          handleError(setStatus, setSubmitting, setLoading);
        }
      } catch (error) {
        handleException(error, setSubmitting, setLoading);
      }
    },
  });

  const fetchComponente = async () => {
    if (id) {
      try {
        setLoading(true);
        const data = await fetchDataById<WorkflowComponentesTypes>('workflowcomponentes', id);

        if (data) {
          formik.setValues({
            ...formik.values,
            idWorkflowAprovacao: data.idWorkflowAprovacao,
            codigoComponente: data.codigoComponente,
            status: data.status,
          });

          if (data.idWorkflowAprovacao) {
            setSelectedFormulario(data.idWorkflowAprovacao);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchComponente();
  }, [id]);

  return (
    <form
      className='form-control-solid'
      onSubmit={formik.handleSubmit}
      noValidate
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
    >
      <div className='row'>
        <div className='col-sm-5'>
          <h1 className='text-dark fw-bolder mb-5'>
            {id ? 'Edição de Workflow Componente' : 'Cadastro de Workflow Componente'}
          </h1>
        </div>
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #000'}}></div>
        </div>
      </div>
      <div className='container-workflow mt-10 sm:gap-6'>
        <div
          className='col-sm-4'
          style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
        >
          <label className='form-label'>Selecione o formulário de Aprovação</label>
          <select
            className={clsx(
              'form-select  bg-transparent',
              {
                'is-invalid':
                  formik.touched.idWorkflowAprovacao && formik.errors.idWorkflowAprovacao,
              },
              {
                'is-valid':
                  formik.touched.idWorkflowAprovacao && !formik.errors.idWorkflowAprovacao,
              }
            )}
            placeholder='Selecione a página que a Permissão terá acesso'
            {...formik.getFieldProps('idWorkflowAprovacao')}
            value={selectedFormulario}
            onChange={(e) => {
              setSelectedFormulario(e.target.value);
              formik.setFieldValue('idWorkflowAprovacao', e.target.value);
            }}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            {optionsAprovacao.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {formik.touched.idWorkflowAprovacao && formik.errors.idWorkflowAprovacao && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.idWorkflowAprovacao}</span>
              </div>
            </div>
          )}
        </div>

        <div className='col-sm-4'>
          <label className='form-label'>Código do Componente</label>
          <input
            type='text'
            placeholder='Insira o código'
            {...formik.getFieldProps('codigoComponente')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.codigoComponente && formik.errors.codigoComponente},
              {
                'is-valid': formik.touched.codigoComponente && !formik.errors.codigoComponente,
              }
            )}
            onChange={handleChange}
          />
          {formik.touched.codigoComponente && formik.errors.codigoComponente && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.codigoComponente}</span>
              </div>
            </div>
          )}
        </div>

        <div className='col-sm-3'>
          <label className='form-label'>Status</label>
          <select
            className={clsx(
              'form-select  bg-transparent',
              {'is-invalid': formik.touched.status && formik.errors.status},
              {
                'is-valid': formik.touched.status && !formik.errors.status,
              }
            )}
            aria-label='Select example'
            value={formik.values.status}
            onChange={handleStatusChange}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            <option value='1'>Ativo</option>
            <option value='0'>Inativo</option>
          </select>
          {formik.touched.status && formik.errors.status && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.status}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='d-flex gap-8 mt-10'>
        <button type='submit' className=' btn-cadastro btn-bg-success' id='post_user_submit'>
          {id ? 'Atualizar' : 'Cadastrar'}

          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <button
          type='button'
          className='btn-cadastro btn-danger'
          id='back'
          onClick={() => navigate('/listagem-workflow-componente')}
        >
          Voltar
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
};

export default WorkflowComponentes;
