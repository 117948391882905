import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useAuth } from "../../modules/auth";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export async function fetchOptionsAlmoxarifadoGlobal(currentUser) {
  try {
    
    const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/almoxarifado/optionsAlmoxarifado`);
    const data = response.data;
    const filtered = data.filter(item => currentUser?.empresasVinculadas?.some(empresa => +empresa.codigo === +item.idEmpresa));
    localStorage.setItem('optionsAlmoxarifado', JSON.stringify(filtered));
  } catch (error) {
    console.error("Erro ao buscar opções:", error);
  }
}

export function getOptionsAlmoxarifadoFromStorage() {
  const storedOptions = localStorage.getItem('optionsAlmoxarifado');
  return storedOptions ? JSON.parse(storedOptions) : [];
}