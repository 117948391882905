import * as Yup from 'yup';

export const cadastroCategorizacaoValidationSchema = Yup.object().shape({
  titulo: Yup.string()
    .min(3, 'O titulo deve conter pelo menos 3 caracteres')
    .max(255, 'O nome do arquivo deve conter no máximo 255 caracteres')
    .required('Por favor, informe um titulo'),
  descricao: Yup.string()
    .min(3, 'A descrição deve conter no mínimo 3 caracteres')
    .max(255, 'A descrição deve conter no máximo 255 caracteres')
    .required('Por favor, informe uma descrição'),
  status: Yup.string().required('Por favor, adicione um status'),
  modelo: Yup.number().required('Por favor, informe uma descrição'),
});
